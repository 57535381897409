import { useMemo } from "react";
import { When } from "react-if";

import CheckCircleIcon from "@icons/CheckCircleIcon";
import ErrorIcon from "@icons/ErrorIcon";
import InformationCircleIcon from "@icons/InformationCircleIcon";
import WarningIcon from "@icons/WarningIcon";
import XMarkIcon from "@icons/XMarkIcon";

import Well from "@ui/Well/Well";

import { classnames } from "@utils/classnames";

import { FEEDBACK_TYPE } from "@lib/constants/theme";
import type { FeedbackColor, FeedbackType } from "@lib/types/theme";

type NoticeProps = {
  children: React.ReactNode;
  type: FeedbackType;
  className?: string;
  heading?: string | React.ReactNode;
  icon?: FeedbackType;
  onClose?: () => void;
}

const WELL_COLOR = {
  [FEEDBACK_TYPE.INFO]: "info",
  [FEEDBACK_TYPE.ERROR]: "error",
  [FEEDBACK_TYPE.SUCCESS]: "success",
  [FEEDBACK_TYPE.WARNING]: "yellow"
};

const COLOR_CLASSNAMES = {
  icon: {
    [FEEDBACK_TYPE.INFO]: "text-blue-400",
    [FEEDBACK_TYPE.ERROR]: "text-error-icon",
    [FEEDBACK_TYPE.SUCCESS]: "text-success-icon",
    [FEEDBACK_TYPE.WARNING]: "text-warning-icon",
  },
  heading: {
    [FEEDBACK_TYPE.INFO]: "text-blue-800",
    [FEEDBACK_TYPE.ERROR]: "text-red-800",
    [FEEDBACK_TYPE.SUCCESS]: "text-green-800",
    [FEEDBACK_TYPE.WARNING]: "text-yellow-800",
  },
  text: {
    [FEEDBACK_TYPE.INFO]: "text-blue-700",
    [FEEDBACK_TYPE.ERROR]: "text-red-700",
    [FEEDBACK_TYPE.SUCCESS]: "text-success",
    [FEEDBACK_TYPE.WARNING]: "text-warning",
  },
  iconButton: {
    [FEEDBACK_TYPE.INFO]: "bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50",
    [FEEDBACK_TYPE.ERROR]: "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50",
    [FEEDBACK_TYPE.SUCCESS]: "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50",
    [FEEDBACK_TYPE.WARNING]: "bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50",
  }
};

/**
 * A `Notice` component that displays a styled message box with an optional icon, heading, and custom content.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the notice.
 * @param {FeedbackType} props.type - The feedback type for styling the notice (e.g., INFO, ERROR, SUCCESS, WARNING). Defaults to INFO.
 * @param {string} [props.className] - Additional CSS class names to apply to the content.
 * @param {string | React.ReactNode} [props.heading] - An optional heading for the notice.
 * @param {FeedbackType} [props.icon] - The feedback type for the icon (e.g., INFO, ERROR, SUCCESS, WARNING). If icon is not included, it does not show an icon.
 * @param {() => void} [props.onClose] - Optional callback function to handle the notice close action. If it's not included, it does not show the close button.
 *
 * @example
 * // Example usage:
 * <Notice
 *   type={FEEDBACK_TYPE.SUCCESS}
 *   icon={FEEDBACK_TYPE.SUCCESS}
 *   heading="Success!"
 *   onClose={() => closeNotice()}
 * >
 *   <p>The operation was completed successfully.</p>
 * </Notice>
 *
 * @returns {JSX.Element} The rendered Notice component.
 */

const Notice = ({
  children,
  className,
  heading,
  icon,
  type,
  onClose,
  ...attrs
}: NoticeProps) => {
  const showCloseButton = useMemo(() => {
    return !!onClose;
  }, [onClose]);

  const IconComponent = useMemo(() => {
    if (!icon) return null;

    return {
      [FEEDBACK_TYPE.INFO]: InformationCircleIcon,
      [FEEDBACK_TYPE.ERROR]: ErrorIcon,
      [FEEDBACK_TYPE.SUCCESS]: CheckCircleIcon,
      [FEEDBACK_TYPE.WARNING]: WarningIcon,
    }[icon];
  }, [icon]);

  const classNames = useMemo(() => {
    return {
      icon: classnames("size-5", COLOR_CLASSNAMES.icon[type]),
      iconButton: classnames("inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2", COLOR_CLASSNAMES.iconButton[type]),
      heading: classnames("text-sm font-medium", COLOR_CLASSNAMES.heading[type]),
      container: classnames({ "ml-3": !!icon }),
      content: classnames("text-sm", COLOR_CLASSNAMES.text[type], {
        "mt-2": !!heading
      }, className),
    };
  }, [className, heading, icon, type]);

  return (
    <Well
      className="text-sm relative"
      color={WELL_COLOR[type] as FeedbackColor}
      data-testid="notice-component"
      {...attrs}
    >
      <div className="flex">
        {!!icon && !!IconComponent &&
          <div className="shrink-0">
            <IconComponent
              aria-hidden="true"
              className={classNames.icon}
              data-testid="notice-icon"
            />
          </div>
        }

        <When condition={showCloseButton}>
          <div className="absolute top-1.5 right-1.5 pl-3">
            <button
              type="button"
              data-testid="notice-close-btn"
              className={classNames.iconButton}
              onClick={onClose}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="size-5 text-current" />
            </button>
          </div>
        </When>

        <div className={classNames.container}>
          <When condition={!!heading}>
            <h3 className={classNames.heading} data-testid="notice-heading">{heading}</h3>
          </When>

          <div className={classNames.content} data-testid="notice-content">
            {children}
          </div>
        </div>
      </div>
    </Well>
  );
};

export default Notice;