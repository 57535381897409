import React from "react";

import { styled } from "@mui/material";
import { Button, Stack, Typography } from "@mui/material";

import Modal from "commons/Modal";
import Dialog from "commons/Modal/Dialog";

import Link529 from "@components/Link529/Link529";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { BankAccount } from "@lib/types/account";
import type { State529Plan } from "@lib/types/state529";
import type { Link529ModalPayload, ReLink529Payload } from "@lib/types/modal";

type Link529ModalProps = {
  bankAccount?: BankAccount;
  reLink?: ReLink529Payload;
  state529Plan?: State529Plan;
  beneficiary: Beneficiary;
  open: boolean;
  handleOnClose: (payload?: Link529ModalPayload) => void;
};

const ExitModalTitle = styled(Typography)({
  fontWeight: "700",
  fontSize: "1.2rem",
  textAlign: "center",
});

const Text = styled(Typography)({
  textAlign: "center",
  margin: "auto",
});

const Link529Modal = ({
  bankAccount,
  beneficiary,
  open,
  reLink,
  state529Plan,
  handleOnClose
}: Link529ModalProps) => {
  const { trackEvent } = useAnalytics();

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

  const attemptToClose = () => setConfirmationModalOpen(true);

  const keepOpen = () => setConfirmationModalOpen(false);

  const closeConfirmation = () => {
    trackEvent("SKIPPED_LINKING_AUTOMATED", {
      origin: ANALYTICS_EVENT_ORIGIN.DASHBOARD,
      action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
      action_description: "Clicked the Exit Link 529 button in the Exiting Link 529 dialog",
      component: "Link529Modal",
    });

    setConfirmationModalOpen(false);
    handleOnClose();
  };

  const closeLink529Handler = (payload?: Link529ModalPayload) => {
    if (!payload) {
      attemptToClose();
      return;
    }

    setConfirmationModalOpen(false);
    handleOnClose(payload);
  };

  if (!bankAccount || !state529Plan) return null;

  return (
    <Modal
      open={open}
      handleClose={attemptToClose}
      dataCy="exit-payment-confirmation-modal"
      confirmation={
        <Dialog open={confirmationModalOpen} onClose={keepOpen}>
          <Stack spacing={3} alignItems="center">
            <ExitModalTitle data-cy="modal-title">Exiting Link 529</ExitModalTitle>
            <Stack alignItems="center">
              <Text>Your account connection has not been made yet.</Text>
              <Text>Are you sure you want to leave?</Text>
            </Stack>

            <Button onClick={keepOpen} variant="contained" data-cy="continue-payment">
              Continue Link 529
            </Button>
            <Button onClick={closeConfirmation} variant="text" data-cy="payment-exit">
              Exit Link 529
            </Button>
          </Stack>
        </Dialog>
      }>

      <Link529
        bankAccount={bankAccount}
        beneficiary={beneficiary}
        state529Plan={state529Plan}
        reLink={reLink}
        handleClose={closeLink529Handler}
      />
    </Modal>
  );
};

export default Link529Modal;
