export const MUTATION_KEY = {
  ANALYTICS: "analytics",
  CREATE_BENEFICIARY: "create-beneficiary",
  UPDATE_BENEFICIARY_ENROLLMENT: "update-beneficiary-enrollment",
  CREATE_INSTITUTION_PAYMENT_TOKEN: "create-institution-payment-token",
  REQUEST_INSTITUTION_PAYMENT_CODE: "request-institution-payment-code",
  CREATE_INSTITUTION_PAYMENT: "create-institution-payment",
  UPDATE_MANUAL_LINK: "update-manual-link",
  ADD_529_COUNTERPARTY_LINK: "add-529-counterparty-link",
  ADD_PERSONAL_DEBIT_COUNTERPARTY: "add-personal-debit-counterparty",
  PERSONAL_DEBIT_WITHDRAWAL_REQUEST: "personal-debit-withdrawal-request",
  DEBIT_INSTITUTION_PAYMENT: "debit-institution-payment",
  DEBIT_529_WITHDRAWAL_REQUEST: "debit-529-withdrawal-request",
};