import React, { useState, FC } from "react";
import { Else, If, Then } from "react-if";

import { ThemeProvider } from "@mui/material/styles";
import { moduleTheme } from "./theme";

import LoginLayout from "@layouts/LoginLayout";

import LoginForm from "components/Login/LoginForm";
import Registration from "components/Registration";
import ApplicationServices from "@lib/services/application.services";

import type { UUID } from "@lib/types/global";

interface OnboardingProps {
  defaultView?: "login" | "signup";
}

const Login: FC<OnboardingProps> = ({ defaultView = "login" }) => {
  const [showRegistration, setShowRegistration] = useState(false);
  const [applicationId, setApplicationId] = React.useState("");

  const handleNext = () => {
    setShowRegistration(true);
  };

  const handleCreateApplication = async (phoneNumber: string) => {
    let appId: UUID = applicationId;
    if (!applicationId) {
      appId = await ApplicationServices.createApplication(phoneNumber);
      setApplicationId(() => appId);
    }
    await ApplicationServices.getApplicationMFA(appId);
  };

  return (
    <ThemeProvider theme={moduleTheme}>
      <LoginLayout>
        <If condition={!showRegistration}>
          <Then>
            <LoginForm
              applicationId={applicationId}
              defaultTabValue={defaultView === "login" ? 0 : 1}
              handleNext={handleNext}
              handleCreateApplication={handleCreateApplication}
            />
          </Then>

          <Else>
            <Registration applicationId={applicationId} />
          </Else>
        </If>
      </LoginLayout>
    </ThemeProvider>
  );
};

export default Login;
