import { useMemo, useRef, useState } from "react";
import { useShallow } from "zustand/react/shallow";

import Button from "@ui/Button/Button";
import Spinner from "@ui/Spinner/Spinner";
import Stepper from "@ui/Stepper/Stepper";

import DirectDebitAccountForm from "@components/DirectDebit/DirectDebitAccountForm";

import { ADD_FUNDS_STEP_DETAILS } from "@lib/constants/addFunds";

import { useAddFundsStore } from "@stores/addFundsStore";

import { AddFundsSteps, Flow } from "@lib/enums/flows";
import type { AccountDetails } from "@lib/types/addFunds";

const AddPersonalFundsAccountDetails = () => {
  const formId = "direct-debit-personal-account-form";
  const formRef = useRef<HTMLFormElement | null>(null);

  const step = useAddFundsStore(useShallow((state) => state.step));

  const updatePersonalDebitPayload = useAddFundsStore((state) => state.updatePersonalDebitPayload);
  const goToNextStep = useAddFundsStore((state) => state.goToNextStep);

  const details = useMemo(() => {
    if (!step.details) return ADD_FUNDS_STEP_DETAILS[Flow.PERSONAL_DEBIT][AddFundsSteps.ACCOUNT_DETAILS];

    return step.details[AddFundsSteps.ACCOUNT_DETAILS];
  }, [step]);

  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const handleIsValidForm = (isValid: boolean) => {
    if (isValid === isValidForm) return;

    setIsValidForm(isValid);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleOnFormSuccess = (accountDetails: AccountDetails) => {
    updatePersonalDebitPayload(accountDetails);
    goToNextStep();
  };

  return (
    <>
      <header className="modal-content-fixed flex-col px-12">
        <h1 className="text-blue text-lg font-montserrat font-bold text-center mb-5" data-testid="AddPersonalFundsAccountDetails-heading">{details.heading}</h1>

        <Stepper
          steps={step.list}
          current={step.stepNumber}
          className="mb-7"
        />

        <p className="text-base font-bold font-montserrat text-indigo" data-testid="AddPersonalFundsAccountDetails-description">
          {details.description}
        </p>
      </header>

      <main className="modal-screen-footer-fixed px-12 modal-body-scroll">
        <DirectDebitAccountForm
          type="personal"
          formId={formId}
          ref={formRef}
          onIsValidForm={(isValid) => handleIsValidForm(isValid)}
          onPendingSubmit={(enable) => setIsPendingSubmit(enable)}
          onFormSuccess={(accountDetails) => handleOnFormSuccess(accountDetails)}
        />

        <div className="w-full shrink-0">
          <Button
            rounded
            fullWidth
            data-testid="AddPersonalFundsAccountDetails-continue-btn"
            disabled={!isValidForm || isPendingSubmit}
            onClick={handleFormSubmit}
          >
            {isPendingSubmit
              ? <Spinner color="white" />
              : "Continue"
            }
          </Button>
        </div>
      </main>
    </>
  );
};

export default AddPersonalFundsAccountDetails;