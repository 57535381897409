import { useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { sendAnalyticsEvent } from "@lib/mutations/analyticsMutations";

import { useUserProfileStore, type UserProfileState } from "@stores/userProfileStore";
import { useCurrentUserStore } from "@stores/currentUserStore";

import { ANALYTICS_EVENT_ORIGIN, ANALYTICS_EVENT_TYPE } from "@lib/constants/analytics";
import type {
  AnalyticsEvent,
  AnalyticsEventType,
  EventProperties
} from "@lib/types/analytics";
import type { UUID } from "@lib/types/global";

type BuildPayloadParams = {
  event: string;
  properties: EventProperties;
  owner_id: UUID | string;
  userProfiles: UserProfileState;
  type: AnalyticsEventType | string;
}

const buildUserProfiles = (
  name: AnalyticsEvent["name"],
  userProfiles: UserProfileState
) => {
  switch (name) {
    case ANALYTICS_EVENT_ORIGIN.REGISTRATION:
      return null;

    case ANALYTICS_EVENT_ORIGIN.ONBOARDING:
      return {
        profiles: {
          onboarding: userProfiles.profiles.onboarding,
        },
        profilesCompleted: {
          onboarding: userProfiles.profilesCompleted.onboarding
        }
      };

    case ANALYTICS_EVENT_ORIGIN.DASHBOARD:
    default:
      return {
        profiles: userProfiles.profiles,
        profilesCompleted: userProfiles.profilesCompleted
      };
  }
};

const buildPayload = (params: BuildPayloadParams) => {
  const {
    origin,
    action,
  } = params.properties;

  const owner_id = params.owner_id;
  const application_id = params.properties?.application_id;
  const version_number = params.properties?.version || 1;

  const context = {
    page: {
      path: window.location.pathname,
      search: window.location.search,
      title: document.title,
      url: window.location.href
    },
    userAgent: navigator.userAgent,
  };

  const userProfiles = buildUserProfiles(origin, params.userProfiles);

  const payload: AnalyticsEvent = {
    action,
    name: params.event,
    metadata: {
      context,
      event: params.event,
      properties: {
        ...params.properties,
        userProfiles
      },
      timestamp: new Date().toISOString(),
      type: params.type,
    },
    version_number
  };

  if (!!owner_id && !!owner_id.length) {
    payload["owner_id"] = owner_id;
  }

  if (!!application_id && !!application_id.length) {
    payload["application_id"] = application_id;
  }

  return payload;
};

export const useAnalytics = () => {
  const currentUser = useCurrentUserStore(useShallow((state) => state.currentUser));
  const userProfiles = useUserProfileStore(useShallow((state) => state));

  const mutation = useMutation({
    mutationKey: [MUTATION_KEY.ANALYTICS],
    mutationFn: sendAnalyticsEvent,
    onError: (error: any, variables: any) => {
      // [SM - 12/22/2024]: consider replacing this with a custom sentry hook/helper eventually
      Sentry.withScope(function (scope) {
        if (error.request_id) {
          scope.setExtra("request_id", error.request_id);
        }

        scope.setExtra("event_payload", variables.payload);

        Sentry.captureException(new Error("Failed to send analytics event"));
      });
    },
    throwOnError: false,
  });

  const screenEvent = useCallback((
    event: string,
    properties: EventProperties
  ) => {
    const payload = buildPayload({
      event,
      properties,
      userProfiles,
      type: ANALYTICS_EVENT_TYPE.SCREEN,
      owner_id: currentUser.id,
    }) as AnalyticsEvent;

    mutation.mutate(payload);
  }, [mutation, userProfiles]);

  const trackEvent = useCallback((
    event: string,
    properties: EventProperties
  ) => {
    const payload = buildPayload({
      event,
      properties,
      userProfiles,
      type: ANALYTICS_EVENT_TYPE.TRACK,
      owner_id: currentUser.id,
    }) as AnalyticsEvent;

    mutation.mutate(payload);
  }, [mutation, userProfiles]);

  return {
    screenEvent,
    trackEvent
  };
};