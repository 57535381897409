import React from "react";
import { Switch, Case } from "react-if";

import Icon, { type SolidIconProps } from "@icons/Icon";

type HourglassIconProps = SolidIconProps & {
  type: "top" | "bottom" | "full" | "empty"
}

const HourglassIcon = React.memo(function HourglassIcon({
  type = "empty",
  ...iconProps
}: HourglassIconProps) {
  return (
    <Icon outline={false} { ...iconProps }>
      <Switch>
        <Case condition={type === "top"}>
          <path d="M6 2L6.01 8L10 12L6.01 16.01L6 22H18V16L14 12L18 8.01V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5Z" />
        </Case>

        <Case condition={type === "bottom"}>
          <path d="M18 22L17.99 16L14 12L17.99 7.99L18 2H6V8L10 12L6 15.99V22H18ZM8 7.5V4H16V7.5L12 11.5L8 7.5Z" />
        </Case>

        <Case condition={type === "full"}>
          <path d="M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6Z" />
        </Case>

        <Case condition={type === "empty"}>
          <path d="M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5ZM12 11.5L8 7.5V4H16V7.5L12 11.5Z" />
        </Case>
      </Switch>
    </Icon>
  );
});

export default HourglassIcon;
