import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { When, Switch, Case } from "react-if";

import Button from "@ui/Button/Button";
import Modal from "@ui/Modal/Modal";
import ModalDialog from "@ui/Modal/ModalDialog";

import Add529Funds from "@components/AddFunds/Add529Funds/Add529Funds";
import AddPersonalFunds from "@components/AddFunds/AddPersonalFunds/AddPersonalFunds";

import { BASE_ROUTE } from "@lib/constants/global";
import { AddFundsSteps, Flow } from "@lib/enums/flows";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import { useAddFundsStore } from "@stores/addFundsStore";

type AddFundsModalProps = {
  open: boolean;
  closeModal?: () => void;
}

const AddFundsModal = ({
  open,
  closeModal,
}: AddFundsModalProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const [openExitConfirmation, setOpenExitConfirmation] = useState(false);

  const flowType = useAddFundsStore(useShallow((state) => state.flow));
  const currentStep = useAddFundsStore(useShallow((state) => state.step.current));

  const resetAddFundsStore = useAddFundsStore((state) => state.reset);

  const handleConfirmExit = () => {
    resetAddFundsStore();
    setOpenExitConfirmation(false);

    if (flowType === Flow.DIRECT_DEBIT) {
      trackEvent("SKIPPED_LINKING_FIDELITY", {
        origin: ANALYTICS_EVENT_ORIGIN.DASHBOARD,
        action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
        action_description: "Clicked the Exit Withdrawal button in the Exiting Withdrawal Request dialog",
        component: "AddFundsModal",
        variation: flowType
      });
    }

    if (!closeModal) {
      navigate(BASE_ROUTE.dashboard, { replace: true });
      return;
    }

    closeModal();
  };

  const handleCloseModal = (success?: boolean) => {
    if (currentStep === AddFundsSteps.SUCCESS || success) {
      navigate(BASE_ROUTE.dashboard, {
        replace: true,
        state: {
          addFundsComplete: true,
          flowType
        }
      });

      return;
    }

    setOpenExitConfirmation(true);
  };

  return (
    <>
      <Modal
        open={open}
        maxWidth="xs"
        className="sm:h-[calc(100%-20px)] sm:max-h-[932px]"
        showCloseButton
        scrollScreen
        handleClose={() => handleCloseModal()}
      >
        <Switch>
          <Case condition={flowType === Flow.DIRECT_DEBIT}>
            <Add529Funds onFinishCloseModal={() => handleCloseModal(true)} />
          </Case>

          <Case condition={flowType === Flow.PERSONAL_DEBIT}>
            <AddPersonalFunds onFinishCloseModal={() => handleCloseModal(true)} />
          </Case>
        </Switch>
      </Modal>

      <When condition={openExitConfirmation}>
        <ModalDialog open={openExitConfirmation} onClose={() => setOpenExitConfirmation(false)}>
          <h3 className="text-xl font-montserrat font-bold">Exiting Withdrawal Request</h3>

          <p className="mt-4 text-base">
            Your withdrawal has not been made yet.
            Are you sure you want to leave?
          </p>

          <div className="mt-4">
            <Button
              primary
              rounded
              fullWidth
              data-testid="dialog-continue-withdrawal-button"
              onClick={() => setOpenExitConfirmation(false)}
            >
              Continue Withdrawal
            </Button>

            <Button
              text
              rounded
              size="sm"
              className="mt-1.5 font-medium"
              data-testid="dialog-exit-withdrawal-button"
              onClick={handleConfirmExit}
            >
              Exit Withdrawal
            </Button>
          </div>
        </ModalDialog>
      </When>
    </>
  );
};

export default AddFundsModal;