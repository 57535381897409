import instance from "config/fetch.config";
import { MutationError } from "@utils/errors";

import type { AnalyticsEvent } from "@lib/types/analytics";

export const sendAnalyticsEvent = async (payload: AnalyticsEvent): Promise<{ message: string }> => {
  try {
    const { data: { data } } = await instance.post("/analytics", payload);

    return data;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};