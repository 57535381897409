import { useShallow } from "zustand/react/shallow";
import { useMutation } from "@tanstack/react-query";
import { Case, Switch } from "react-if";

import TuitionPaymentNewScreen from "@components/TuitionPayment/screens/TuitionPaymentNewScreen";
import TuitionPaymentReviewScreen from "@components/TuitionPayment/screens/TuitionPaymentReviewScreen";
import TuitionPaymentInitiatedScreen from "@components/TuitionPayment/screens/TuitionPaymentInitiatedScreen";
import MFAModal from "@shared/MFA/MFAModal";
import ModalDialog from "@ui/Modal/ModalDialog";
import Button from "@ui/Button/Button";

import {
  Flow,
  ManualLink529Steps,
  TuitionPaymentSteps
} from "@lib/enums/flows";
import { State529ProviderType } from "@lib/enums/state529";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { createInstitutionPaymentToken, requestInstitutionPaymentMFACode } from "@lib/mutations/institutionPaymentMutations";

import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";
import { useCurrentUserStore } from "@stores/currentUserStore";
import { useOnboardingStore } from "@stores/onboardingStore";
import { useEffect } from "react";

const TuitionPayment = () => {
  const phoneNumberLast4 = useCurrentUserStore(useShallow((state) => state.currentUser.phone_number_last_four_digits));
  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));

  const screen = useTuitionPaymentsStore(useShallow((state) => state.screen.current));
  const currentStep = useTuitionPaymentsStore(useShallow((state) => state.step.current));

  const isOnboarding = useTuitionPaymentsStore(useShallow((state) => state.isOnboarding));
  const hasSkippedLinking = useOnboardingStore(useShallow((state) => state.status[Flow.LINK_529] === ManualLink529Steps.SKIPPED));

  const state529PlanDetails = useTuitionPaymentsStore(useShallow((state) => state.state529PlanDetails));

  const mfaError = useTuitionPaymentsStore(useShallow((state) => state.mfaError));

  const setMFAError = useTuitionPaymentsStore((state) => state.setMFAError);
  const clearErrors = useTuitionPaymentsStore((state) => state.clearErrors);

  const updatePaymentPayload = useTuitionPaymentsStore((state) => state.updateTuitionPaymentPayload);

  const goToPrevStep = useTuitionPaymentsStore((state) => state.goToPrevStep);
  const goToNextStep = useTuitionPaymentsStore((state) => state.goToNextStep);
  const goToAdd529Details = useOnboardingStore((state) => state.goToAdd529Details);

  const skipTuitionPayment = useOnboardingStore((state) => state.skipTuitionPayment);

  const requestMFACode = useMutation({
    mutationKey: [MUTATION_KEY.REQUEST_INSTITUTION_PAYMENT_CODE],
    mutationFn: requestInstitutionPaymentMFACode,
    onError: (error) => {
      setMFAError(error);
    }
  });

  const createPaymentToken = useMutation({
    mutationKey: [MUTATION_KEY.CREATE_INSTITUTION_PAYMENT_TOKEN],
    mutationFn: createInstitutionPaymentToken,
    onSuccess: async (data) => {
      await updatePaymentPayload({
        institution_payment_token: data
      });

      clearErrors();

      if (isOnboarding
        && hasSkippedLinking
        && state529PlanDetails.planProviderType === State529ProviderType.DIRECT_DEBIT
      ) {
        goToAdd529Details(Flow.TUITION_PAYMENT);
      } else {
        goToNextStep();
      }
    },
    onError: (error) => {
      setMFAError(error);
    }
  });

  useEffect(() => {
    if (currentStep === TuitionPaymentSteps.MFA) {
      requestMFACode.mutate();
    }
  }, [currentStep]);

  return (
    <>
      <Switch>
        <Case condition={screen === TuitionPaymentSteps.NEW}>
          <TuitionPaymentNewScreen />
        </Case>

        <Case condition={screen === TuitionPaymentSteps.REVIEW}>
          <TuitionPaymentReviewScreen />
        </Case>

        <Case condition={screen === TuitionPaymentSteps.INITIATED}>
          <TuitionPaymentInitiatedScreen />
        </Case>
      </Switch>

      <MFAModal
        title="Confirm it's you"
        description={`For your security, we've texted a verification code to your phone ending in ${phoneNumberLast4}.`}
        open={currentStep === TuitionPaymentSteps.MFA}
        error={mfaError}
        handleClose={() => {
          clearErrors();
          goToPrevStep();
        }}
        handleMFACode={(code) => createPaymentToken.mutate({
          mfa_code: code,
          institution_id: beneficiary.enrollments[0].institution_id
        })}
        onRetry={() => requestMFACode.mutate()}
      />

      <ModalDialog
        open={currentStep === TuitionPaymentSteps.SKIPPED}
        onClose={goToPrevStep}
      >
        <h3 className="text-xl font-montserrat font-bold">Are you sure?</h3>

        <p className="mt-4 text-base">If you skip now, you can setup a Tuition Payment later by clicking the Pay Tuition button on the Dashboard.</p>

        <div className="mt-4">
          <Button
            primary
            rounded
            fullWidth
            data-testid="modal-dialog-skip-button"
            onClick={skipTuitionPayment}
          >
            Skip
          </Button>

          <Button
            text
            rounded
            size="sm"
            className="mt-1.5 font-medium"
            data-testid="modal-dialog-go-back-button"
            onClick={goToPrevStep}
          >
            Go Back
          </Button>
        </div>
      </ModalDialog>
    </>
  );
};

export default TuitionPayment;