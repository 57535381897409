import { If, Else, Then, When } from "react-if";
import clsx from "clsx";

import Stepper, { type StepperProps } from "@ui/Stepper/Stepper";
import WarningIcon from "@icons/WarningIcon";

type Link529HeaderProps = {
  children?: React.ReactNode;
  reAddBackpackAccount: boolean;
  reLinkWithdrawal: boolean;
  showStepper: boolean;
  stepper: StepperProps;
}

const Link529Header = ({
  children,
  reAddBackpackAccount,
  reLinkWithdrawal,
  showStepper,
  stepper,
}: Link529HeaderProps) => {
  return (
    <div>
      <h3 className={clsx(
        "text-lg mb-4 flex justify-center items-center gap-x-1",
        !reAddBackpackAccount && !reLinkWithdrawal ? "text-blue" : "text-warning"
      )}>
        <If condition={reAddBackpackAccount}>
          <Then>
            <WarningIcon className="size-8 text-warning-icon" aria-hidden="true" /> Re-add your account
          </Then>

          <Else>
            {children}
          </Else>
        </If>
      </h3>

      <When condition={reLinkWithdrawal}>
        <WarningIcon className="size-25 text-warning-icon" aria-hidden="true" />
      </When>

      <When condition={showStepper}>
        <Stepper {...stepper} />
      </When>
    </div>
  );
};

export default Link529Header;