import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Card from "@ui/Card/Card";
import DefaultErrorScreen from "@shared/Error/DefaultErrorScreen";

import { logError } from "@helpers/handleError";

const defaultFallbackRender = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full" data-testid="default-fallback-error-boundary">
      <Card className="flex flex-col items-center px-12 py-8 size-full sm:w-96 sm:min-h-96 gap-5" containerClassName="max-sm:border-none max-sm:shadow-none">
        <DefaultErrorScreen />

        <img
          height={25}
          width={129}
          src="/backpack.svg"
          alt="Backpack Logo"
        />
      </Card>
    </div>
  );
};

// Note [Santucho, 12/15/2023]: This error boundary layout allows us to catch errors in any of the routes without
// having to wrap each route in an error boundary.
const GlobalErrorBoundary = () => {
  return (
    <ErrorBoundary
      fallbackRender={defaultFallbackRender}
      onError={logError}
    >
      <Outlet />
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
