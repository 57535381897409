import { useNavigate } from "react-router-dom";
import { When } from "react-if";

import ErrorIcon from "@icons/ErrorIcon";
import Button from "@ui/Button/Button";
import ContactSupportButton from "@shared/ContactSupport/ContactSupportButton";

import { DEFAULT_CONTACT_SUPPORT_ERROR_BODY, DEFAULT_CONTACT_SUPPORT_ERROR_TITLE } from "@lib/constants/error";
import { BASE_ROUTE } from "@lib/constants/global";

type ContactSupportProps = {
  title?: string;
  showGoHome?: boolean;
}

const DefaultErrorScreen = ({
  title = DEFAULT_CONTACT_SUPPORT_ERROR_TITLE,
  showGoHome = false
}: ContactSupportProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col grow items-center my-12 gap-8 text-center">
      <h3 data-testid="contact-support-title" className="text-2xl font-bold">
        {title}
      </h3>

      <ErrorIcon
        aria-hidden="true"
        strokeWidth={2}
        outline
        className="stroke-red-600 fill-red-300 shrink-0 size-25"
      />

      <div className="flex flex-col gap-6">
        <p data-test-id="contact-support-text text-base">{DEFAULT_CONTACT_SUPPORT_ERROR_BODY}</p>

        <div className="flex flex-col gap-4">
          <ContactSupportButton />

          <When condition={showGoHome}>
            <Button
              rounded
              text
              size="sm"
              data-testid="go-back-btn"
              onClick={() => navigate(BASE_ROUTE.dashboard)}
            >
              Go Home
            </Button>
          </When>
        </div>
      </div>
    </div>
  );
};

export default DefaultErrorScreen;