import { useMemo } from "react";
import { Switch, Case, When } from "react-if";
import { useShallow } from "zustand/react/shallow";

import { Button } from "@mui/material";

import Card from "@ui/Card/Card";
// TODO: Replace with @ui/Tooltip
import Tooltip from "components/Tooltip";
import Notice from "@ui/Notice/Notice";
import Link529StatusNotice from "components/Link529/Link529StatusNotice";

import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import WarningIcon from "@icons/WarningIcon";

import { useUserProfileStore } from "@stores/userProfileStore";

import { FEEDBACK_TYPE } from "@lib/constants/theme";
import { NOTICE_TYPE } from "@lib/constants/userProfile";

import { LinkType } from "@lib/enums/link529";
import { State529ConnectionStatus, State529Provider } from "@lib/enums/state529";
import { ProfileType } from "@lib/enums/userProfile";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { State529Connection } from "@lib/types/state529";

type BeneficiaryProps = {
  beneficiary: Beneficiary;
  state529Connection: State529Connection;
  openLink529Modal: (linkType: LinkType) => void;
  openPayTuitionModal: () => void;
  openAddFundsModal: () => void;
};

const Beneficiary = ({
  beneficiary,
  state529Connection,
  openLink529Modal,
  openPayTuitionModal,
  openAddFundsModal
}: BeneficiaryProps) => {
  const {
    status,
    has_linked_before,
    is_withdrawal_eligible,
    is_automated_withdrawal_eligible,
    provider_name
  } = state529Connection;

  const beneficiaryFullName = `${beneficiary.first_name} ${beneficiary.last_name}`;

  const activeNotices = useUserProfileStore(useShallow((state) => state.profiles.activeNotices));
  const updateProfile = useUserProfileStore((state) => state.updateProfile);

  const showNotices = useMemo(() => {
    return Object.values(activeNotices).some((status) => !!status);
  }, [activeNotices]);

  const handleLink529ButtonClick = (linkType: LinkType) => {
    if (is_automated_withdrawal_eligible) {
      openLink529Modal(linkType);
    }
  };

  const showLinked529Icon = useMemo(() => {
    return !!state529Connection && has_linked_before && status === State529ConnectionStatus.LINKED;
  }, [
    state529Connection,
    has_linked_before,
    status
  ]);

  const onCloseNotice = (noticeType: typeof NOTICE_TYPE[keyof typeof NOTICE_TYPE]) => {
    updateProfile({
      type: ProfileType.ACTIVE_NOTICES,
      update: { [noticeType]: false },
      updateStatus: false,
    });
  };

  return (
    <div className="flex flex-col gap-y-2.5">
      <span className="block sm:hidden text-sm">Beneficiary</span>

      <Card
        data-cy="Beneficiary"
        containerClassName="w-full"
        className="flex flex-col gap-y-4 sm:gap-y-5"
      >
        <div className="flex flex-col sm:flex-row justify-between gap-x-2 gap-y-4 sm:gap-y-5">
          <div className="flex flex-col justify-between gap-4 lg:basis-2/5">
            <h3 className="text-xl md:text-2xl text-blue relative mr-6 break-words text-wrap">
              {beneficiaryFullName}
              <When condition={showLinked529Icon}>
                <Tooltip
                  title="529 Linked"
                  placement="top"
                  arrow
                >
                  <VerifiedOutlinedIcon className="size-5 text-blue absolute ml-0.5" data-cy="has-529-linked"/>
                </Tooltip>
              </When>
            </h3>

            <span className="hidden sm:block text-md xl:text-base mb-2">Beneficiary</span>
          </div>

          <Link529StatusNotice className="sm:hidden" state529Connection={state529Connection} />

          <div className="flex-1 flex flex-col gap-2.5">
            <Button
              data-cy="dashboard-initiate-payment"
              variant="contained"
              className="h-12 px-5 py-2.5 self-end w-full sm:max-w-60 xl:w-60 bg-gradient font-bold text-base"
              onClick={openPayTuitionModal}
            >
              Pay Tuition
            </Button>

            <Switch>
              {/* 529 plans that are automated_withdrawal_eligible */}
              <Case condition={provider_name === State529Provider.ASCENSUS}>
                <Switch>
                  <Case condition={status === State529ConnectionStatus.NOT_LINKED}>
                    <Button
                      data-cy="link-529-button"
                      variant="outlined"
                      className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end text-base secondary-button"
                      id="automated_link_eligible"
                      onClick={() => handleLink529ButtonClick(LinkType.LINK)}
                    >
                      Link 529
                    </Button>
                  </Case>

                  <Case condition={status === State529ConnectionStatus.NEEDS_RELINK}>
                    <Button
                      data-cy="relink-529-button"
                      variant="contained"
                      className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end font-bold text-base gap-1.5 bg-red-500 hover:bg-red-600"
                      onClick={() => handleLink529ButtonClick(LinkType.RELINK)}
                    >
                      <WarningIcon
                        outline
                        strokeWidth={2}
                        aria-hidden="true" className="size-5 text-white -mb-0.5"
                      /> Re-Link 529
                    </Button>
                  </Case>

                  <Case condition={status === State529ConnectionStatus.LINKED}>
                    <Button
                      data-cy="add-529-funds-button"
                      variant="outlined"
                      className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end text-base secondary-button"
                      disabled={!is_withdrawal_eligible}
                      onClick={openAddFundsModal}
                    >
                      Add 529 Funds
                    </Button>
                  </Case>
                </Switch>
              </Case>

              <Case condition={provider_name === State529Provider.FIDELITY}>
                <Button
                  data-cy="add-529-funds-button"
                  variant="outlined"
                  className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end text-base secondary-button"
                  onClick={openAddFundsModal}
                >
                  Add 529 Funds
                </Button>
              </Case>

              {/* 529 plans that require manual linking */}
              <Case condition={!provider_name}>
                <Button
                  data-cy="link-529-button"
                  variant="outlined"
                  className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end text-base secondary-button"
                  id="automated_link_uneligible"
                  onClick={() => handleLink529ButtonClick(LinkType.LINK)}
                >
                  {status === State529ConnectionStatus.NOT_LINKED ? "Link 529" : "Add 529 Funds"}
                </Button>
              </Case>
            </Switch>
          </div>
        </div>

        <When condition={showNotices}>
          <div className="flex flex-col gap-4">
            <When condition={activeNotices.pendingTuitionPayment}>
              <Notice
                data-testid="pending-tuition-payment-notice"
                icon={FEEDBACK_TYPE.WARNING}
                type={FEEDBACK_TYPE.WARNING}
                heading="Pending Tuition Payment"
                onClose={() => onCloseNotice(NOTICE_TYPE.PENDING_TUITION_PAYMENT)}
              >
                <p>Your tuition payment has been set up and will be processed once enough funds are added to your Backpack account. You can check the status below.</p>
              </Notice>
            </When>

            <When condition={activeNotices.withdrawalInProgress}>
              <Notice
                data-testid="withdrawal-in-progress-notice"
                icon={FEEDBACK_TYPE.INFO}
                type={FEEDBACK_TYPE.INFO}
                heading="Withdrawal In Progress"
                onClose={() => onCloseNotice(NOTICE_TYPE.WITHDRAWAL_IN_PROGRESS)}
              >
                <p>A withdrawal has been submitted. Withdrawals can take 1 - 4 business days to deposit into your Backpack account. You can check the status below.</p>
              </Notice>
            </When>
          </div>
        </When>

        <Link529StatusNotice className="max-sm:hidden" state529Connection={state529Connection} />
      </Card>
    </div>
  );
};

export default Beneficiary;