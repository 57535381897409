import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import AddFundsModal from "@components/AddFunds/AddFundsModal";

import { useAddFundsStore } from "@stores/addFundsStore";
import { useCurrentUserStore } from "@stores/currentUserStore";

const PersonalDebitPage = () => {
  const { step } = useParams();

  const hasInitializedAddFundsStore = useAddFundsStore(useShallow((state) => !!state.flow));
  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));

  const initializePersonalDebitFlow = useAddFundsStore((state) => state.initializePersonalDebitFlow);

  useEffect(() => {
    if (!beneficiary.id.length || hasInitializedAddFundsStore) return;

    if (!step && !!beneficiary.id.length) {
      initializePersonalDebitFlow(beneficiary);
      return;
    }
  }, [
    step,
    beneficiary,
    hasInitializedAddFundsStore
  ]);

  useEffect(() => {
    const handleUnload = () => {
      useAddFundsStore.persist.clearStorage();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <>
      <div className="page-container">
        <h1 className="sr-only" data-testid="page-header">Add Funds to your Backpack Account</h1>

        <main className="content-container">
          <AddFundsModal
            open={true}
          />
        </main>
      </div>
    </>
  );
};

export default PersonalDebitPage;