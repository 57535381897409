import { FC, useMemo } from "react";
import { formatCurrency } from "@utils/deprecated/formats";
import { Container, containerClasses, Stack, styled, Typography } from "@mui/material";

import Tooltip from "@ui/Tooltip/Tooltip";
import InformationCircleIcon from "@icons/InformationCircleIcon";

import type { TransactionResponse } from "@lib/types/transaction";
import type { InstitutionPayment } from "@lib/types/institutionPayment";
import type { State529WithdrawalRequest } from "@lib/types/state529";

interface TransactionProps {
  transaction: TransactionResponse | InstitutionPayment | State529WithdrawalRequest;
  variant: "transactions" | "payments" | "withdrawals";
}

const RowContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "1rem",
  alignItems: "center",
  borderBottom: "1px solid rgba(0,0,0, 0.05)",
  "&:first-of-type": {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  [`&.${containerClasses.root}`]: {
    padding: "1rem",
    maxWidth: "calc(100vw - 2rem)",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "700",
  [theme.breakpoints.down("md")]: {
    width: "30%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Information = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    width: "70%",
    gap: "0.2rem",
  },
}));

const InformationElement = styled(Stack)(({ theme }) => ({
  fontSize: "0.875rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.75rem",
    textAlign: "right",
  },
}));

const Price = styled(InformationElement)({ width: "5rem" });
const Status = styled(InformationElement)({
  width: "8rem",
  alignSelf: "flex-end",
});
const Date = styled(InformationElement)(({ theme }) => ({
  width: "5rem",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const TransactionRow: FC<TransactionProps> = ({ transaction, variant }) => {
  const details = useMemo(() => {
    const formattedAmount = formatCurrency(transaction.amount_in_cents);

    if (variant === "payments") {
      return {
        name: (transaction as InstitutionPayment).institution.name,
        amount: `-${formattedAmount}`,
        date: (transaction as InstitutionPayment).date,
        status: "Awaiting Funds",
        tooltipText: "Awaiting deposit of funds to complete this payment."
      };
    }

    if (variant === "withdrawals") {
      return {
        name: (transaction as State529WithdrawalRequest).state_529_plan.name,
        amount: formattedAmount,
        date: (transaction as State529WithdrawalRequest).date,
        status: "PROCESSING",
        tooltipText: "Your 529 is processing the withdrawal request and transferring funds to Backpack."
      };
    }

    return {
      name: (transaction as TransactionResponse).description,
      amount: formattedAmount,
      date: (transaction as TransactionResponse).date,
      status: (transaction as TransactionResponse).type,
      tooltipText: ""
    };
  }, [transaction, variant]);

  return (
    <RowContainer>
      <Title>{details.name}</Title>
      <Information>
        <Price>{details.amount}</Price>
        <Date className="whitespace-nowrap">{details.date}</Date>
        <Status>
          <Tooltip text={details.tooltipText} position="left">
            <span className="flex gap-1 items-center">
              <span className="whitespace-nowrap">{details.status}</span>
              <InformationCircleIcon
                outline
                aria-hidden={true}
                strokeWidth={3}
                className="size-4 text-gray-500 shrink-0"
              />
            </span>
          </Tooltip>
        </Status>
      </Information>
    </RowContainer>
  );
};

export default TransactionRow;
