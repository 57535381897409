/**
 * Constants representing various analytics event origins.
 * These event origins are used to track where the event originated from in the app
 *
 */
export const ANALYTICS_EVENT_ORIGIN = {
  DASHBOARD: "Dashboard",
  ONBOARDING: "Onboarding",
  REGISTRATION: "Registration",
};

/**
 * Constants representing the type of analytics events.
 * These event types define how an event is recorded or tracked.
 *
 * @property {string} SCREEN - Event type used when a user sees a screen
 * @property {string} TRACK - Event type used to track user actions
 */
export const ANALYTICS_EVENT_TYPE = {
  SCREEN: "screen",
  TRACK: "track"
};


/**
 * Constants representing different analytics event actions.
 * These actions describe specific interactions or outcomes within an event.
 *
 */
export const ANALYTICS_EVENT_ACTION = {
  BUTTON_CLICK: "BUTTON_CLICK",
  FORM_SUBMIT: "FORM_SUBMIT",
  ON_LOAD: "ON_LOAD",
  ON_SUCCESS: "ON_SUCCESS",
};