import { Suspense } from "react";
import { useShallow } from "zustand/react/shallow";
import { Case, Switch, When } from "react-if";

import BellIcon from "@icons/BellIcon";
import CheckCircleIcon from "@icons/CheckCircleIcon";
import ShieldExclamationIcon from "@icons/ShieldExclamationIcon";
import SpeakerphoneIcon from "@icons/SpeakerphoneIcon";
import Button from "@ui/Button/Button";
import Well from "@ui/Well/Well";
import Spinner from "@ui/Spinner/Spinner";

import { VERIFICATION_TYPE } from "@components/Link529/Link529Manual/constants";

import { useLink529Store } from "@stores/link529Store";

const AccountVerificationScreen = () => {
  const planType = useLink529Store(useShallow((state) => state.planType));
  const linking_guide = useLink529Store(useShallow((state) => state.guides.linking));

  const goToPrevStep = useLink529Store((state) => state.goToPrevStep);
  const goToNextStep = useLink529Store((state) => state.goToNextStep);

  return (
    <Suspense fallback={<Spinner size="lg"/>}>
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between">
        <h2 className="modal-header-title">Account Verification</h2>

        <p className="modal-header-description">
          Safety first! Your account needs to be verified before you can make a withdrawal.
        </p>

        <div className="text-sm pb-6">
          <ul className="flex flex-col gap-6 pt-8 pb-4">
            <When condition={planType !== VERIFICATION_TYPE.DIRECT_PAY && planType !== VERIFICATION_TYPE.PLAID_LINK}>
              <Switch>
                <Case condition={planType === VERIFICATION_TYPE.HOLD_15_DAY}>
                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p><strong>Your 529 Plan has a 15 day security hold</strong> before you can make your first withdrawal and complete your payment.</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <BellIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>Don&apos;t worry, there&apos;s nothing you need to do right now. <strong>We&apos;ll notify you when the hold period is over.</strong></p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <CheckCircleIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>The notification will provide <strong>instructions on next steps</strong> to complete your payment!</p>
                  </li>
                </Case>

                <Case condition={planType === VERIFICATION_TYPE.HOLD_15_DAY_WITHDRAWAL_SETUP}>
                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p><strong>Your 529 Plan has a 15 day security hold</strong> before your first withdrawal is issued.</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <BellIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>Don&apos;t worry, you <strong>can setup a withdrawal now</strong> so that it is automatically completed once the hold period is over.</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <CheckCircleIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>We will <strong>notify you when the hold period is over and the withdrawal is complete.</strong></p>
                  </li>
                </Case>

                <Case condition={planType === VERIFICATION_TYPE.MICRODEPOSITS}>
                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>Your 529 Plan uses micro-deposits to verify new accounts and will make <strong>two small deposits into your Backpack account</strong>.</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <BellIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>We will <strong>notify you when the deposits land</strong> in your Backpack account (it may take 1-2 business days).</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <CheckCircleIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>Log into your 529 Portal to view your linked bank accounts and enter the <strong>dollar amounts of the deposits to complete verification.</strong></p>
                  </li>
                </Case>

                <Case condition={planType === VERIFICATION_TYPE.BANK_FORMS}>
                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p><strong>Your 529 Plan has a 10 day security hold</strong> before you can make your first withdrawal and complete your payment.</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <BellIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>Don&apos;t worry, there&apos;s nothing you need to do right now. <strong>We will notify you when the hold period is over.</strong></p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <CheckCircleIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>The notification will provide <strong>instructions on next steps</strong> to complete your payment!</p>
                  </li>
                </Case>

                <Case condition={planType === VERIFICATION_TYPE.OTHER || planType === VERIFICATION_TYPE.DEFAULT}>
                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p><strong>Your 529 Plan may require up to a 15 day security hold</strong> before you can make your first withdrawal and complete your payment.</p>
                  </li>
                </Case>

                <Case condition={planType === VERIFICATION_TYPE.HOLD_30_DAY}>
                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p><strong>Your 529 Plan has a 30 day security hold</strong> before you can make your first withdrawal and complete your payment.</p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <BellIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>Don&apos;t worry, there&apos;s nothing you need to do right now. <strong>We&apos;ll notify you when the hold period is over.</strong></p>
                  </li>

                  <li className="flex gap-4">
                    <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                      <CheckCircleIcon outline strokeWidth={2} aria-hidden="true" />
                    </span>

                    <p>The notification will provide <strong>instructions on next steps</strong> to complete your payment!</p>
                  </li>
                </Case>
              </Switch>
            </When>
          </ul>

          <When condition={planType === VERIFICATION_TYPE.MICRODEPOSITS}>
            <p>See our guide for detailed steps on <a href={linking_guide} className="link" target="_blank" rel="noreferrer">linking and verifying your Backpack account</a>.</p>
          </When>
        </div>

        <Well color="sky" className="flex items-start gap-2">
          <SpeakerphoneIcon strokeWidth={2} className="stroke-sky-800 fill-sky-400 shrink-0 mt-1.5" aria-hidden="true" />

          <span className="text-sm">Once verified, all future payments through Backpack will be quick and easy. </span>
        </Well>
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          onClick={goToPrevStep}
        >
          Back
        </Button>

        <Button
          rounded
          fullWidth
          onClick={goToNextStep}
        >
          Continue
        </Button>
      </div>
      </div>
    </Suspense>
  );
};

export default AccountVerificationScreen;