import { useState } from "react";
import { Case, Switch, If, Else, Then } from "react-if";

import data from "../../data/insights.json";

import Link529Modal from "components/Link529/Link529Modal";
// TODO: Replace this with @ui/Modal/Modal
import Modal from "components/Modal";
import InsightCards from "components/Insights/InsightCards";

import UniversityExclusive from "components/Insights/InsightsModalDetails/UniversityExclusive";
import PurchaseLaptop from "components/Insights/InsightsModalDetails/PurchaseLaptop";
import StoreReceipts from "components/Insights/InsightsModalDetails/StoreReceipts";
import ShopAmazon from "components/Insights/InsightsModalDetails/ShopAmazon";

import { INSIGHT_MODALS } from "components/Insights/constants";

import type { BankAccount } from "@lib/types/account";
import type { Beneficiary } from "@lib/types/beneficiary";
import type { State529Plan } from "@lib/types/state529";

export type InsightsProps = {
  profiles: {
    bankAccount: BankAccount | undefined;
    beneficiary: Beneficiary | undefined;
    state529Plan: State529Plan | undefined;
  };
};

const Insights = ({ profiles }: InsightsProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleInsight = (modal?: string) => {
    if (!modal) return;

    const hasNonAutomatedLinkButton = document.getElementById("automated_link_uneligible");

    if (INSIGHT_MODALS[modal] === INSIGHT_MODALS.LINK_529 && !!hasNonAutomatedLinkButton) {
      return hasNonAutomatedLinkButton.click();
    }

    setOpenModal(true);
    setModalType(modal);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setModalType("");
  };

  if (profiles.bankAccount === undefined || profiles.beneficiary === undefined) return null;

  return (
    <>
      <InsightCards
        insights={data}
        onHandleInsight={(modal) => handleInsight(modal)}
      />

      <If condition={INSIGHT_MODALS[modalType] === INSIGHT_MODALS.LINK_529}>
        <Then>
          <Link529Modal
            bankAccount={profiles.bankAccount}
            beneficiary={profiles.beneficiary}
            state529Plan={profiles.state529Plan}
            open={openModal && (INSIGHT_MODALS[modalType] === INSIGHT_MODALS.LINK_529)}
            handleOnClose={handleModalClose}
          />
        </Then>

        <Else>
          <Modal
            maxWidth="sm"
            open={openModal}
            handleClose={handleModalClose}
          >
            <div className="flex flex-col overflow-y-auto p-2 sm:p-8 sm:pt-0">
              <Switch>
                <Case condition={INSIGHT_MODALS[modalType] === INSIGHT_MODALS.UNIVERSITY_EXCLUSIVE}>
                  <UniversityExclusive />
                </Case>

                <Case condition={INSIGHT_MODALS[modalType] === INSIGHT_MODALS.PURCHASE_LAPTOP}>
                  <PurchaseLaptop />
                </Case>

                <Case condition={INSIGHT_MODALS[modalType] === INSIGHT_MODALS.STORE_RECEIPTS}>
                  <StoreReceipts />
                </Case>

                <Case condition={INSIGHT_MODALS[modalType] === INSIGHT_MODALS.SHOP_AMAZON}>
                  <ShopAmazon />
                </Case>
              </Switch>
            </div>
          </Modal>
        </Else>
      </If>
    </>
  );
};

export default Insights;
