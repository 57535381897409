import { When } from "react-if";

import InputDescription from "@ui/Input/InputDescription";

import { INPUT_TYPE, useInputAttributes } from "@hooks/useInputAttributes";
import type { InputProps } from "@lib/types/ui";

const InputText = ({
  id,
  error,
  helper,
  label,
  name,
  privacy,
  hideLabel = false,
  ...inputAttrs
}: InputProps) => {
  const {
    labelClassName,
    inputClassName,
    ariaDescribedBy,
    description
  } = useInputAttributes({
    id,
    error,
    helper,
    hideLabel,
    privacy,
    type: INPUT_TYPE.TEXT
  });

  return (
    <div>
      <label
        className={labelClassName}
        data-testid="input-text-component-label"
        htmlFor={id}
      >
        <>
          {label}
        </>
      </label>

      <input
        type="text"
        className={inputClassName}
        data-testid="input-text-component"
        id={id}
        name={name || id}
        aria-invalid={!!error || undefined}
        aria-describedby={ariaDescribedBy}
        {...inputAttrs}
      />

      <When condition={!!helper || !!error}>
        <InputDescription
          id={ariaDescribedBy || ""}
          helper={!!helper}
          error={!!error}
        >
          {description}
        </InputDescription>
      </When>
    </div>
  );
};

export default InputText;