/**
 * Deprecated
 */
import { FC } from "react";
import { Button } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export type Links = { label: string; screen: string }[];

const LinkList: FC<{ links: Links; openScreen: (screen: any) => void }> = ({
  links,
  openScreen,
}) => {
  return (
    <ul className="flex flex-col gap-4 link-list">
      {links.map((link) => (
        <li key={link.label}>
          <Button
            className="justify-between px-6 py-4 w-full text-primary font-bold text-left rounded-lg cursor-pointer shadow hover:shadow-gray-300"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => openScreen(link.screen)}
          >
            {link.label}
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default LinkList;
