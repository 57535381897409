import { create } from "@stores/utils";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  ONBOARDING_STEP,
  ONBOARDING_DESCRIPTION_VARIANT,
  TUITION_PAYMENT_CREATED_STEPS,
  DEFAULT_STEPS,
  DEFAULT_INITIAL_STATE
 } from "@lib/constants/onboarding";

import {
  Flow,
  OnboardingSteps,
  TuitionPaymentSteps,
  ManualLink529Steps
} from "@lib/enums/flows";
import { ProfileType } from "@lib/enums/userProfile";
import { State529ProviderType } from "@lib/enums/state529";

import { checkProfileStatus } from "@helpers/checkProfileStatus";

import { useUserProfileStore } from "@stores/userProfileStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";
import { useLink529Store } from "@stores/link529Store";
import { useCurrentUserStore } from "@stores/currentUserStore";

import type { ProgressStep } from "@ui/Stepper/ProgressStepper";
import type { State529PlanDetails } from "@lib/types/state529";
import type { CreateInstitutionPaymentPayload } from "@lib/types/institutionPayment";

export type OnboardingStep = {
  name: OnboardingSteps;
} & Partial<ProgressStep>;

type OnboardingStatus = {
  [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.SKIPPED | TuitionPaymentSteps.CREATED | null;
  [Flow.LINK_529]: ManualLink529Steps.MANUAL | ManualLink529Steps.SKIPPED | null;
}

type OnboardingState = {
  step: OnboardingStep | Pick<OnboardingStep, "name"> | null;
  stepCount: number;
  steps: OnboardingStep[];
  nestedFlow: Flow | null;
  status: OnboardingStatus;
  state529PlanDetails: State529PlanDetails;
  origin: Flow | null;
  tuitionPaymentPayload: CreateInstitutionPaymentPayload | null;
};

type OnboardingAction = {
  initializeOnboarding: (redirectURL?: string) => void;
  goToNextStep: () => void;
  goToAdd529Details: (origin?: Flow) => void;
  goToPayTuition: () => void;
  goBackToPayTuition: () => void;
  goToLink529Manual: () => void;
  skipTuitionPayment: () => void;
  skipManualLinking: () => void;
  setNestedFlow: (nestedFlow: OnboardingState["nestedFlow"]) => void;
  updateState529PlanDetails: (update: Partial<State529PlanDetails>, updateTuitionPaymentFlow?: boolean) => void;
  updateLinkStatus: (update: OnboardingStatus["link-529"]) => void;
  updateStepper: (currentStep: TuitionPaymentSteps) => void;
  clearAccountDetails: () => void;
  goToFinalScreen: () => void;
  goToFinalScreenForDirectDebit: (hasLinked529: boolean) => void;
};

/**
 *
 * @param steps the current progress stepper steps
 * @param step the step description to update
 * @param variant if there is a variant description to use, otherwise default is completed
 * @returns updated steps to use with progress stepper
 */
const updateProgressStepper = ({ steps, step, variant = "completed" }: {
  steps: OnboardingStep[];
  step: OnboardingSteps;
  variant?: "created" | "completed" | "skipped" | "skipped_linking";
}): OnboardingStep[] => {
  return steps.map((onboardingStep) => {
    if (onboardingStep.name !== step) return onboardingStep;

    const updatedStep = { ...onboardingStep };

    const title = ONBOARDING_DESCRIPTION_VARIANT[step][variant].title;
    const description = ONBOARDING_DESCRIPTION_VARIANT[step][variant].description;

    if (title) updatedStep.title = title;
    if (description) updatedStep.description = description;

    return updatedStep;
  });
};

export const useOnboardingStore = create<OnboardingState & OnboardingAction>()(
  persist(
    (set, get) => ({
      ...DEFAULT_INITIAL_STATE,

      initializeOnboarding: (redirectURL) => {
        const currentUser = useCurrentUserStore.getState().currentUser;
        const profile = useUserProfileStore.getState().profiles.onboarding;
        const planProviderType = useUserProfileStore.getState().profiles.onboarding.planProviderType;

        const status = get().status;
        const nestedFlow = get().nestedFlow;
        const state529PlanDetails = get().state529PlanDetails;

        let redirectStep: (OnboardingSteps | null) = null;

        if (redirectURL) {
          const redirectPathNames = redirectURL.split("/");
          redirectStep = redirectPathNames[redirectPathNames.length - 1] as OnboardingSteps;
        }

        const {
          hasBeneficiary,
          hasInstitutionPayments,
          hasTransactions,
          hasLinked529,
          hasSkippedTuitionPayment
        } = profile;

        const userOnlyHasBeneficiary = hasBeneficiary
          && !hasTransactions
          && !hasInstitutionPayments
          && !hasLinked529
          && !hasSkippedTuitionPayment;

        // set the default currentStep and stepCount
        // to the Welcome screen (first step)
        // unless the redirect is to student_details
        let step: OnboardingState["step"] = ONBOARDING_STEP[OnboardingSteps.WELCOME];
        let stepCount = ONBOARDING_STEP[OnboardingSteps.WELCOME].step;
        let updatedSteps: OnboardingStep[] = [...DEFAULT_STEPS];
        let updatedStatus = { ...status };
        let updatedNestedFlow = nestedFlow;
        let updatedState529PlanDetails = { ...state529PlanDetails };

        if (hasBeneficiary) {
          updatedState529PlanDetails = {
            ...state529PlanDetails,
            planProviderType,
            state529Plan: currentUser.beneficiaries[0].state_529_plan
          };
        }

        // update the step if the redirect step is to student_details
        if (redirectStep === OnboardingSteps.STUDENT_DETAILS) {
          step = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS];
          stepCount = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS].step;
        }

        // if a user has a beneficiary
        // but has no transactions and no pending institution payments
        // and the plan provider type is NOT direct debit
        // show the confirm attendance screen
        if (userOnlyHasBeneficiary && planProviderType !== State529ProviderType.DIRECT_DEBIT) {
          step = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE];

          stepCount = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE].step;

          updatedSteps = updateProgressStepper({
            steps: DEFAULT_STEPS,
            step: OnboardingSteps.STUDENT_DETAILS
          }) as OnboardingStep[];
        }

        // if a user has a beneficiary
        // but has no transactions and no pending institution payments
        // and the plan provider type IS direct debit
        // show the add 529 details screen
        if (userOnlyHasBeneficiary && planProviderType === State529ProviderType.DIRECT_DEBIT) {
          step = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS];

          stepCount = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS].step;
          updatedNestedFlow = Flow.DIRECT_DEBIT;
        }

        if (hasBeneficiary
          && hasInstitutionPayments
          && !hasTransactions
          && !hasLinked529
        ) {
          step = ONBOARDING_STEP[OnboardingSteps.LINK_529];
          stepCount = ONBOARDING_STEP[OnboardingSteps.LINK_529].step;
          updatedSteps = [...TUITION_PAYMENT_CREATED_STEPS];

          updatedStatus = {
            ...status,
            [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.CREATED,
          };
        }

        // if a user has a beneficiary
        // and no pending institution payments
        // and the plan provider type IS direct debit
        // and has linked their 529
        // show the do you have a tuition payment
        if (hasBeneficiary
          && hasLinked529
          && !hasInstitutionPayments
          && planProviderType === State529ProviderType.DIRECT_DEBIT
        ) {
          step = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE];
          stepCount = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE].step;

          updatedSteps = updateProgressStepper({
            steps: DEFAULT_STEPS,
            step: OnboardingSteps.STUDENT_DETAILS
          }) as OnboardingStep[];
        }

        // if everything inside the onboarding profile is true
        // or if the user has transactions or institutions payments
        // then set the step to null aka do not show onboarding
        if (checkProfileStatus(ProfileType.ONBOARDING, profile) || hasSkippedTuitionPayment) {
          step = null;
          stepCount = 0;
        }

        set({
          stepCount,
          step,
          steps: updatedSteps,
          status: updatedStatus,
          nestedFlow: updatedNestedFlow,
          state529PlanDetails: updatedState529PlanDetails,
        });
      },

      goToNextStep: () => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();
        const userProfileStore = useUserProfileStore.getState();

        const currentStep: OnboardingState["step"] = get().step;
        const steps = get().steps;
        const status = get().status;

        let nextStep = ONBOARDING_STEP[OnboardingSteps.DONE];
        let stepCount = steps.length + 1;
        let updatedSteps = [...steps];
        let updatedStatus = { ...status };

        switch (currentStep?.name) {
          case OnboardingSteps.WELCOME:
          default:
            nextStep = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS];
            stepCount = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS].step;
            break;

          case OnboardingSteps.STUDENT_DETAILS:
            nextStep = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE];

            stepCount = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE].step;

            updatedSteps = updateProgressStepper({
              steps,
              step: OnboardingSteps.STUDENT_DETAILS
            }) as OnboardingStep[];

            break;

          case OnboardingSteps.CONFIRM_ATTENDANCE:
            // reset tuition payment store to default state
            tuitionPaymentStore.reset();

            nextStep = ONBOARDING_STEP[OnboardingSteps.LINK_529];

            stepCount = ONBOARDING_STEP[OnboardingSteps.LINK_529].step;

            updatedSteps = updateProgressStepper({
              steps,
              step: OnboardingSteps.CONFIRM_ATTENDANCE,
              variant: "created"
            }) as OnboardingStep[];

            updatedStatus = {
              ...status,
              [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.CREATED,
            };

            break;

          case OnboardingSteps.LINK_529:
            userProfileStore.updateProfileStatus({ onboarding: true });

            nextStep = ONBOARDING_STEP[OnboardingSteps.DONE];
            stepCount = steps.length + 1;
            break;
        }

        set({
          stepCount,
          step: nextStep,
          steps: updatedSteps,
          status: updatedStatus,
          nestedFlow: null,
        });
      },

      goToAdd529Details: (flow) => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();

        if (flow === Flow.TUITION_PAYMENT) {
          set((state) => ({
            ...state,
            step: ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS],
            stepCount: ONBOARDING_STEP[OnboardingSteps.LINK_529].step,
            nestedFlow: Flow.DIRECT_DEBIT,
            origin: Flow.TUITION_PAYMENT,
            tuitionPaymentPayload: tuitionPaymentStore.tuitionPaymentPayload
          }));
        } else {
          set({ nestedFlow: Flow.DIRECT_DEBIT });
        }
      },

      goToPayTuition: () => {
        const currentUserStore = useCurrentUserStore.getState();
        const userProfileStore = useUserProfileStore.getState();
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();

        const state529Plan = currentUserStore.currentUser.beneficiaries[0].state_529_plan;
        const planProviderType = userProfileStore.profiles.onboarding.planProviderType;

        tuitionPaymentStore.setIsOnboarding({
          state529Plan,
          planProviderType
        });

        set({ nestedFlow: Flow.TUITION_PAYMENT });
      },

      goBackToPayTuition: () => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();
        const state529PlanDetails = get().state529PlanDetails;
        const tuitionPaymentPayload = get().tuitionPaymentPayload;

        tuitionPaymentStore.reEnterTuitionPayment(state529PlanDetails, tuitionPaymentPayload);

        set((state) => ({
          ...state,
          step:ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE],
          stepCount: ONBOARDING_STEP[OnboardingSteps.LINK_529].step,
          nestedFlow: Flow.TUITION_PAYMENT,
          status: {
            ...state.status,
            [Flow.LINK_529]: null,
          }
        }));
      },

      goToLink529Manual: () => {
        set((state) => ({
          nestedFlow: Flow.LINK_529,
          status: {
            ...state.status,
            [Flow.LINK_529]: ManualLink529Steps.MANUAL
          }
        }));
      },

      skipTuitionPayment: () => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();
        const userProfileStore = useUserProfileStore.getState();

        const planProviderType = userProfileStore.profiles.onboarding.planProviderType;

        const steps = get().steps;

        const updatedSteps = updateProgressStepper({
          steps,
          step: OnboardingSteps.CONFIRM_ATTENDANCE,
          variant: "skipped"
        });

        // reset tuition payment store to default state
        tuitionPaymentStore.reset();

        let nextStep = ONBOARDING_STEP[OnboardingSteps.LINK_529] as OnboardingState["step"];
        let stepCount = ONBOARDING_STEP[OnboardingSteps.LINK_529].step;

        if (planProviderType === State529ProviderType.DIRECT_DEBIT) {
          nextStep = ONBOARDING_STEP[OnboardingSteps.FINAL_SCREEN];
          stepCount = steps.length + 1;
        }

        // update profile store that tuition payment was skipped
        userProfileStore.updateProfile({
          type: ProfileType.ONBOARDING,
          update: { hasSkippedTuitionPayment: true }
        });

        set((state) => ({
          step: nextStep,
          stepCount: stepCount,
          steps: updateProgressStepper({
            steps: updatedSteps,
            step: OnboardingSteps.LINK_529,
            variant: "skipped"
          }),
          status: {
            ...state.status,
            [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.SKIPPED
          },
          nestedFlow: null,
        }));
      },

      skipManualLinking: () => {
        const link529Store = useLink529Store.getState();
        const steps = get().steps;

        // reset link 529 store to default state
        link529Store.reset();

        set((state) => ({
          step: ONBOARDING_STEP[OnboardingSteps.FINAL_SCREEN],
          stepCount: steps.length + 1,
          steps: updateProgressStepper({
            steps,
            step: OnboardingSteps.LINK_529,
            variant: "skipped_linking"
          }),
          status: {
            ...state.status,
            [Flow.LINK_529]: ManualLink529Steps.SKIPPED
          },
          nestedFlow: null,
        }));
      },

      setNestedFlow: (nestedFlow) => {
        set({ nestedFlow });
      },

      updateLinkStatus: (update) => {
        set((state) => ({
          ...state,
          status: {
            ...state.status,
            [Flow.LINK_529]: update
          }
        }));
      },

      updateState529PlanDetails: (update, updateTuitionPaymentFlow) => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();

        if (updateTuitionPaymentFlow) {
          tuitionPaymentStore.setState529PlanDetails(update);
        }

        set((state) => ({
          ...state,
          state529PlanDetails: {
            ...state.state529PlanDetails,
            ...update
          }
        }));
      },

      clearAccountDetails: () => {
        set((state) => ({
          ...state,
          state529PlanDetails: {
            ...state.state529PlanDetails,
            account_number: null,
            routing_number: null
          }
        }));
      },

      goToFinalScreen: () => {
        const steps = get().steps;
        let updatedSteps = [...steps];

        updatedSteps = updateProgressStepper({
            steps,
            step: OnboardingSteps.LINK_529,
            variant: "completed"
          }) as OnboardingStep[];

        set((state) => ({
          ...state,
          stepCount: steps.length + 1,
          step: ONBOARDING_STEP[OnboardingSteps.FINAL_SCREEN],
          steps: updatedSteps,
          status: {
            ...state.status,
            [Flow.LINK_529]: null,
          },
          nestedFlow: null,
        }));
      },

      goToFinalScreenForDirectDebit: (hasLinked529) => {
        const userProfileStore = useUserProfileStore.getState();
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();
        const steps = get().steps;

        let updatedSteps = updateProgressStepper({
          steps: [...steps],
          step: OnboardingSteps.CONFIRM_ATTENDANCE,
          variant: "created"
        }) as OnboardingStep[];

        updatedSteps = updateProgressStepper({
          steps: updatedSteps,
          step: OnboardingSteps.LINK_529,
          variant: "completed"
        }) as OnboardingStep[];

        // reset tuition payment store to default state
        tuitionPaymentStore.reset();

        // update the onboarding profile
        // if a user has linked their 529
        if (hasLinked529) {
          userProfileStore.updateProfile({
            type: ProfileType.ONBOARDING,
            update: { hasLinked529: true }
          });
        }

        set((state) => ({
          ...state,
          stepCount: steps.length + 1,
          step: ONBOARDING_STEP[OnboardingSteps.FINAL_SCREEN],
          steps: updatedSteps,
          status: {
            ...state.status,
            [Flow.LINK_529]: null,
          },
          nestedFlow: null,
        }));
      },

      updateStepper: (step) => {
        if (step === TuitionPaymentSteps.REVIEW) {
          set({ stepCount: ONBOARDING_STEP[OnboardingSteps.LINK_529].step });
        }

        if (step === TuitionPaymentSteps.NEW) {
          set({ stepCount: ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE].step });
        }
      }
    }),
    {
      name: "onboarding",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);