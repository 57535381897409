import Select from "@ui/Select/Select";
import DateRangePicker from "@ui/DateRangePicker/DateRangePicker";
import TransactionsTableFilterChips from "@components/Transactions/TransactionTableFilterChips";

import { TransactionType } from "@lib/enums/transaction";
import { useTransactionsStore } from "@stores/transactionsStore";
import ArrowDownTrayIcon from "@icons/ArrowDownTrayIcon";
import { generateCSVDownloadURL } from "@utils/generateCSVDownloadURL";
import { InstitutionPaymentStatus } from "@lib/enums/institutionPayment";
import { TRANSACTION_TYPE_FILTER_OPTIONS } from "@components/Transactions/constants";

export type FilterByType = TransactionType.DEPOSIT | TransactionType.WITHDRAWAL | InstitutionPaymentStatus.PENDING | "ALL";

const TransactionsTableFilters = () => {
  const transactions = useTransactionsStore((state) => state.transactions);
  const transactionTypeFilter = useTransactionsStore((state) => state.filters.type);
  const transactionDateFilter = useTransactionsStore((state) => state.filters.date);

  const filterByType = useTransactionsStore((state) => state.filterByType);
  const filterByDate = useTransactionsStore((state) => state.filterByDate);

  const handleDownloadCSV = (event: React.MouseEvent) => {
    event.preventDefault();

    const endcodedURI = generateCSVDownloadURL({
      header: ["Date", "To/From", "Amount", "Beneficiary"],
      data: transactions.map((transaction) => ({
        "Date": transaction.date,
        "To/From": transaction.transaction_name,
        "Amount": transaction.amount,
        "Beneficiary": transaction.beneficiary_fullname
      }))
    });

    window.open(endcodedURI);
  };

  return (
    <div className="flex flex-col xl:flex-row items-start gap-3 mb-6" data-testid="transaction-table-filters-component">
      <div className="flex flex-wrap md:flex-nowrap items-start gap-3">
        <a
          href="#"
          className="h-9 inline-flex items-center shadow-sm p-1.5 rounded-md ring-1 ring-inset ring-gray-300 text-primary hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue"
          data-testid="transaction-csv-download-btn"
          onClick={(event) => handleDownloadCSV(event)}
        >
          <span className="sr-only">Download Transactions CSV</span>

          <ArrowDownTrayIcon aria-hidden="true" className="-mt-px size-6" />
        </a>

        <div>
          <Select
            hideLabel
            data-testid="transactions-type-filter"
            id="transactions_type_filter"
            label="Transaction type filter"
            options={TRANSACTION_TYPE_FILTER_OPTIONS}
            selected={transactionTypeFilter}
            onChange={(event) => filterByType(event.currentTarget.value as FilterByType)}
          />
        </div>

        <DateRangePicker
          data-testid="transaction-date-filter"
          resetInputs={!transactionDateFilter}
          onDateRangeChange={(dateRanges) => filterByDate(dateRanges)}
        />
      </div>

      <TransactionsTableFilterChips />
    </div>
  );
};

export default TransactionsTableFilters;