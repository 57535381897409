import { Suspense, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";

import Spinner from "@ui/Spinner/Spinner";

import { useAuth } from "@hooks/useAuth";

import LoggedInLayout from "@layouts/LoggedInLayout";

import { FOREVER_STALE_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getInstitutions } from "@lib/queries/institutionQueries";
import { getState529Plans } from "@lib/queries/state529Queries";

import { useUserProfileStore } from "@stores/userProfileStore";

const ProtectedRoutes = () => {
  const queryClient = useQueryClient();
  const { logout, logoutPath, isAuthed } = useAuth();

  const hasUserProfiles = useUserProfileStore(useShallow((state) => state.initialized));

  // prefetch queries
  useEffect(() => {
    if (!isAuthed()) {
      logout(false);
    }

    if (isAuthed() && !hasUserProfiles) {
      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY.INSTITUTIONS],
        queryFn: getInstitutions,
        ...FOREVER_STALE_QUERY_OPTIONS
      });

      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY.STATE_529_PLANS],
        queryFn: getState529Plans,
        ...FOREVER_STALE_QUERY_OPTIONS
      });
    }
  }, [isAuthed()]);

  // when a user is not logged in and/or auth has expired
  // redirect the user to login
  if (!isAuthed()) {
    return <Navigate to={logoutPath} replace />;
  }

  return (
    <LoggedInLayout>
      <Suspense fallback={<Spinner fullScreen />}>
        <Outlet />
      </Suspense>
    </LoggedInLayout>
  );
};

export default ProtectedRoutes;