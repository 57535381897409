/**
 * Enum for Flows
 *
 * TUITION_PAYMENT = "tuition-payment"
 *
 * DIRECT_DEBIT = "direct-debit"
 *
 * LINK_529 = "link-529"
 */
export enum Flow {
  DIRECT_DEBIT = "direct-debit",
  PERSONAL_DEBIT = "personal-debit",
  TUITION_PAYMENT = "tuition-payment",
  LINK_529 = "link-529",
}

/**
 * Enum for onboarding flow steps
 *
 * The values are also used in the url
 *
 * WELCOME, STUDENT_DETAILS, CONFIRM_ATTENDANCE,
 * COMPLETE_PAYMENT, LINK_529, FINAL_SCREEN, DONE
 *
 * DONE: is used to signify the "end" of onboarding
 * to then trigger the next action
 */
export enum OnboardingSteps {
  WELCOME = "welcome",
  STUDENT_DETAILS = "student-details",
  CONFIRM_ATTENDANCE = "confirm-attendance",
  COMPLETE_PAYMENT = "complete-payment",
  LINK_529 = "link-529",
  FINAL_SCREEN = "final-screen",
  DONE = "done"
}

/**
 * Enum for steps in tuition (institution payment) payment flows
 *
 * NEW, MFA, REVIEW, COMPLETE, SKIPPED
 *
 * SKIPPED: used in onboarding
 */
export enum TuitionPaymentSteps {
  NEW = "new",
  MFA = "mfa",
  REVIEW = "review",
  CREATED = "created",
  INITIATED = "initiated",
  SKIPPED = "skipped",
}

/**
 * Enum for steps in manual linking flows
 *
 * MANUAL, VERIFICATION, WITHDRAWAL, DONE, SKIPPED
 *
 * Done: used to signify the end of the manual linking flow
 *
 * SKIPPED: used in onboarding
 */
export enum ManualLink529Steps {
  MANUAL = "manual",
  VERIFICATION = "verification",
  WITHDRAWAL = "withdrawal",
  DONE = "done",
  SKIPPED = "skipped",
}

/**
 * Enum for steps for the add funds flows
 *
 * ACCOUNT_DETAILS, AMOUNT, SUBMIT, SUCCESS
 *
 */
export enum AddFundsSteps {
  ACCOUNT_DETAILS = "account-details",
  AMOUNT = "amount",
  SUBMIT = "submit",
  SUCCESS = "success"
}