import React from "react";

import Badge, { type BadgeProps } from "@ui/Badge/Badge";

import { useTransactionsStore } from "@stores/transactionsStore";

import { TransactionStatus } from "@lib/enums/transaction";
import type { Transaction } from "@lib/types/transaction";

import { classnames } from "@lib/utils/classnames";

import {
  TRANSACTION_TYPE,
  TUITION_PAYMENT_BADGE_COLOR,
  TUITION_PAYMENT_BADGE_DESCRIPTION,
  TUITION_PAYMENT_BADGE_STATUS,
  TUITION_PAYMENT_BADGE_TYPE
} from "@components/Transactions/constants";

type TransactionsTableRowProps = {
  transaction: Transaction;
}

const TransactionsTableRow = React.memo(function TransactionsTableRow({ transaction }: TransactionsTableRowProps) {
  const activeTransaction = useTransactionsStore((state) => state.modal?.transaction);
  const isActive = activeTransaction?.id === transaction.id;

  const setTransactionDetails = useTransactionsStore((state) => state.setModal);

  const transactionType = "institution" in transaction
    ? TRANSACTION_TYPE.INSTITUTION_PAYMENT
    : TRANSACTION_TYPE.TRANSACTION;

  const isInstitutionPayment = "institution" in transaction || ("metadata" in transaction && transaction.metadata?.institution_payment);

  const {
    date,
    type,
    transaction_name,
    beneficiary_fullname
  } = transaction;

  let badge_status: (TUITION_PAYMENT_BADGE_STATUS | null) = null;

  if (isInstitutionPayment && transaction.status !== TransactionStatus.DONE) {
    badge_status = transactionType === TRANSACTION_TYPE.INSTITUTION_PAYMENT
      // @ts-expect-error
      ? TUITION_PAYMENT_BADGE_TYPE.INSTITUTION_PAYMENT[transaction.status]
      // @ts-expect-error
      : TUITION_PAYMENT_BADGE_TYPE.TRANSACTION[transaction.status];
  }

  const BadgeComponent = badge_status ?
    <Badge
      color={TUITION_PAYMENT_BADGE_COLOR[badge_status] as BadgeProps["color"]}
      withDot={true}
      aria-description={TUITION_PAYMENT_BADGE_DESCRIPTION[badge_status]}
      data-testid="institution-payment-badge"
    >
      {badge_status}
    </Badge> : null;

  const handleOnRowClick = (event: React.MouseEvent) => {
    event.preventDefault();

    setTransactionDetails({
      transaction,
      badge: transactionType === TRANSACTION_TYPE.INSTITUTION_PAYMENT ? BadgeComponent : null
    });
  };

  return (
    <tr data-testid={`transaction-table-row-${transaction.id}`} className={classnames("hover:bg-gray-50 has-[[data-row-link]:focus]:outline-none has-[[data-row-link]:focus]:ring-2 has-[[data-row-link]:focus]:ring-inset has-[[data-row-link]:focus]:ring-blue-500", isActive && "bg-sky-50 ring-2 ring-inset ring-blue-500 hover:bg-sky-50")}>
      <td className="lg:whitespace-nowrap w-full max-w-0 p-4 text-sm font-medium text-primary sm:w-auto sm:max-w-none sm:pl-6 relative">
        <a data-row-link={true} tabIndex={0} className="absolute inset-0 focus:outline-none" href={`#${transaction.id}`} onClick={handleOnRowClick}></a>

        <div className="flex flex-col gap-1">
          <span>
            <span className="sr-only lg:hidden">Date: </span>{date}
          </span>

          <span className="text-gray-700 font-normal lg:hidden" aria-label="To/From">
            {transaction_name}
          </span>

          <span className="text-gray-500 font-normal sm:hidden" aria-label="Beneficiary">
            {beneficiary_fullname}
          </span>

          {!!badge_status &&
            <span className="lg:hidden">
              {BadgeComponent}
            </span>
          }
        </div>
      </td>

      <td className="hidden lg:table-cell relative">
        <a data-row-link={true} tabIndex={-1} className="absolute inset-0 focus:outline-none" href={`#${transaction.id}`} onClick={handleOnRowClick}></a>

        <div className="flex gap-2 xl:gap-4 2xl:gap-6 items-center justify-between lg:justify-start" data-testid="transaction-name">
          {transaction_name}
          {!!badge_status && BadgeComponent}
        </div>
      </td>

      <td className={classnames("max-sm:text-right whitespace-nowrap relative", {
        "text-success": type === "DEPOSIT"
      })}>
        <a data-row-link={true} tabIndex={-1} className="absolute inset-0 focus:outline-none" href={`#${transaction.id}`} onClick={handleOnRowClick}></a>
        {transaction.amount}
      </td>

      <td className="hidden sm:table-cell relative">
        <a data-row-link={true} tabIndex={-1} className="absolute inset-0 focus:outline-none" href={`#${transaction.id}`} onClick={handleOnRowClick}></a>
        {beneficiary_fullname}
      </td>
    </tr>
  );
});

export default TransactionsTableRow;