import { createBrowserRouter, RouterProvider } from "react-router-dom";

import GlobalErrorBoundary from "@shared/Error/GlobalErrorBoundary";
import PageNotFound from "@components/PageNotFound";

import Login from "@components/Login";

import ProtectedRoutes from "@routes/ProtectedRoutes";
import DashboardPage from "@pages/DashboardPage";
import Settings from "@pages/Settings";
import TransactionsPage from "@pages/TransactionsPage";
import PersonalDebitPage from "@pages/PersonalDebitPage";

const router = createBrowserRouter([
  {
    element: <GlobalErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/login/*",
        element: <Login />,
      },
      {
        path: "/signup/*",
        element: <Login defaultView={"signup"} />,
      },
      {
        path: "/",
        element: <ProtectedRoutes />,
        children: [
          {
            path: "dashboard/*",
            element: <DashboardPage />,
          },
          {
            path: "dashboard/:flow/:provider?/:step?",
            element: <DashboardPage />,
          },
          {
            path: "onboarding/*",
            element: <DashboardPage />,
          },
          {
            path: "onboarding/:step/:nestedStep?",
            element: <DashboardPage />,
          },
          {
            path: "settings/*",
            element: <Settings />,
          },
          {
            path: "transactions/*",
            element: <TransactionsPage />
          },
          {
            path: "direct-debit/personal/*",
            element: <PersonalDebitPage />
          },
          {
            path: "direct-debit/personal/:step?",
            element: <PersonalDebitPage />,
          },
        ]
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;