export const COLOR = {
  GRAY: "gray",
  RED: "red",
  YELLOW: "yellow",
  GREEN: "green",
  BLUE: "blue",
  INDIGO: "indigo",
  PURPLE: "purple",
  PINK: "pink",
  SKY: "sky"
} as const;

export const SIZE = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
} as const;

export const TREATMENT = {
  PILL: "pill",
  ROUNDED: "rounded",
  SQUARE: "square",
} as const;

export const VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  TEXT: "text"
} as const;

export const FEEDBACK_TYPE = {
  INFO: "INFO",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
};

export default {
  COLOR,
  FEEDBACK_TYPE,
  SIZE,
  TREATMENT,
  VARIANT
};