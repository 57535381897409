import { useMemo } from "react";
import { When } from "react-if";

import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from "@headlessui/react";
import XMarkIcon from "@icons/XMarkIcon";

import { classnames } from "@lib/utils/classnames";

import { SIZE } from "@lib/constants/theme";
import type { Size } from "@lib/types/theme";

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  className?: string;
  containerClassName?: string;
  maxWidth?: Size;
  showCloseButton?: boolean;
  disableBackdrop?: boolean;
  scrollBody?: boolean;
  scrollScreen?: boolean;
  handleClose?: () => void;
}

const MODAL_MAX_WIDTH = {
  [SIZE.XS]: "sm:max-w-md",
  [SIZE.SM]: "sm:max-w-xl",
  [SIZE.MD]: "sm:max-w-3xl",
  [SIZE.LG]: "sm:max-w-3xl",
  [SIZE.XL]: "sm:max-w-5xl"
};

const Modal = ({
  children,
  className,
  containerClassName,
  open,
  maxWidth = "default",
  showCloseButton = false,
  disableBackdrop = false,
  scrollBody = true,
  scrollScreen = false,
  handleClose
}: ModalProps) => {
  const container = useMemo(() => {
    return classnames(
      "fixed inset-0 z-10 w-screen",
      {
        "overflow-y-auto": scrollBody && !scrollScreen,
      }
    );
  }, [scrollBody]);

  const panelContainerClassName = useMemo(() => {
    return classnames(
      "flex items-center justify-center max-sm:size-full",
      {
        "min-h-full": scrollBody && !scrollScreen,
        "h-full": scrollScreen
      },
      containerClassName,
    );
  }, [containerClassName]);

  const maxWidthClassName = useMemo(() => {
    return maxWidth && maxWidth !== "default"
      ? MODAL_MAX_WIDTH[maxWidth]
      : MODAL_MAX_WIDTH[SIZE.MD];
  }, [maxWidth]);

  const panelClassName = useMemo(() => {
    return classnames(
    "relative overflow-hidden bg-white rounded-none sm:rounded-xl sm:max-h-[calc(100%-20px)] max-sm:size-full max-w-full flex flex-col",
    maxWidthClassName,
    className
  );
  }, [maxWidthClassName, className]);

  const handleOnClose = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();

    if (disableBackdrop) return;

    if (handleClose) return handleClose();
  };

  return (
    <Dialog
      className="dialog"
      data-testid="modal-component"
      open={open}
      onClose={() => handleOnClose()}
    >
      <DialogBackdrop
        transition
        className="backdrop transitions-backdrop"
        data-testid="modal-component-backdrop"
      />

      <div className={container}>
        <div className={panelContainerClassName}>
          <DialogPanel
            className={panelClassName}
            data-testid="modal-component-panel"
          >
            <TransitionChild>
              <When condition={showCloseButton}>
                <div className={classnames(showCloseButton && "flex justify-end p-2")}>
                  <button
                    type="button"
                    className="relative icon-button p-2"
                    data-testid="modal-close-btn"
                    onClick={(event) => handleOnClose(event)}
                  >
                    <XMarkIcon />
                  </button>
                </div>
              </When>

              {children}
            </TransitionChild>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;