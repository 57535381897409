import type { State529Connection } from "@lib/types/state529";
import instance from "config/fetch.config";
import type { UUID } from "@lib/types/global";
import { errorMapper } from "@utils/deprecated/errors";

class Link529Services {
  static async getPolicies(): Promise<{ id: string; url: string }> {
    try {
      const {
        data: { data },
      } = await instance.get("/state-529-connect/policies");

      return data;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async acceptPolicy(policyId: UUID): Promise<any> {
    try {
      return instance.post(`/state-529-connect/policies/${policyId}/accept`);
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async authenticate(username: string, password: string): Promise<any> {
    try {
      return instance.post("/state-529-connect/link", { username, password });
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async verifyMFA(mfa_code: string): Promise<{ bank_account_exists: boolean }> {
    try {
      const { data: data } = await instance.post("/state-529-connect/verify", { pin: mfa_code });

      return data;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async link529(): Promise<any> {
    try {
      return instance.post("/state-529-connect/accounts");
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async get529ConnectionStatus(planId: UUID): Promise<State529Connection> {
    try {
      const {
        data: { data },
      } = await instance.get(`/state-529-connect/${planId}/status`);

      return data;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}

export default Link529Services;
