import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Persona, { Client, ClientOptions } from "persona";
import { Case, Default, Else, If, Switch, Then } from "react-if";

import Card from "@ui/Card/Card";
import Stepper from "@ui/Stepper/Stepper";

import EmailVerification from "./Screens/EmailVerification";
import IdentityVerification from "./Screens/IdentityVerification";
import FinalConfirmation from "./Screens/FinalConfirmation";
import DefaultErrorScreen from "@shared/Error/DefaultErrorScreen";
import Spinner from "@ui/Spinner/Spinner";

import { PersonaService } from "@lib/services/persona.services";
import AccountServices from "@lib/services/account.services";
import { getApplicationNextStep } from "@lib/queries/application";

import { backoff } from "@utils/requests";

import type { UUID } from "@lib/types/global";
import type { PersonaInquiry } from "@lib/types/persona";

type RegistrationProps = {
  applicationId: UUID
}

const REGISTRATION_SCREEN = {
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_IDENTITY: "VERIFY_IDENTITY",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  DASHBOARD: "DASHBOARD",
  ERROR: "ERROR",
};

const REGISTRATION_STEP = {
  [REGISTRATION_SCREEN.VERIFY_EMAIL]: {
    title: "Email Verification",
    step: 1
  },
  [REGISTRATION_SCREEN.VERIFY_IDENTITY]: {
    title: "Identity Verification",
    step: 2
  },
  [REGISTRATION_SCREEN.CREATE_ACCOUNT]: {
    title: "Create account",
    step: 3
  },
};

const Registration = ({ applicationId }: RegistrationProps) => {
  const [client, setClient] = useState<Client>();
  const [activeStep, setActiveStep] = React.useState<string | null>(null);
  const [error, setError] = React.useState("");

  const stepTitles = Object.values(REGISTRATION_STEP).map((step) => step.title);

  const {
    data,
    isSuccess,
    isRefetching,
    isError: isNextStepError,
    error: nextStepError,
    refetch
  } = useQuery({
    queryKey: ["applicationNextStep", applicationId],
    queryFn: () => getApplicationNextStep(applicationId),
    staleTime: Infinity, // do not automatically keep refetching this call,
  });

  useEffect(() => {
    if (isSuccess) {
      setActiveStep(data.next_step);
    }
  }, [isSuccess, isRefetching]);

  useEffect(() => {
    if (isNextStepError) {
      setActiveStep(REGISTRATION_SCREEN.ERROR);
      setError(nextStepError.message);
    }
  }, [isNextStepError, nextStepError]);

  const handleNext = () => {
    refetch();
  };

  const navigate = useNavigate();

  // final step of registration
  const handleCompletion = async () => {
    const owner = await AccountServices.createOwnerAccount();
    if (owner) {
      navigate("/onboarding/welcome");
    }
  };

  const handlePersona = async (): Promise<{
    inquiry_id: string;
    session_token: string;
    id: UUID;
  }> => {
    try {
      const results = await PersonaService.createInquiry(applicationId);
      const session_token = results.session_token ? results.session_token : "";

      return {
        id: results.id,
        inquiry_id: results.inquiry_id,
        session_token,
      };
    } catch (err: any) {
      // [Santucho: 01/25/2024]: This catch is in case we want to give a retry sequence in the future
      throw new Error(err);
    }
  };

  const setPersonaError = (msg: string) => {
    setActiveStep(REGISTRATION_SCREEN.ERROR);
    setError(msg);
  };

  const onPersonaComplete =
    (id: UUID, triggerLoading: () => void): ClientOptions["onComplete"] =>
    async ({ status }) => {
      if (status === "approved" || status === "completed") {
        try {
          triggerLoading();
          const successCondition = (result: PersonaInquiry[]) => {
            const activeInquiry = result.find((inquiry: PersonaInquiry) => inquiry.id === id);

            return {
              conditionMet:
                activeInquiry?.status === "APPROVED" || activeInquiry?.status === "COMPLETED",
              errorMessage: "Persona inquiry not approved",
            };
          };

          await backoff(() => PersonaService.getInquiries(applicationId), {
            conditionOfSuccess: successCondition,
          });

          handleNext();
        } catch (error) {
          console.error(error);
          Sentry.captureEvent(error as Error);

          setPersonaError(`There was an error with Persona: ${error}`);
        } finally {
          triggerLoading();
        }
      }
      if (status === "failure") {
        const error = new Error("There was an error with Persona: failure");
        console.error(error);
        Sentry.captureEvent(error);
        setPersonaError(error.message);
      }
      client?.destroy();
    };

  const onPersonaError: ClientOptions["onError"] = (error) => {
    setActiveStep(REGISTRATION_SCREEN.ERROR);
    console.error("Persona error", error);
    Sentry.captureEvent(error);
    setError(`There was an error with Persona: ${error.code}`);
  };

  const handleOpenPersona = async (triggerLoading: () => void) => {
    try {
      setError("");
      const { inquiry_id, session_token, id } = await handlePersona();
      const newPersonaClient = new Persona.Client({
        inquiryId: inquiry_id,
        sessionToken: session_token,
        onComplete: onPersonaComplete(id, triggerLoading),
        onError: onPersonaError,
      });
      setClient(newPersonaClient);
      newPersonaClient.open();
    } catch (error) {
      setError(String(error));
    }
  };

  return (
    <Card containerClassName="max-sm:shadow-none w-[440px]" className="px-10 pt-4 pb-8">
      <div className="flex flex-col justify-center items-center text-center">
        <If condition={activeStep === REGISTRATION_SCREEN.ERROR}>
          <Then>
            <DefaultErrorScreen />
          </Then>

          <Else>
            {!!activeStep &&
              <div className="pt-6 pb-8">
                <h3 className="text-lg mb-4 flex justify-center items-center gap-x-1 text-blue">{REGISTRATION_STEP[activeStep].title}</h3>

                <Stepper steps={stepTitles} current={REGISTRATION_STEP[activeStep].step} />
              </div>
            }

            <Switch>
              <Case condition={activeStep === REGISTRATION_SCREEN.VERIFY_EMAIL}>
                <EmailVerification
                  handleNext={handleNext}
                  applicationId={applicationId}
                />
              </Case>

              <Case condition={activeStep === REGISTRATION_SCREEN.VERIFY_IDENTITY}>
                <IdentityVerification
                  applicationId={applicationId}
                  className={!client ? "sentry-block" : ""}
                  handleOpenPersona={handleOpenPersona}
                  error={error}
                />
              </Case>

              <Case condition={activeStep === REGISTRATION_SCREEN.CREATE_ACCOUNT}>
                <FinalConfirmation handleNext={handleCompletion} />
              </Case>

              <Default>
                <div className="py-8">
                  <Spinner size="lg" />
                </div>
              </Default>
            </Switch>
          </Else>
        </If>

        <img
          alt="Backpack Logo"
          className="mt-7 h-6 w-auto shrink-0"
          src="/backpack.svg"
        />
      </div>
    </Card>
  );
};

export default Registration;
