import { memo } from "react";

import { BADGE_COLOR, DOT_COLOR } from "@ui/Badge/constants";
import type { Color, Treatment, Variant } from "@lib/types/theme";
import { classnames } from "@lib/utils/classnames";

export type BadgeProps = Variant
  & Pick<Treatment, "pill" | "rounded">
  & {
  "aria-description"?: string;
  children: React.ReactNode;
  className?: string;
  color?: Color;
  small?: boolean;
  large?: boolean;
  withDot?: boolean;
};

/**
 * @prop {children} required
 * @prop {className} optional
 * @prop {color} optional - default is gray
 * @prop {small} optional - default is true
 * @prop {large} optional - default is false
 * @prop {pill} optional - badge style. default is true
 * @prop {rounded} optional - badge style. default is false
 * @prop {primary} optional - badge variant. default is true
 * @prop {secondary} optional - badge variant. default is false
 * @prop {tertiary} optional - badge variant. default is false.
 * @prop {withDot} optional - default is false
 */
const Badge = memo(function Badge({
  children,
  className,
  "aria-description": ariaDescription,
  color = "gray",
  small = true,
  large = false,
  pill = true,
  rounded = false,
  primary = true,
  secondary = false,
  tertiary = false,
  withDot = false
}: BadgeProps) {
  const variant = (tertiary && "tertiary")
    || (secondary && "secondary")
    || (primary && "primary")
    || "primary";

  return (
    <span aria-description={ariaDescription} className={classnames(
      "w-fit inline-flex items-center gap-x-1.5 text-xs font-medium",
      {
        "rounded-full": pill,
        "rounded-md": rounded,
        "py-0.5": small,
        "px-2": small && withDot,
        "px-1.5": small && !withDot,
        "py-1 px-2.5": large,
      },
      BADGE_COLOR[variant][color],
      className
    )}>
      {withDot &&
        <svg
          data-testid="badge-dot"
          viewBox="0 0 6 6"
          aria-hidden="true"
          className={(classnames("size-1.5", DOT_COLOR[color]))}
        >
          <circle r={3} cx={3} cy={3} />
        </svg>
      }

      {children}
    </span>
  );
});

export default Badge;