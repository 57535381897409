import { memo, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { When } from "react-if";
import * as Sentry from "@sentry/react";
import { useShallow } from "zustand/react/shallow";
import { useQuery } from "@tanstack/react-query";

import ModalDialog from "@ui/Modal/ModalDialog";
import Button from "@ui/Button/Button";
import Spinner from "@ui/Spinner/Spinner";
import MainHeader from "@components/MainHeader/MainHeader";
import SidebarNav from "@ui/SidebarNav/SidebarNav";
import NotificationContainer from "@ui/Notification/Notification";
import Onboarding from "@components/Onboarding/Onboarding";

import { useAuth } from "@hooks/useAuth";
import { useIdleDetector } from "@hooks/useIdleDetector";
import { useBuildUserProfiles } from "@hooks/useBuildUserProfiles";
import { useFormatBeneficiaries } from "@hooks/useFormatBeneficiaries";
import { useFetchCurrentUser } from "@hooks/useFetchCurrentUser";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY, STALE_TIME_5_MINS } from "@lib/queries/constants";
import { getBeneficiaries } from "@lib/queries/beneficiaryQueries";

import { useUserProfileStore } from "@stores/userProfileStore";
import { useCurrentUserStore } from "@stores/currentUserStore";

import { BASE_ROUTE } from "@lib/constants/global";

const LoggedInLayout = memo(function LoggedInLayout() {
  const { logout } = useAuth();
  const { currentState, keepAlive } = useIdleDetector(logout);

  const navigate = useNavigate();
  const location = useLocation();
  const isOnOnboarding = location.pathname.startsWith("/onboarding");

  const hasCurrentUser = useCurrentUserStore(useShallow((state) => !!state.currentUser.id));
  const hasUserProfiles = useUserProfileStore(useShallow((state) => state.initialized));

  const hasBeneficiaries = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries[0].id.length));

  const setCurrentUser = useCurrentUserStore((state) => state.setCurrentUser);
  const setInitialProfiles = useUserProfileStore((state) => state.setInitialProfiles);
  const setBeneficiaries = useCurrentUserStore((state) => state.setBeneficiaries);

  // fetch the current user
  const {
    currentUser,
    isLoading: isCurrentUserLoading,
  } = useFetchCurrentUser();

  // build the user profiles
  const userProfiles = useBuildUserProfiles(!hasUserProfiles);

  const {
    data: beneficiaries,
    isFetching: isBeneficiariesFetching
  } = useQuery({
    queryKey: [QUERY_KEY.BENEFICIARIES],
    queryFn: getBeneficiaries,
    enabled: !hasBeneficiaries,
    staleTime: STALE_TIME_5_MINS,
    ...DEFAULT_QUERY_OPTIONS
  });

  const {
    formattedBeneficiaries,
    isFetching: isFormattedBeneficiariesFetching,
  } = useFormatBeneficiaries({
    beneficiaries,
    enable: !hasBeneficiaries
      && !!beneficiaries
      && !!beneficiaries.length
  });

  useEffect(() => {
    if (hasCurrentUser
      && hasBeneficiaries
      && hasUserProfiles
    ) return;

    // set the current user
    if (!hasCurrentUser && !!currentUser.id) {
      Sentry.getGlobalScope().setUser({
        id: currentUser.id
      });

      setCurrentUser(currentUser);
    }

    //if there are no profiles, set the initial profile
    if (!hasUserProfiles && userProfiles) {
      setInitialProfiles({ ...userProfiles });

      // if onboarding profile is incomplete,
      // navigate user to onboarding
      if (!userProfiles.profilesCompleted.onboarding) {
        // if there are other params in the url
        // such as /onboarding/student_details
        // do not overwrite the route
        const onboardingRoute = isOnOnboarding && location.pathname !== BASE_ROUTE.onboarding
          ? location.pathname
          : BASE_ROUTE.onboarding;

        navigate(onboardingRoute, { replace: true });
        return;
      }
    }
  }, [
    hasCurrentUser,
    currentUser.id,
    hasUserProfiles,
    userProfiles
  ]);

  // if a user has beneficiaries
  // set the initial beneficiaries
  useEffect(() => {
    if (!hasCurrentUser || !hasUserProfiles) return;
    if (hasCurrentUser && hasBeneficiaries) return;

    if (formattedBeneficiaries && !hasBeneficiaries) {
      setBeneficiaries(formattedBeneficiaries);
    }
  }, [formattedBeneficiaries]);

  if (!hasUserProfiles
    || !hasCurrentUser
    || isBeneficiariesFetching
    || isCurrentUserLoading
    || isFormattedBeneficiariesFetching
  ) return <Spinner fullScreen />;

  return (
    <>
      <MainHeader />

      <div className="flex flex-1 w-full items-start overflow-hidden md:overflow-auto bg-white">
        <SidebarNav />

        <Outlet />
      </div>

      <NotificationContainer />

      <ModalDialog
        className="z-[1502]"
        containerClassName="p-9"
        open={currentState === "idle"}
        onClose={keepAlive}
      >
        <div className="flex flex-col items-center gap-6 text-center">
          <h2 data-cy="modal-title" className="font-bold text-xl font-montserrat">Are you still there?</h2>

          <div className="text-sm">
            <p>You have been inactive for a while.</p>
            <p>For your security, you will be logged out soon.</p>
          </div>

          <div className="w-8/10">
            <Button
              primary
              rounded
              fullWidth
              data-cy="idle-confirmation"
              data-testid="idle-confirmation-btn"
              onClick={keepAlive}
            >
              Continue
            </Button>
          </div>
        </div>
      </ModalDialog>

      {/* Onboarding Modal */}
      <When condition={isOnOnboarding}>
        <Onboarding />
      </When>
    </>
  );
});

export default LoggedInLayout;