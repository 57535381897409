import { ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

export const AGREEMENT_TYPE = {
  ACH: "ACH",
  TERMS_OF_SERVICE: "TERMS_OF_SERVICE"
} as const;

export const ORIGIN = { ...ANALYTICS_EVENT_ORIGIN };

export const ROUTE_TYPE = {
  ROOT: "root",
  LOGIN: "login",
  SIGNUP: "signup",
  DASHBOARD: "dashboard",
  TRANSACTIONS: "transactions",
  SETTINGS: "settings",
  ONBOARDING: "onboarding",
  PERSONAL_DEBIT: "personal-debit"
} as const;

export const BASE_ROUTE = {
  [ROUTE_TYPE.ROOT]: "/",
  [ROUTE_TYPE.LOGIN]: "/login",
  [ROUTE_TYPE.SIGNUP]: "/signup",
  [ROUTE_TYPE.DASHBOARD]: "/dashboard",
  [ROUTE_TYPE.TRANSACTIONS]: "/transactions",
  [ROUTE_TYPE.SETTINGS]: "/settings",
  [ROUTE_TYPE.ONBOARDING]: "/onboarding",
  [ROUTE_TYPE.PERSONAL_DEBIT]: "/direct-debit/personal"
};