import React from "react";

import Icon, { type OutlineIconProps } from "@icons/Icon";

const CheckIcon = React.memo(function CheckIcon({
  outline,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={true} { ...iconProps }>
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </Icon>
  );
});

export default CheckIcon;