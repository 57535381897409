import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

import WarningIcon from "@icons/WarningIcon";
import Modal from "@ui/Modal/Modal";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getState529WithdrawalRequests } from "@lib/queries/state529Queries";

import { State529WithdrawalStatus } from "@lib/enums/state529";

type InitiatedWithdrawalsModalProps = {
  open: boolean;
  openWithdrawalsModal: () => void;
  closeModal: () => void;
}
const logEvent = (message: string) => {
  Sentry.captureMessage(message, "debug");
};

const InitiatedWithdrawalsModal = ({
  open = false,
  openWithdrawalsModal,
  closeModal
}: InitiatedWithdrawalsModalProps) => {
  const { data: initiated529Withdrawals } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_WITHDRAWALS, State529WithdrawalStatus.CREATED],
    queryFn: ({ queryKey }) => getState529WithdrawalRequests((queryKey[1] as State529WithdrawalStatus)),
    throwOnError: true,
    ...DEFAULT_QUERY_OPTIONS
  });

  const amountText = useMemo(() => {
    if (!initiated529Withdrawals || initiated529Withdrawals.length === 0) {
      return "A withdrawal has already been submitted.";
    }

    return `A withdrawal of ${initiated529Withdrawals[0].amount} has already been submitted.`;
  }, [initiated529Withdrawals]);

  const onProceedAnyways = () => {
    logEvent("InitiatedWithdrawalsModal clicked proceed anyways");
    openWithdrawalsModal();
  };

  const onCancel = () => {
    logEvent("InitiatedWithdrawalsModal clicked cancel");
    closeModal();
  };

  useEffect(() => {
    if (open) {
      logEvent("InitiatedWithdrawalsModal shown");
      return;
    }
  }, [open]);

  return (
    <Modal
      open={open}
      maxWidth="sm"
      className="sm:rounded-lg"
      data-testid="initiated-withdrawals-modal"
    >
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-warning-icon sm:mx-0 sm:size-10">
            <WarningIcon
              aria-hidden="true"
              className="size-6 text-warning"
              strokeWidth={2}
              outline
            />
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-semibold text-primary">
              Withdrawal already in progress
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-600">
                <span data-testid="amount-text">{amountText}</span> Withdrawals can take 1 - 4 business days to deposit into your Backpack account. To avoid duplicate withdrawals, check your Pending Transactions on the Dashboard.
                <br /><br />
                Do you want to set up another withdrawal?
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col sm:flex-row sm:justify-end gap-2 sm:gap-3">
        <button
          type="button"
          data-testid="continue-button"
          className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2.5 text-sm font-medium text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
          onClick={onProceedAnyways}
        >
          Proceed Anyways
        </button>

        <button
          type="button"
          data-testid="cancel-button"
          className="inline-flex w-full justify-center rounded-md bg-yellow-400 text-yellow-900 px-4 py-2.5 text-sm font-medium shadow-sm hover:bg-yellow-400 sm:w-auto"
          data-autofocus
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default InitiatedWithdrawalsModal;