import Button from "@ui/Button/Button";

import { isIntercomEnabled } from "@helpers/vendorCheck";
import { DEFAULT_CONTACT_SUPPORT_LINK } from "@lib/constants/error";

const ContactSupportButton = () => {
  const handleContactSupport = () => {
    if (isIntercomEnabled()) {
      // @ts-expect-error
      window.Intercom("show");
    } else {
      window.open(DEFAULT_CONTACT_SUPPORT_LINK, "_blank");
    }
  };

  return (
    <Button
      rounded
      data-testid="contact-support-btn"
      onClick={handleContactSupport}
    >
      Contact Support
    </Button>
  );
};

export default ContactSupportButton;