import { useShallow } from "zustand/react/shallow";
import { Else, If, Then } from "react-if";

import linkAndPay from "@assets/images/linkAndPay.svg";

import Button from "@ui/Button/Button";

import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";
import { Flow, TuitionPaymentSteps } from "@lib/enums/flows";
import { ProfileType } from "@lib/enums/userProfile";
import { State529ProviderType } from "@lib/enums/state529";

import { useAnalytics } from "@hooks/useAnalytics";

import { useOnboardingStore } from "@stores/onboardingStore";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useCurrentUserStore } from "@stores/currentUserStore";

const Link529Screen = () => {
  const { trackEvent } = useAnalytics();
  const beneficiaries = useCurrentUserStore((state) => state.currentUser.beneficiaries);

  const tuitionPaymentStatus = useOnboardingStore(useShallow((state) => state.status[Flow.TUITION_PAYMENT]));
  const planProviderType = useUserProfileStore(useShallow((state) => state.profiles.onboarding.planProviderType));

  const updateProfile = useUserProfileStore((state) => state.updateProfile);
  const updateBeneficiary529Connection = useCurrentUserStore((state) => state.updateBeneficiary529Connection);

  const goToLink529Manual = useOnboardingStore((state) => state.goToLink529Manual);
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);

  const handleNextStep = async () => {
    const onboardingProfileUpdate = tuitionPaymentStatus === TuitionPaymentSteps.SKIPPED
      ? { hasSkippedTuitionPayment: true }
      : { hasInstitutionPayments: true };

    // update the status only if the planProviderType is automated
    await updateProfile({
      type: ProfileType.ONBOARDING,
      update: onboardingProfileUpdate,
      updateStatus: planProviderType === State529ProviderType.AUTOMATED
    });

    trackEvent("STARTED_TO_LINK", {
      origin: ANALYTICS_EVENT_ORIGIN.ONBOARDING,
      action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
      action_description: "Clicked the Continue button",
      component: "Link529Screen",
    });

    if (planProviderType === State529ProviderType.AUTOMATED) {
      updateBeneficiary529Connection({
        beneficiary_id: beneficiaries[0].id,
        state_529_connection: {
          ...beneficiaries[0].state_529_plan.state_529_connection,
          is_automated_withdrawal_eligible: true,
        },
      });

      return goToNextStep();
    }

    goToLink529Manual();
  };

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title">
          <If condition={tuitionPaymentStatus === TuitionPaymentSteps.SKIPPED}>
            <Then>Link your 529 to Backpack</Then>

            <Else>Link your 529 & complete payment</Else>
          </If>
        </h2>

        <p className="modal-header-description">
          <If condition={tuitionPaymentStatus === TuitionPaymentSteps.SKIPPED}>
            <Then>Next, we&#39;ll walk you through how to link your 529 to Backpack. This will enable seamless tuition payments and ability to use your 529 for other eligible expenses.</Then>

            <Else>Next, we&#39;ll walk you through how to link your 529 to Backpack and how to withdraw your 529 funds.</Else>
          </If>
        </p>

        <img
          src={linkAndPay}
          alt="person infront of phone with receipt"
          loading="lazy"
          height={300}
          width={300}
          className="h-auto w-full max-w-60 sm:max-w-[300px] mt-5 sm:mt-10"
        />
      </div>

      <div className="w-full shrink-0">
        <Button
          rounded
          fullWidth
          onClick={handleNextStep}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Link529Screen;