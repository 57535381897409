import { create } from "@stores/utils";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  ADD_FUNDS_STEP_DETAILS,
  DIRECT_DEBIT_STEPS_LIST,
  DIRECT_DEBIT_STEPS_LIST_LINKED529
} from "@lib/constants/addFunds";

import { AddFundsSteps, Flow } from "@lib/enums/flows";
import { State529ConnectionStatus, State529ProviderType } from "@lib/enums/state529";

import type { Beneficiary, BeneficiaryState529Plan } from "@lib/types/beneficiary";
import type { State529Connection, State529WithdrawalRequestResponse } from "@lib/types/state529";
import type { AccountDetails, AddFundsStep } from "@lib/types/addFunds";
import type { SubmitDebitWithdrawalRequestResponse } from "@lib/types/directDebit";

type DirectDebitPayload = {
  amount: string;
};

export type PersonalDebitPayload = Pick<AccountDetails, "counterparty_id" | "account_type" | "account_holder_name"> & DirectDebitPayload;

type DirectDebitConfirmation = State529WithdrawalRequestResponse | SubmitDebitWithdrawalRequestResponse;

type AddFundsState = {
  flow: Flow.DIRECT_DEBIT | Flow.PERSONAL_DEBIT | null;
  origin: "dashboard" | null;
  step: {
    current: AddFundsSteps | null;
    stepNumber: number;
    total: number;
    details: Record<AddFundsSteps, AddFundsStep> | null;
    list: AddFundsSteps[];
  },
  hasLinked529: boolean | null;
  beneficiary: Beneficiary | null;
  state529Plan: Beneficiary["state_529_plan"] | null;
  planProviderType: State529ProviderType | null;
  accountNumberLast4: string | null;
  payload: DirectDebitPayload | PersonalDebitPayload;
  confirmation: DirectDebitConfirmation | null;
};

type AddFundsAction = {
  initializeDirectDebitFlow: (beneficiary: Beneficiary) => void;
  initializePersonalDebitFlow: (beneficiary: Beneficiary) => void;
  goToNextStep: () => void;
  updateLink529Success: (state529Connection: Partial<State529Connection>, accountNumber: AddFundsState["accountNumberLast4"]) => void;
  updatePersonalDebitPayload: (accountDetails: Partial<AccountDetails>) => void;
  setAmount: (amount: string) => void;
  setConfirmationDetails: (details: DirectDebitConfirmation) => void;
  reset: () => void;
};

const DEFAULT_INITIAL_STATE = {
  flow: null,
  origin: null,
  step: {
    current: null,
    stepNumber: 0,
    total: 0,
    details: null,
    list: [] as AddFundsSteps[],
  },
  hasLinked529: null,
  beneficiary: null,
  state529Plan: null,
  planProviderType: null,
  accountNumberLast4: null,
  payload: {
    amount: "0"
  },
  confirmation: null,
};

export const useAddFundsStore = create<AddFundsState & AddFundsAction>()(
  persist(
    (set, get) => ({
      ...DEFAULT_INITIAL_STATE,

      initializeDirectDebitFlow: (beneficiary) => {
        const { state_529_connection } = beneficiary.state_529_plan;
        const hasLinked529 = state_529_connection.status === State529ConnectionStatus.LINKED;

        // if a user has linked their 529
        // 1st screen starts with amount
        const step = hasLinked529
          ? AddFundsSteps.AMOUNT
          : AddFundsSteps.ACCOUNT_DETAILS;

        const stepsList = hasLinked529
          ? DIRECT_DEBIT_STEPS_LIST_LINKED529
          : DIRECT_DEBIT_STEPS_LIST;

        set({
          ...DEFAULT_INITIAL_STATE,
          flow: Flow.DIRECT_DEBIT,
          origin: "dashboard",
          step: {
            current: step,
            stepNumber: 1,
            total: stepsList.length,
            details: { ...ADD_FUNDS_STEP_DETAILS[State529ProviderType.DIRECT_DEBIT] },
            list: stepsList,
          },
          beneficiary: beneficiary,
          state529Plan: beneficiary.state_529_plan,
          planProviderType: State529ProviderType.DIRECT_DEBIT,
          hasLinked529,
        });
      },

      initializePersonalDebitFlow: (beneficiary) => {
        set({
          ...DEFAULT_INITIAL_STATE,
          flow: Flow.PERSONAL_DEBIT,
          step: {
            current: AddFundsSteps.ACCOUNT_DETAILS,
            stepNumber: 1,
            total: DIRECT_DEBIT_STEPS_LIST.length,
            details: { ...ADD_FUNDS_STEP_DETAILS[Flow.PERSONAL_DEBIT] },
            list: DIRECT_DEBIT_STEPS_LIST,
          },
          beneficiary: beneficiary
        });
      },

      goToNextStep: () => {
        const currentStep = get().step.current;
        const currentStepNumber = get().step.stepNumber;

        let nextStep: (AddFundsSteps | null) = AddFundsSteps.AMOUNT;
        const updatedStepNumber = currentStepNumber + 1;

        switch (currentStep) {
          case AddFundsSteps.AMOUNT:
            nextStep = AddFundsSteps.SUBMIT;
            break;

          case AddFundsSteps.SUBMIT:
            nextStep = AddFundsSteps.SUCCESS;
            break;
        }

        set((state) => ({
          ...state,
          step: {
            ...state.step,
            current: nextStep,
            stepNumber: updatedStepNumber
          }
        }));
      },

      updateLink529Success: (state529ConnectionUpdate, accountNumber) => {
        const existingState529Plan = get().state529Plan;
        const updatedState529Plan = {
          ...existingState529Plan,
          state_529_connection: {
            ...existingState529Plan?.state_529_connection,
            ...state529ConnectionUpdate
          }
        } as BeneficiaryState529Plan;

        set({
          state529Plan: { ...updatedState529Plan },
          hasLinked529: true,
          accountNumberLast4: accountNumber
        });
      },

      updatePersonalDebitPayload: (accountDetails) => {
        const {
          counterparty_id,
          account_type,
          account_holder_name,
          account_number,
        } = accountDetails;

        set((state) => ({
          ...state,
          accountNumberLast4: account_number,
          payload: {
            ...state.payload,
            counterparty_id,
            account_type,
            account_holder_name
          } as PersonalDebitPayload
        }));
      },

      setAmount: (update) => {
        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            amount: update
          }
        }));
      },

      setConfirmationDetails: (details) => {
        set({ confirmation: details });
      },

      reset: () => {
        set(DEFAULT_INITIAL_STATE);
        useAddFundsStore.persist.clearStorage();
      }
    }),
    {
      name: "add-funds",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);