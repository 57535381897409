import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { BASE_ROUTE } from "@lib/constants/global";

import { useAuthStore } from "@stores/authStore";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useOnboardingStore } from "@stores/onboardingStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";
import { resetAllStores } from "@stores/utils";
import { useAddFundsStore } from "@stores/addFundsStore";

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

export const useAuth = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const { setToken, removeToken, isAuthed } = useAuthStore();

  const logoutPath = useMemo(() => {
    const basePath = BASE_ROUTE.login;

    const hasExistingRedirect = location.search.startsWith("?return_to");

    const redirectPath = `${location.pathname}${location.search}`;
    const redirectURL = hasExistingRedirect
      ? ""
      : encodeURIComponent(new URL(redirectPath, BASE_URL).href);

    const logoutURL = hasExistingRedirect
      ? `${basePath}${location.search}`
      : `${basePath}?return_to=${redirectURL}`;

    // if there is an existing redirect url
    if (hasExistingRedirect) {
      return logoutURL;
    }

    return logoutURL;
  }, [location]);

  const login = useCallback(async (token: string) => {
    const nextURL = new URLSearchParams(location.search).get("return_to");
    const redirectURL = nextURL ? new URL(nextURL) : "";
    const loginPath  = redirectURL
    ? `${redirectURL.pathname}${redirectURL.search}`
    : BASE_ROUTE.dashboard;

    await setToken(token);
    navigate(loginPath);
  }, [navigate]);

  const logout = useCallback(async (redirect = true) => {
    const path = redirect ? logoutPath : BASE_ROUTE.root;

    queryClient.clear();

    resetAllStores();

    useUserProfileStore.persist.clearStorage();
    useOnboardingStore.persist.clearStorage();
    useTuitionPaymentsStore.persist.clearStorage();
    useAddFundsStore.persist.clearStorage();

    sessionStorage.clear();
    removeToken();

    if (!redirect) return;

    return navigate(path);
  }, [queryClient, navigate]);

  return {
    logoutPath,
    isAuthed,
    login,
    logout
  };
};