import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import Transactions from "@components/Transactions/Transactions";
import TransactionSummary from "@components/Transactions/TransactionsSummary";
import TransactionDetailDrawer from "@components/TransactionDetailDrawer/TransactionDetailDrawer";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useTransactionsStore } from "@stores/transactionsStore";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getInstitutionPayments } from "@lib/queries/institutionPaymentQueries";
import { getTransactions } from "@lib/queries/transactionQueries";

import { InstitutionPaymentStatus } from "@lib/enums/institutionPayment";
import { useLocation } from "react-router-dom";

const TransactionsPage = () => {
  const location = useLocation();

  const hasCurrentUser = useCurrentUserStore((state) => state.currentUser.id && !!state.currentUser.id.length);
  const setInitialTransactions = useTransactionsStore((state) => state.setInitialTransactions);

  const openDrawer = useTransactionsStore((state) => !!state.modal);
  const closeDrawer = useTransactionsStore((state) => state.setModal);

  const {
    data: institutionPayments,
    isSuccess: isSuccessInstitutionPayments,
    isError: isErrorInstitutionPayments,
    error: InstitutionPaymentsError
  } = useQuery({
    queryKey: [QUERY_KEY.INSTITUTION_PAYMENTS, InstitutionPaymentStatus.PENDING],
    queryFn: ({ queryKey }) => getInstitutionPayments((queryKey[1] as InstitutionPaymentStatus)),
    ...DEFAULT_QUERY_OPTIONS
  });

  const {
    data: standardTransactions,
    isSuccess: isSuccessTransactions,
    isError: isErrorTransactions,
    error: TransactionsError
  } = useQuery({
    queryKey: [QUERY_KEY.TRANSACTIONS],
    queryFn: getTransactions,
    ...DEFAULT_QUERY_OPTIONS
  });

  useEffect(() => {
    if (isSuccessInstitutionPayments
      && isSuccessTransactions
      && hasCurrentUser
    ) {
      setInitialTransactions([
        ...standardTransactions,
        ...institutionPayments
      ]);
    }
  }, [
    hasCurrentUser,
    isSuccessInstitutionPayments,
    isSuccessTransactions
  ]);

  // [SM - 09/29/2024]: update this to show the error in a toast
  if (isErrorInstitutionPayments || isErrorTransactions) {
    const error_message = InstitutionPaymentsError || TransactionsError;
    console.error(error_message);
  }

  useEffect(() => {
    closeDrawer();
  }, [location]);

  return (
    <>
      <div className="size-full overflow-y-scroll pb-20">
        <h1 className="sr-only">Transactions</h1>

        <main className="flex flex-col w-full max-w-7xl xl:mx-auto p-6 xl:p-8">
          <TransactionSummary />

          <Transactions />
        </main>
      </div>

      <TransactionDetailDrawer open={openDrawer} />
    </>
  );
};

export default TransactionsPage;