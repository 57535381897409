import { Box, Stack } from "@mui/material";

import { styled } from "@mui/material/styles";

const FooterBox = styled(Box)({
  alignItems: "center",
  justifyContent: "center",
  marginTop: "auto",
  paddingTop: "1rem",
});

const CardFooter = (): React.ReactNode => {
  return (
    <FooterBox component="footer">
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="0.5rem"
        data-cy="footer">
        <img height={25} src="/backpack.svg" alt={"Backpack Logo"} />
      </Stack>
    </FooterBox>
  );
};

export default CardFooter;
