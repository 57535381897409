import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CalendarIcon from "@icons/CalendarIcon";

import type { DateRange } from "@lib/types/filter";

import { classnames } from "@lib/utils/classnames";

export type DateRangePickerProps = {
  resetInputs?: boolean;
  onDateRangeChange: ({ startDate, endDate }: DateRange) => void;
}

const DATE_RANGE = {
  START: "START",
  END: "END"
} as const;

const ERROR_MESSAGE = {
  [DATE_RANGE.START]: "Start date cannot be after end date.",
  [DATE_RANGE.END]: "End date cannot be before start date."
};

const DateRangePicker = ({ resetInputs, onDateRangeChange }: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [error, setError] = useState<"START" | "END" | null>(null);

  const today = new Date();
  const minDate = new Date("01/01/2023");

  const handleStartDate = (date: Date) => {
    setError(null);
    setStartDate(date);

    onDateRangeChange({ startDate: date, endDate });

    if (!date) return;

    if (endDate && (date > endDate)) {
      setError(DATE_RANGE.START);
      return;
    }
  };

  const handleEndDate = (date: Date) => {
    setError(null);
    setEndDate(date);

    onDateRangeChange({ startDate, endDate: date });

    if (!date) return;

    if (startDate && (startDate > date)) {
      setError(DATE_RANGE.END);
      return;
    }
  };

  const clearDates = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setError(null);
  };

  useEffect(() => {
    if (!resetInputs) return;

    clearDates();
  }, [resetInputs]);

  return (
    <div className="daterange-picker" data-testid="daterange-picker-component">
      <div className="flex flex-wrap md:flex-nowrap gap-1.5">
        <label htmlFor="date-picker-start-date" className="sr-only">Filter Start Date</label>

        <DatePicker
          selectsStart
          showIcon
          id="date-picker-start-date"
          data-testid="daterange-picker-start"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={today}
          showPopperArrow={false}
          popperPlacement="bottom-end"
          icon={<CalendarIcon aria-hidden="true" className="size-5 text-gray-400 -mt-px" />}
          placeholderText="Start Date"
          className={classnames({
            "!ring-2 !ring-error": !!error,
            "!text-error": error === "START"
          })}
          onChange={(date) => handleStartDate(date as Date)}
        />

        <label htmlFor="date-picker-end-date" className="sr-only">Filter End Date</label>

        <DatePicker
          selectsEnd
          showIcon
          id="date-picker-end-date"
          data-testid="daterange-picker-end"
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={today}
          showPopperArrow={false}
          popperPlacement="bottom-start"
          icon={<CalendarIcon aria-hidden="true" className="size-5 text-gray-400 -mt-px" />}
          placeholderText="End Date"
          className={classnames({
            "!ring-2 !ring-error": !!error,
            "!text-error": error === "END"
          })}
          onChange={(date) => handleEndDate(date as Date)}
        />
      </div>

      {error &&
        <span className="block text-error text-xs mt-1" data-testid="daterange-picker-error">{ERROR_MESSAGE[error]}</span>
      }
    </div>
  );
};

export default DateRangePicker;