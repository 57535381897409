import { AddFundsSteps, Flow } from "@lib/enums/flows";
import { State529ProviderType } from "@lib/enums/state529";

export const ADD_FUNDS_STEP_DETAILS = {
  [State529ProviderType.DIRECT_DEBIT]: {
    [AddFundsSteps.ACCOUNT_DETAILS]: {
      heading: "529 Withdrawal",
      description: "Add your account details to initiate a withdrawal from your 529 plan.",
    },
    [AddFundsSteps.AMOUNT]: {
      heading: "Amount",
      description: "Enter the amount you want to withdraw from your 529 Account.",
    },
    [AddFundsSteps.SUBMIT]: {
      heading: "Submit Withdrawal",
      subHeading: "You are good to go!",
      description: "Click submit to initiate the withdrawal.",
    },
    [AddFundsSteps.SUCCESS]: {
      heading: "Success",
      subHeading: "Your withdrawal has been initiated.",
    },
  },
  [Flow.PERSONAL_DEBIT]: {
    [AddFundsSteps.ACCOUNT_DETAILS]: {
      heading: "Enter bank details",
      description: "Enter your bank account details to initiate a withdrawal",
    },
    [AddFundsSteps.AMOUNT]: {
      heading: "Amount",
      description: "Enter the amount you want to withdraw",
    },
    [AddFundsSteps.SUBMIT]: {
      heading: "Submit Withdrawal",
      subHeading: "You are good to go!",
      description: "Click submit to initiate the withdrawal.",
    },
    [AddFundsSteps.SUCCESS]: {
      heading: "Success",
      subHeading: "Your withdrawal has been initiated.",
    },
  }
};

export const DIRECT_DEBIT_STEPS_LIST = [
  AddFundsSteps.ACCOUNT_DETAILS,
  AddFundsSteps.AMOUNT,
  AddFundsSteps.SUBMIT,
  AddFundsSteps.SUCCESS
];

export const DIRECT_DEBIT_STEPS_LIST_LINKED529 = [
  AddFundsSteps.AMOUNT,
  AddFundsSteps.SUBMIT,
  AddFundsSteps.SUCCESS
];

export const ACCOUNT_TYPE_OPTIONS = [{
  label: "Checking",
  value: "checking"
},
{
  label: "Savings",
  value: "savings"
}];