import DescriptionList, { type DescriptionListProps } from "@ui/DescriptionList/DescriptionList";

import type { StandardTransaction } from "@lib/types/transaction";

import { formatDateString } from "@lib/utils/formatDates";
import { startCase } from "@utils/format";

type TransactionDetailsProps = {
  transaction: StandardTransaction;
}

const TransactionDetails = ({ transaction }: TransactionDetailsProps) => {
  const hasInstitutionPaymentDetails = !!transaction.metadata && !!transaction.metadata.institution_payment;

  const institutionPaymentDetails = hasInstitutionPaymentDetails ? transaction.metadata?.institution_payment : null;

  const detailsList = [
    { term: "Payment Method", details: transaction.method },
    { term: "Payment ID", details: transaction.id },
    { term: "Trace Number", details: transaction.trace_number },
    { term: "Company Name", details: transaction.company_name },
    { term: "Description", details: transaction.enriched_description || transaction.description },
  ] as DescriptionListProps["list"];

  const tuitionPaymentDetailsList = institutionPaymentDetails ? [
    { term: "Institution", details: institutionPaymentDetails.institution.name },
    { term: "Type", details: startCase(institutionPaymentDetails?.type) },
    { term: "Payment Status", details: startCase(transaction.status) },
    { term: "Last Status Update", details: formatDateString(transaction.updated_at) },
  ] as DescriptionListProps["list"]
    : [];

  const statusList = [
    { term: "Initiated On", details: formatDateString(transaction.created_at) },
    { term: "Processed On", details: formatDateString(transaction.processed_dt) },
  ] as DescriptionListProps["list"];

  return (
    <>
      <DescriptionList table title="Details" list={detailsList} />

      {institutionPaymentDetails &&
        <DescriptionList table title="Tuition Payment Details" list={tuitionPaymentDetailsList}/>
      }

      <DescriptionList table title="Status" list={statusList} />
    </>
  );
};

export default TransactionDetails;