import { useState } from "react";
import { styled } from "@mui/material/styles";

import { Button, Checkbox, CircularProgress, Stack, Typography } from "@mui/material";
import CheckIcon from "@assets/images/check.svg";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

const CheckboxIcon = styled(Checkbox)(({ theme }) => ({
  paddingLeft: 0,
  paddingTop: "8px",
  color: theme.palette.grey[700],
}));

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  margin: "1rem 0",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    margin: "1.75rem 0",
  },
}));

const ConfirmButton = styled(Button)({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
  marginBottom: "0",
});

const CheckboxStack = styled(Stack)({
  flexDirection: "row",
  alignItems: "flex-start",
  width: "85%",
  margin: "auto",
});

const AccountConnectionSubtitle = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "2rem",
  textAlign: "justify",
  width: "90%",
});

const BenefitsStack = styled(Stack)({
  flexDirection: "row",
  alignItems: "flex-start",
  marginBottom: "1rem",
});

const SingleBenefitStack = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  marginBottom: "1rem",
});

const CheckImage = styled("img")({
  marginRight: "1rem",
});

type AccountConnectionProps = {
  policy: { id: string; url: string; };
  connect529: () => void;
};

const AccountConnection = ({
  policy,
  connect529
}: AccountConnectionProps) => {
  const { trackEvent } = useAnalytics();

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    setLoading(true);

    trackEvent("STARTED_TO_LINK", {
      origin: ANALYTICS_EVENT_ORIGIN.DASHBOARD,
      action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
      action_description: "Clicked the Continue button",
      component: "AccountConnection",
    });

    await connect529();
    setLoading(false);
  };

  return (
    <Content>
      <AccountConnectionSubtitle data-cy="link-account-connection-title">
        Connecting your 529 Account allows us to provide a seamless experience for you.{" "}
      </AccountConnectionSubtitle>
      <BenefitsStack spacing={1}>
        <Typography>A connection will allow you to:</Typography>
      </BenefitsStack>
      <SingleBenefitStack>
        <CheckImage src={CheckIcon} alt="Check Icon" />
        <Typography sx={{ textAlign: "left" }} data-cy="link-account-connection-benefit">
          View your 529 balance within the Backpack experience
        </Typography>
      </SingleBenefitStack>
      <SingleBenefitStack>
        <CheckImage src={CheckIcon} alt="Check Icon" />
        <Typography sx={{ textAlign: "left" }}>
          Add your Backpack account as a withdrawal method
        </Typography>
      </SingleBenefitStack>
      <SingleBenefitStack>
        <CheckImage src={CheckIcon} alt="Check Icon" />
        <Typography sx={{ textAlign: "left" }}>
          Initiate a withdrawal or pay tuition directly from your 529
        </Typography>
      </SingleBenefitStack>

      <Stack mb={"-1rem"}>
        <CheckboxStack spacing={loading ? 0 : 1}>
          <CheckboxIcon
            checked={checked}
            onChange={() => setChecked(!checked)}
            aria-label="Terms and Policies"
            data-cy="terms-and-policies-checkbox"
            disableRipple
          />

          <Typography
            onClick={() => setChecked(!checked)}
            variant="legal"
            sx={{ textAlign: "justify" }}
            data-cy="link-account-connection-policies"
          >
            By checking this checkbox, I authorize Backpack to create a connection to my selected
            529 Plan Account. Backpack will never sell your information and will only use this
            account connection to facilitate Backpack application capabilities as outlined in
            Backpack’s{" "}
            <a href={policy.url} target="_blank" rel="noreferrer">
              <b>Terms of Service</b>
            </a>
            .
          </Typography>
        </CheckboxStack>

        <ConfirmButton
          variant="contained"
          disabled={!checked}
          onClick={handleNext}
          data-cy="link-account-connection-button"
        >
          {loading ? <CircularProgress size="1.7rem" sx={{ color: "white" }} /> : "Continue"}
        </ConfirmButton>
      </Stack>
    </Content>
  );
};

export default AccountConnection;