import React, { FC } from "react";

import {
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
  typographyClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import OnboardingSuccess from "@assets/images/successStudent.svg";

const ConfirmationStack = styled(Stack)({
  padding: "1rem",
  paddingBottom: 0,
  flexGrow: "1",
  alignItems: "center",
});

const Logo = styled("img")(({ theme }) => ({
  width: "80%",
  backgroundColor: "white",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
  [typographyClasses.root]: {
    paddingLeft: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
  },
}));

interface FinalConfirmationPropTypes {
  handleNext: any;
}

const FinalConfirmation: FC<FinalConfirmationPropTypes> = ({ handleNext }) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const createAccount = async () => {
    try {
      setLoading(true);
      const owner = await handleNext();
      if (!owner) {
        setError("There was an unexpected error. Please contact support");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("There was an unexpected error. Please contact support");
    }
  };

  return (
    <ConfirmationStack>
      <Stack>
        <Typography fontWeight={"bold"} variant="h6" data-cy="confirmation-title">
          Identity confirmed!
        </Typography>
        <Typography mt={1} data-cy="confirmation-subtitle">
          We have the details we need to create your Backpack account.
        </Typography>
      </Stack>
      <Logo src={OnboardingSuccess} alt={"Backpack Logo"} />
      {error ? (
        <Typography width="70%" color="error" data-cy="confirmation-error">
          {error}
        </Typography>
      ) : (
        <Typography data-cy="confirmation-information">
          Click create account below. You’ll be <br></br>redirected to your Backpack dashboard.
        </Typography>
      )}
      {error ? (
        <Link
          href="https://www.backpack529.com/contact"
          underline="none"
          target="_blank"
          rel="noreferrer"
          id="intercom_support"
          width="85%">
          <ConfirmButton
            onClick={createAccount}
            variant="contained"
            disabled={loading}
            color="error"
            data-cy="details-button">
            Contact Support
          </ConfirmButton>
        </Link>
      ) : (
        <ConfirmButton
          onClick={createAccount}
          variant="contained"
          disabled={loading}
          data-cy="details-button">
          {loading ? (
            <>
              <CircularProgress size="1.7rem" sx={{ color: "white" }} />
              <Typography sx={{ marginLeft: "15px" }}>Setting up your account</Typography>
            </>
          ) : (
            "Create Account"
          )}
        </ConfirmButton>
      )}
    </ConfirmationStack>
  );
};

export default FinalConfirmation;
