import { BANK_ACCOUNT_NUMBER_VALIDATOR, BANK_NAME } from "@lib/constants/banks";

export const ERROR_TYPE = {
  ACCESS_DENIED_EXCEPTION: "ACCESS_DENIED_EXCEPTION",
  COUNTERPARTY_NOT_FOUND: "COUNTERPARTY_NOT_FOUND",
  DUPLICATE_APPLICATION: "DUPLICATE_APPLICATION",
  DUPLICATE_RECORD: "DUPLICATE_RECORD",
  DUPLICATE_ROW_EXCEPTION: "DUPLICATE_ROW_EXCEPTION",
  FILES_MISSING: "FILES_MISSING",
  INSTITUTION_PAYMENT_COMPLETE: "INSTITUTION_PAYMENT_COMPLETE",
  INSUFFICIENT_FUNDS: "INSUFFICIENT_FUNDS",
  INVALID_FORMAT: "INVALID_FORMAT",
  MFA_CODE_EXCEPTION: "MFA_CODE_EXCEPTION",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  TOO_MANY_REQUESTS: "TOO_MANY_REQUESTS",
  TRANSFER_ALREADY_INITIATED: "TRANSFER_ALREADY_INITIATED",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  UNEXPECTED_ERROR_LOGIN: "UNEXPECTED_ERROR_LOGIN",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  UNKNOWN_EXCEPTION: "UNKNOWN_EXCEPTION",
  VERIFY_MFA_ERROR: "VERIFY_MFA_ERROR",
} as const;

export const FORM_ERROR_TYPE = {
  INVALID_INPUT: "INVALID_INPUT",
  INVALID_FORMAT: "INVALID_FORMAT",
  REQUIRED: "REQUIRED"
} as const;

export const FORM_ERROR = {
  [FORM_ERROR_TYPE.INVALID_INPUT]: "Please fix the highlighted errors to proceed.",
  [FORM_ERROR_TYPE.INVALID_FORMAT]: "There was a problem submitting the form. Fix the issues below and try again.",
  [FORM_ERROR_TYPE.REQUIRED]: "Please fill out the required fields."
};

export const VALIDITY_STATE = {
  VALUE_MISSING: "valueMissing",
} as const;

export const VALIDITY_STATE_ERROR = {
  [VALIDITY_STATE.VALUE_MISSING]: "This is a required field."
};

export const ERROR_REPORTING: { [key: string]: string[]} = {
  debug: [
    ERROR_TYPE.DUPLICATE_APPLICATION,
    ERROR_TYPE.DUPLICATE_ROW_EXCEPTION,
    ERROR_TYPE.MFA_CODE_EXCEPTION,
    ERROR_TYPE.VERIFY_MFA_ERROR,
    ERROR_TYPE.INVALID_FORMAT,
    ERROR_TYPE.RECORD_NOT_FOUND
  ],
  log: [
    ERROR_TYPE.ACCESS_DENIED_EXCEPTION,
    ERROR_TYPE.SESSION_EXPIRED,
  ],
};

export const INPUT_ERROR = {
  ACCOUNT_NUMBER: "account_number",
  ROUTING_NUMBER: "routing_number"
} as const;

export const INPUT_ERROR_MESSAGE = {
  [INPUT_ERROR.ACCOUNT_NUMBER]: {
    default: "Please enter a valid account number.",
    [BANK_NAME.FIDELITY]: `Account number should be ${BANK_ACCOUNT_NUMBER_VALIDATOR.fidelity.length} digits long.`
  },
  [INPUT_ERROR.ROUTING_NUMBER]: "Routing number should be 9 digits long."
};

export const DEFAULT_CONTACT_SUPPORT_LINK = "https://www.backpack529.com/contact";
export const DEFAULT_CONTACT_SUPPORT_ERROR_TITLE = "Whoops, looks like we failed this one";
export const DEFAULT_CONTACT_SUPPORT_ERROR_BODY = "Click below to contact support and let us know what happened. We'll have everything back to summa cum laude status in no time.";