import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@ui/Button/Button";
import DirectDebitAccountForm from "@components/DirectDebit/DirectDebitAccountForm";
import Spinner from "@ui/Spinner/Spinner";
import ModalDialog from "@ui/Modal/ModalDialog";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import { Flow, ManualLink529Steps } from "@lib/enums/flows";
import { State529ProviderType } from "@lib/enums/state529";
import { ProfileType } from "@lib/enums/userProfile";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getState529Connection } from "@lib/queries/state529Queries";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useOnboardingStore } from "@stores/onboardingStore";
import { useUserProfileStore } from "@stores/userProfileStore";

import type { AccountDetails } from "@lib/types/addFunds";

const Add529DetailsScreen = () => {
  const { trackEvent } = useAnalytics();

  const formId = "onboarding-fidelity-account-form";
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (formRef.current && formRef.current.id !== formId) {
      formRef.current.id = formId;
    }
  }, [formId]);

  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));
  const origin = useOnboardingStore(useShallow((state) => state.origin));
  const hasSkippedDirectDebitLinkingBefore = useUserProfileStore(useShallow((state) => state.profiles.onboarding.hasSkippedDirectDebitLinking));

  const update529Connection = useCurrentUserStore((state) => state.updateBeneficiary529Connection);

  const updateState529PlanDetails = useOnboardingStore((state) => state.updateState529PlanDetails);
  const updateLinkStatus = useOnboardingStore((state) => state.updateLinkStatus);
  const updateProfile = useUserProfileStore((state) => state.updateProfile);

  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const goBackToPayTuition = useOnboardingStore((state) => state.goBackToPayTuition);

  const [openSkipDialog, setOpenSkipDialog] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null);

  const {
    data: state529Connection,
    refetch: refetchState529Connection,
    isSuccess: isState529ConnectionSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, beneficiary.state_529_plan_id],
    queryFn: () => getState529Connection(beneficiary.state_529_plan_id),
    throwOnError: true,
    enabled: !!beneficiary.state_529_plan_id,
    ...DEFAULT_QUERY_OPTIONS
  });

  const handleIsValidForm = (isValid: boolean) => {
    if (isValid === isValidForm) return;

    setIsValidForm(isValid);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      formRef.current.submitForm();
    }

    trackEvent("STARTED_FIDELITY_LINK", {
      origin: ANALYTICS_EVENT_ORIGIN.ONBOARDING,
      action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
      action_description: "Clicked the Continue button",
      component: "Add529DetailsScreen",
    });
  };

  const handleOnFormSuccess = (accountDetails: AccountDetails) => {
    refetchState529Connection();
    setAccountDetails(accountDetails);
  };

  const handleSkipLinking = () => {
    // update profile store that tuition payment was skipped
    updateProfile({
      type: ProfileType.ONBOARDING,
      update: { hasSkippedDirectDebitLinking: true }
    });

    updateLinkStatus(ManualLink529Steps.SKIPPED);

    trackEvent("SKIPPED_LINKING_FIDELITY", {
      origin: ANALYTICS_EVENT_ORIGIN.ONBOARDING,
      action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
      action_description: "Clicked the skip button in the are you sure dialog",
      component: "Add529DetailsScreen",
    });

    goToNextStep();
  };

  useEffect(() => {
    if (!accountDetails) return;

    if (!!accountDetails
      && state529Connection
      && isState529ConnectionSuccess
    ) {
      update529Connection({
        beneficiary_id: beneficiary.id,
        state_529_connection: state529Connection
      });

      updateState529PlanDetails({
        state529Plan: {
          ...beneficiary.state_529_plan,
          state_529_connection: state529Connection
        },
        planProviderType: State529ProviderType.DIRECT_DEBIT,
        ...accountDetails
      }, true);

      updateProfile({
        type: ProfileType.ONBOARDING,
        update: { hasLinked529: true }
      });

      if (origin === Flow.TUITION_PAYMENT) {
        goBackToPayTuition();
      } else {
        goToNextStep();
      }
    }
  }, [state529Connection]);

  return (
    <>
      <div className="modal-screen-footer-fixed">
        <div className="flex flex-col justify-between items-center">
          <h2 className="modal-header-title" data-testid="onboarding-screen-header">Add 529 Details</h2>

          <p className="modal-header-description" data-testid="onboarding-screen-description">
            Fidelity 529 Plans allow direct debiting for 529 eligible expenses. Enter your Fidelity details below.
          </p>

          <DirectDebitAccountForm
            type="fidelity"
            formId={formId}
            ref={formRef}
            onIsValidForm={(isValid) => handleIsValidForm(isValid)}
            onPendingSubmit={(enable) => setIsPendingSubmit(enable)}
            onFormSuccess={(accountDetails) => handleOnFormSuccess(accountDetails)}
          />
        </div>

        <div className="w-full shrink-0 flex justify-between gap-4">
          <When condition={!hasSkippedDirectDebitLinkingBefore}>
            <Button
              secondary
              rounded
              fullWidth
              data-testid="skip-add-529-details-btn"
              disabled={isPendingSubmit}
              onClick={() => setOpenSkipDialog(true)}
            >
              I&apos;ll add them later
            </Button>
          </When>

          <Button
            rounded
            fullWidth
            type="submit"
            form={formId}
            data-testid="add-529-details-submit-btn"
            disabled={!isValidForm || isPendingSubmit}
            onClick={handleFormSubmit}
          >
            {isPendingSubmit
              ? <Spinner color="white" />
              : "Continue"
            }
          </Button>
        </div>
      </div>

      <ModalDialog
        open={openSkipDialog}
        onClose={() => setOpenSkipDialog(false)}
      >
        <h3 className="text-xl font-montserrat font-bold">Are you sure?</h3>

        <p className="mt-4 text-base">Your Fidelity direct debit details are required to make Tuition Payments and withdraw 529 funds for non-tuition expenses like laptops, textbooks and more.</p>

        <div className="mt-4">
          <Button
            primary
            rounded
            fullWidth
            data-testid="modal-dialog-skip-button"
            onClick={() => setOpenSkipDialog(false)}
          >
            Go Back
          </Button>

          <Button
            text
            rounded
            size="sm"
            className="mt-1.5 font-medium"
            data-testid="modal-dialog-go-back-button"
            onClick={() => handleSkipLinking()}
          >
            Skip
          </Button>
        </div>
      </ModalDialog>
    </>
  );
};

export default Add529DetailsScreen;