import React from "react";

import Icon, { type OutlineIconProps } from "@icons/Icon";

const XMarkIcon = React.memo(function XMarkIcon({
  outline,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={true} { ...iconProps }>
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </Icon>
  );
});

export default XMarkIcon;