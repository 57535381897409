import { Else, If, Then, When } from "react-if";

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import XMarkIcon from "@icons/XMarkIcon";
import Well from "@ui/Well/Well";
import TransactionDetails from "@components/TransactionDetailDrawer/TransactionDetails";
import InstitutionPaymentDetails from "@components/TransactionDetailDrawer/InstitutionPaymentDetails";

import { useTransactionsStore } from "@stores/transactionsStore";

import { formatDateString } from "@lib/utils/formatDates";
import { formatCurrency } from "@utils/deprecated/formats";
import { classnames } from "@lib/utils/classnames";

import type { InstitutionPaymentTransaction, StandardTransaction } from "@lib/types/transaction";

type TransactionDetailDrawerProps = {
  open: boolean;
}

const TransactionDetailDrawer = ({ open }: TransactionDetailDrawerProps) => {
  const transaction = useTransactionsStore((state) => state.modal?.transaction);
  const badge = useTransactionsStore((state) => state.modal?.badge);
  const isInstitutionPayment = transaction && "institution" in transaction;

  const handleClose = useTransactionsStore((state) => state.setModal);

  if (!transaction) return null;

  return (
    <Dialog open={open} onClose={() => {}} className="dialog">
      <div data-testid="transaction-detail-drawer-component" className="drawer-wrapper sm:pl-16">
        <DialogPanel transition className="drawer-container max-w-md sm:max-w-lg lg:max-w-screen-sm">
          <div className="drawer divide-y divide-gray-200">
            <div className="px-6 sm:px-8 pt-5 pb-4">
              <div className="flex items-start justify-between">
                <DialogTitle className="text-lg font-semibold leading-6 text-primary">Transaction Details</DialogTitle>

                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 h-6"
                    data-testid="transaction-detail-drawer-close-icon-btn"
                    onClick={() => handleClose()}
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon aria-hidden="true" className="size-6" />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll p-6 sm:pt-8 sm:px-8">
              <div className="relative only:flex-1 flex flex-col gap-6">
                <Well className="p-7 rounded text-primary">
                  <dl className="flex flex-col sm:flex-row gap-y-4 gap-x-12 justify-between sm:items-end h-full">
                    <div className="space-y-2 sm:space-y-6">
                      <div>
                        <dt className="sr-only">Amount</dt>
                        <dd className="font-bold text-3xl">{formatCurrency(Math.abs(transaction.amount_in_cents))}</dd>
                      </div>

                      <div>
                        <dt className="sr-only">Company Name</dt>
                        <dd className="text-xl">{transaction.transaction_name}</dd>
                      </div>
                    </div>

                    <div className={classnames("flex flex-col gap-1.5 size-full sm:w-48 text-md", badge ? "justify-between" : "justify-end")}>
                      <When condition={!isInstitutionPayment}>
                        <div className="flex gap-2.5 justify-between">
                          <dt className="font-bold">Type</dt>
                          <dd className="text-right">{transaction.type}</dd>
                        </div>
                      </When>

                      <When condition={!!badge}>
                        <div className="self-end">
                          <dt className="sr-only">Payment Status</dt>
                          <dd className="text-right" data-testid="transaction-detail-drawer-badge">{badge}</dd>
                        </div>
                      </When>

                      <div className="flex gap-2.5 justify-between">
                        <dt className="font-bold">Date</dt>
                        <dd className="text-right">{formatDateString(transaction.created_at)}</dd>
                      </div>
                    </div>
                  </dl>
                </Well>

                <If condition={isInstitutionPayment}>
                  <Then>
                    <InstitutionPaymentDetails transaction={transaction as InstitutionPaymentTransaction } />
                  </Then>

                  <Else>
                    <TransactionDetails transaction={transaction as StandardTransaction} />
                  </Else>
                </If>
              </div>
            </div>

            <div className="flex shrink-0 py-5 px-6 sm:px-8">
              <button
                type="button"
                className="w-40 inline-flex justify-center rounded bg-blue px-3 py-2 text-sm md:text-base font-bold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                data-testid="transaction-detail-drawer-close-btn"
                onClick={() => handleClose()}
              >
                Close
              </button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default TransactionDetailDrawer;