import { COLOR, VARIANT } from "@lib/constants/theme";

export const BADGE_COLOR = {
  [VARIANT.PRIMARY]: {
    [COLOR.GRAY]: "bg-gray-100 text-gray-600",
    [COLOR.RED]: "bg-red-100 text-red-700",
    [COLOR.YELLOW]: "bg-yellow-100 text-yellow-800",
    [COLOR.GREEN]: "bg-green-100 text-green-700",
    [COLOR.BLUE]: "bg-blue-100 text-blue-700",
    [COLOR.INDIGO]: "bg-indigo-100 text-indigo-700",
    [COLOR.PURPLE]: "bg-purple-100 text-purple-700",
    [COLOR.PINK]: "bg-pink-100 text-pink-700",
    [COLOR.SKY]: "bg-sky-100 text-sky-700",
  },
  [VARIANT.SECONDARY]: {
    [COLOR.GRAY]: "bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-500/10",
    [COLOR.RED]: "bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/10",
    [COLOR.YELLOW]: "bg-yellow-50 text-yellow-800 ring-1 ring-inset ring-yellow-600/10",
    [COLOR.GREEN]: "bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20",
    [COLOR.BLUE]: "bg-blue-50 text-blue-700 ring-1 ring-inset ring-blue-700/10",
    [COLOR.INDIGO]: "bg-indigo-50 text-indigo-700 ring-1 ring-inset ring-indigo-700/10",
    [COLOR.PURPLE]: "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-700/10",
    [COLOR.PINK]: "bg-pink-50 text-pink-700 ring-1 ring-inset ring-pink-700/10",
    [COLOR.SKY]: "bg-sky-50 text-sky-700 ring-1 ring-inset ring-sky-700/10",
  },
  [VARIANT.TERTIARY]: {
    [COLOR.GRAY]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.RED]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.YELLOW]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.GREEN]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.BLUE]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.INDIGO]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.PURPLE]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.PINK]: "bg-white text-primary ring-1 ring-inset ring-gray-200",
    [COLOR.SKY]: "bg-white text-primary ring-1 ring-inset ring-sky-200",
  },
};

export const DOT_COLOR = {
  [COLOR.GRAY]: "fill-gray-400",
  [COLOR.RED]: "fill-red-500",
  [COLOR.YELLOW]: "fill-yellow-500",
  [COLOR.GREEN]: "fill-green-500",
  [COLOR.BLUE]: "fill-blue-500",
  [COLOR.INDIGO]: "fill-indigo-500",
  [COLOR.PURPLE]: "fill-purple-500",
  [COLOR.PINK]: "fill-pink-500",
  [COLOR.SKY]: "fill-sky-500",
};

export default {
  VARIANT,
  COLOR,
  BADGE_COLOR,
  DOT_COLOR,
};