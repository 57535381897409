import * as Sentry from "@sentry/react";
import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@ui/Button/Button";
import DescriptionList, { type DescriptionListProps } from "@ui/DescriptionList/DescriptionList";
import Well from "@ui/Well/Well";
import Tooltip from "@ui/Tooltip/Tooltip";
import InformationCircleIcon from "@icons/InformationCircleIcon";
import Spinner from "@ui/Spinner/Spinner";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { createInstitutionPayment, debitInstitutionPayment } from "@lib/mutations/institutionPaymentMutations";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useOnboardingStore } from "@stores/onboardingStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";

import { formatAmountInCentsToCurrency } from "@utils/formatAmounts";
import { State529ProviderType } from "@lib/enums/state529";

const StatusTooltip = () => {
  return (
    <Tooltip text="Awaiting funds transfer from your 529 Plan to Backpack. Payment will be completed once funds are received." position="right">
      <span className="flex gap-1 items-center">
        Status
        <InformationCircleIcon
          outline
          aria-hidden={true}
          strokeWidth={3}
          className="size-4 text-gray-500"
        />
      </span>
    </Tooltip>
  );
};

const TuitionPaymentReviewScreen = () => {
  const { trackEvent } = useAnalytics();

  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));
  const tuitionPaymentPayload = useTuitionPaymentsStore(useShallow((state) => state.tuitionPaymentPayload));

  const state529PlanDetails = useTuitionPaymentsStore(useShallow((state) => state.state529PlanDetails));
  const planProviderType = useTuitionPaymentsStore(useShallow((state) => state.state529PlanDetails.planProviderType));

  const errorMessage = useTuitionPaymentsStore(useShallow((state) => state.errorMessage));
  const setErrorMessage = useTuitionPaymentsStore((state) => state.setErrorMessage);

  const setTuitionPaymentConfirmation = useTuitionPaymentsStore((state) => state.setTuitionPaymentConfirmation);
  const goToPrevStep = useTuitionPaymentsStore((state) => state.goToPrevStep);
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const goToNextTuitionStep = useTuitionPaymentsStore((state) => state.goToNextStep);

  const title = useMemo(() => {
    if (planProviderType === State529ProviderType.DIRECT_DEBIT) return "Review Payment";

    return "Review tuition payment details";
  }, [planProviderType]);

  const description = useMemo(() => {
    const university = beneficiary.enrolled_institutions[0].name;

    const text = `We'll notify ${university} that your payment is in process and confirm your attendance.`;

    if (planProviderType === State529ProviderType.DIRECT_DEBIT) {
      return text;
    }

    return `${text} Your payment will be marked as pending until the funds are transferred to Backpack.`;
  }, [beneficiary, planProviderType, state529PlanDetails]);

  const termName = useMemo(() => {
    const { institution_period_id } = tuitionPaymentPayload;

    if (!institution_period_id) return "";

    const term = beneficiary.enrolled_institutions[0].periods.find((period) => period.is_active && (period.id === tuitionPaymentPayload.institution_period_id));

    return term?.description || "";
  }, [beneficiary, tuitionPaymentPayload]);

  const state529Details = useMemo(() => {
    const detailsList = [{
      term: "From",
      details: state529PlanDetails.state529Plan?.name
    }] as DescriptionListProps["list"];

    if (state529PlanDetails.account_number) {
      detailsList.push({
        term: "Account Number",
        details: `**${state529PlanDetails.account_number}`,
        privacy: true,
      });
    }

    return detailsList;
  }, [state529PlanDetails, planProviderType]);

  const tuitionPaymentDetails = useMemo(() => {
    return [{
      term: "Recipient",
      details: beneficiary.enrolled_institutions[0].name
    },
    {
      term: "Student",
      details: `${beneficiary.first_name} ${beneficiary.last_name}`
    },
    {
      term: "Student ID Number",
      details: beneficiary.enrollments[0].student_id,
    },
    {
      term: "Amount",
      details: formatAmountInCentsToCurrency(tuitionPaymentPayload.amount_in_cents, true),
    },
    {
      term: "Term",
      details: termName
    },
    {
      term: <StatusTooltip />,
      details: "Pending"
    }];
  }, [beneficiary, tuitionPaymentPayload, termName]);

  const buttonText = useMemo(() => {
    if (planProviderType === State529ProviderType.DIRECT_DEBIT) return "Pay Tuition";

    return "Create Payment";
  }, [planProviderType]);

  // mutation to debit the institution payment
  // used with users with 529 plans that allow direct debit
  const {
    mutate: debitTuitionPayment,
    isPending: isDebitTuitionPaymentPending
  } = useMutation({
    mutationKey: [MUTATION_KEY.DEBIT_INSTITUTION_PAYMENT],
    mutationFn: debitInstitutionPayment,
    onError: (error, variables) => {
      Sentry.withScope(function (scope) {
        scope.setLevel("fatal");
        Sentry.captureMessage(`institution payment debit failed for ${tuitionPaymentPayload.amount_in_cents} (amount in cents) for institution_payment_id: ${variables.institution_payment_id}`,);
        Sentry.captureException(error);
      });

      setTuitionPaymentConfirmation({
        status: null,
        withdrawal_request_id: variables.institution_payment_id,
        amount_in_cents: tuitionPaymentPayload.amount_in_cents
      });

      goToNextTuitionStep();
    },
    onSuccess: (results) => {
      setTuitionPaymentConfirmation({
        ...results,
        amount_in_cents: tuitionPaymentPayload.amount_in_cents
      });

      goToNextTuitionStep();
    }
  });

  const {
    mutate: createTuitionPayment,
    isPending: isCreateTuitionPaymentPending
  } = useMutation({
    mutationKey: [MUTATION_KEY.CREATE_INSTITUTION_PAYMENT],
    mutationFn: createInstitutionPayment,
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onSuccess: (results) => {
      if (planProviderType === State529ProviderType.DIRECT_DEBIT) {
        debitTuitionPayment({
          institution_payment_id: results.id,
          state_529_plan_id: beneficiary.state_529_plan_id
        });
      } else {
        goToNextStep();
      }
    }
  });

  const onCreateTuitionPayment = () => {
    if (planProviderType === State529ProviderType.DIRECT_DEBIT) {
      trackEvent("FIDELITY_PAY_TUITION_ATTEMPT", {
        origin: ANALYTICS_EVENT_ORIGIN.ONBOARDING,
        action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
        action_description: "Clicked the Pay Tuition button",
        component: "TuitionPaymentReviewScreen",
      });
    } else {
      trackEvent("TUITION_PAYMENT_PENDING", {
        origin: ANALYTICS_EVENT_ORIGIN.ONBOARDING,
        action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
        action_description: "Clicked the Create Payment button",
        component: "TuitionPaymentReviewScreen",
      });
    }

    createTuitionPayment(tuitionPaymentPayload);
  };

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title" data-testid="tuition-payment-screen-header">{title}</h2>

        <p className="modal-header-description" data-testid="tuition-payment-screen-description">{description}</p>

        <When condition={!!errorMessage}>
          <Well color="error" className="font-semibold">
            {errorMessage}
          </Well>
        </When>

        <When condition={planProviderType === State529ProviderType.DIRECT_DEBIT}>
          <div className="w-full mt-8 pb-4 border-b">
            <DescriptionList list={state529Details} listItemClassName="py-3.5" />
          </div>
        </When>

        <div className="w-full mt-8">
          <DescriptionList list={tuitionPaymentDetails} listItemClassName="py-3.5" />
        </div>
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          disabled={isCreateTuitionPaymentPending || isDebitTuitionPaymentPending}
          data-testid="tuition-payment-back-button"
          onClick={goToPrevStep}
        >
          Back
        </Button>

        <Button
          rounded
          fullWidth
          disabled={isCreateTuitionPaymentPending || isDebitTuitionPaymentPending}
          data-testid="tuition-payment-create-button"
          onClick={onCreateTuitionPayment}
        >
          {isCreateTuitionPaymentPending || isDebitTuitionPaymentPending
            ? <Spinner color="white" />
            : buttonText
          }
        </Button>
      </div>
    </div>
  );
};

export default TuitionPaymentReviewScreen;