import instance from "config/fetch.config";
import { MutationError } from "@utils/errors";

import type { PersonalDebitCounterpartyPayload, SubmitDebitWithdrawalRequest, SubmitDebitWithdrawalRequestResponse } from "@lib/types/directDebit";
import type { UUID } from "@lib/types/global";

type AddPersonalDebitCounterpartyResponse = {
  id: UUID;
}

export const addPersonalDebitCounterparty = async (payload: PersonalDebitCounterpartyPayload): Promise<AddPersonalDebitCounterpartyResponse> => {
  try {
    const { data: { data } } = await instance.post("/bank-accounts/counterparty", payload);

    return data;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};


export const submitDebitWithdrawalRequest = async ({
  counterparty_id,
  amount_in_cents
}: SubmitDebitWithdrawalRequest): Promise<SubmitDebitWithdrawalRequestResponse> => {
  try {
    const { data: { data } } = await instance.post(`/bank-accounts/counterparties/${counterparty_id}/debit`, { amount_in_cents });

    return data;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};