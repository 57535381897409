import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";

import { moduleTheme } from "@components/PayTuition/theme";

import Stepper from "commons/Stepper";
import Card from "commons/Card/Vertical";
import DefaultErrorScreen from "@shared/Error/DefaultErrorScreen";

import BeneficiarySelection from "@components/PayTuition/screens/BeneficiarySelection";
import InstitutionSelection from "@components/PayTuition/screens/InstitutionSelection";
import PaymentDetails from "@components/PayTuition/screens/PaymentDetails";
import MFAVerification from "@components/PayTuition/screens/MFAVerification";
import Review from "@components/PayTuition/screens/Review";
import PaymentComplete from "@components/PayTuition/screens/PaymentSent";
import PaymentPending from "@components/PayTuition/screens/PaymentPending";

import { QUERY_KEY } from "@lib/queries/constants";
import { getInstitutions } from "@lib/queries/institutionQueries";
import PaymentServices from "@lib/services/payment.services";

import { sanitizeAmount } from "@utils/deprecated/formats";

import type { CurrentUser } from "@lib/types/currentUser";
import type { Institution } from "@lib/types/institution";
import type { Enrollment } from "@lib/types/beneficiary";
import type { PaymentInfo } from "@lib/types/institutionPayment";
import type { State529Connection, State529Plan } from "@lib/types/state529";
import type { ClosePaymentHandlerPayload } from "@components/PayTuition/PayTuitionModal";

type PaymentProps = {
  currentUser?: CurrentUser;
  state529Connection?: State529Connection;
  state529Plan?: State529Plan;
  handleClose: ({ isFinalStep, openWithdrawalModal, state529PlanProvider }: ClosePaymentHandlerPayload) => void;
}

const ErrorViewStep = -1;
const PaymentCompleteStep = 6;
const PaymentRejectedStep = -2;
const steps = ["Student", "Education Institution", "Payment", "Verify", "Review"];

const PaymentTuition = ({
  currentUser,
  state529Connection,
  state529Plan,
  handleClose,
}: PaymentProps) => {
  const [activeStep, setActiveStep] = useState(() => (currentUser ? 1 : 0));
  const [paymentInfo, setPaymentInfo] = useState<Partial<PaymentInfo>>({});
  const [selectedInstitution, setSelectedInstitution] = useState<Institution | null>(null);
  const [filteredInstitutions, setFilteredInstitutions] = useState<Institution[]>([]);

  const { data: institutions } = useQuery({
    queryKey: [QUERY_KEY.INSTITUTIONS],
    queryFn: getInstitutions,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (!institutions && !paymentInfo) return;

    if (!!institutions && !paymentInfo.student) {
      setFilteredInstitutions(institutions);
      return;
    }

    if (!!institutions && !!paymentInfo.student) {
      const filteredInstitutions = institutions.filter((institution) => {
        return paymentInfo.student?.enrollments.find((enrollment: Enrollment) => enrollment.institution_id === institution.id);
      });

      setFilteredInstitutions(filteredInstitutions || institutions);
    }
  }, [institutions, paymentInfo]);

  const updatePaymentInfo = (newInfo: Partial<PaymentInfo>) => {
    setPaymentInfo((prevInfo) => ({ ...prevInfo, ...newInfo }));
  };

  const isCompletePaymentInfo = (currentInfo: Partial<PaymentInfo>): currentInfo is PaymentInfo => {
    const requiredProperties: (keyof PaymentInfo)[] = [
      "amount",
      "recipient",
      "term",
      "student",
      "institution_payment_token",
    ];

    return requiredProperties.every((property) => !!currentInfo[property]);
  };

  const makePayment = async () => {
    if (!isCompletePaymentInfo(paymentInfo)) return;
    const beneficiaryEnrollment = paymentInfo.student.enrollments.find(
      (enrollment: Enrollment) => enrollment.institution_id === paymentInfo.recipient.id,
    );

    const paymentRequestObject = {
      institution_payment_token: paymentInfo.institution_payment_token!,
      beneficiary_enrollment_id: beneficiaryEnrollment!.id,
      institution_period_id: paymentInfo.term.id,
      amount_in_cents: sanitizeAmount(paymentInfo.amount),
    };
    try {
      const payment = await PaymentServices.createPayment(paymentRequestObject);
      if (payment.status === "PENDING") setActiveStep(PaymentRejectedStep);
      else setActiveStep(PaymentCompleteStep);
    } catch (err) {
      setActiveStep(ErrorViewStep);
    }
  };

  // Navigation methods
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep > 0 ? prevActiveStep - 1 : 0));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getGoBackFunction = () => {
    // disable go back function on auth or student selection if user is loged in
    if ((activeStep === 0)
      || (activeStep === 1 && !!currentUser?.id)
      || (activeStep === 6)
      || (activeStep === -2)
    ) { return null; }

    return handleBack;
  };

  const handleCloseBehavior = () => {
    const isFinalStep = activeStep === 6 || activeStep === -2;

    handleClose({ isFinalStep });
  };

  return (
    <ThemeProvider theme={moduleTheme}>
      <Card onClose={handleCloseBehavior} goBack={getGoBackFunction()}>
        {activeStep > 0 && activeStep < 6 && (
          <Stepper stepNames={steps} activeStep={activeStep}>
            {activeStep === 1 && (
              <BeneficiarySelection handleNext={handleNext} updatePaymentInfo={updatePaymentInfo} />
            )}

            {activeStep === 2 && (
              <InstitutionSelection
                institutions={filteredInstitutions}
                selectedInstitution={selectedInstitution}
                handleSelectedInstitution={(selected) => setSelectedInstitution(selected)}
                updatePaymentInfo={updatePaymentInfo}
                handleNext={handleNext}
              />
            )}

            {activeStep === 3 && (
              <PaymentDetails
                handleNext={handleNext}
                updatePaymentInfo={updatePaymentInfo}
                paymentInfo={paymentInfo}
              />
            )}

            {activeStep === 4 && (
              <MFAVerification
                handleNext={handleNext}
                updatePaymentInfo={updatePaymentInfo}
                lastPhoneNumberDigits={currentUser?.phone_number_last_four_digits}
                paymentInfo={paymentInfo}
              />
            )}

            {activeStep === 5 && (
              <Review paymentInfo={paymentInfo} handleNext={handleNext} makePayment={makePayment} />
            )}
          </Stepper>
        )}
        {activeStep === PaymentCompleteStep && (
          <PaymentComplete
            educationInstitutionName={selectedInstitution?.name || ""}
            goToDashboard={() => handleClose({ isFinalStep: true })}
          />
        )}
        {activeStep === PaymentRejectedStep && (
          <PaymentPending
            state529Connection={state529Connection}
            state529Plan={state529Plan}
            goToWithdrawal={() => handleClose({ openWithdrawalModal: true })}
            closeModal={() => handleClose({ isFinalStep: true })}
          />
        )}
        {activeStep === ErrorViewStep && (
          <DefaultErrorScreen />
        )}
      </Card>
    </ThemeProvider>
  );
};

export default PaymentTuition;