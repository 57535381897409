import { useMemo } from "react";

import { classnames } from "@lib/utils/classnames";
import type { Color, FeedbackColor } from "@lib/types/theme";

type WellColors = Color | FeedbackColor;

type WellProps = {
  className?: string;
  color?: WellColors;
  children: React.ReactNode;
}

const getWellColors = (color: WellColors) => {
  switch (color) {
    case "red":
    case "error":
      return "bg-red-50 text-red-700";

    case "sky":
      return "bg-sky-50 text-indigo";

    case "blue":
    case "info":
      return "bg-blue-50 text-blue-700";

    case "yellow":
    case "warning":
      return "bg-yellow-50 text-yellow-700";

    case "green":
    case "success":
      return "bg-green-50 text-green-700";

    case "gray":
    default:
      return "bg-gray-100 text-primary";
  }
};

const Well = ({
  className,
  children,
  color = "gray",
  ...attrs
}: WellProps) => {
  const wellClassName = useMemo(() => {
    return classnames(
      "well",
      getWellColors(color),
      className
    );
  }, [color, className]);

  return (
    <div className={wellClassName} { ...attrs }>
      {children}
    </div>
  );
};

export default Well;
