import { When } from "react-if";
import { useState, useRef, useMemo } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel
} from "@headlessui/react";
import { classnames } from "@utils/classnames";

type TooltipProps = {
  children: React.ReactNode;
  text: string;
  pointerClassName?: string;
  position?: "top" | "bottom" | "right" | "left";
}

const POSITION = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
} as const;

const TRIANGLE_CLASSNAME = {
  [POSITION.TOP]: "top-8 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-t-solid border-b-transparent border-l-transparent border-r-transparent border-t-gray-900",
  [POSITION.BOTTOM]: "bottom-8 left-1/2 transform -translate-x-1/2 -translate-y-full border-8 border-b-solid border-t-transparent border-l-transparent border-r-transparent border-b-gray-900",
  [POSITION.LEFT]: "-right-11 top-1/2 transform -translate-y-1/2 -translate-x-full border-8 border-l-solid border-r-transparent border-t-transparent border-b-transparent border-l-gray-900",
  [POSITION.RIGHT]: "-left-11 top-1/2 transform -translate-y-1/2 translate-x-full border-8 border-r-solid border-l-transparent border-t-transparent border-b-transparent border-r-gray-900",
};

const PANEL_CLASSNAME = {
  [POSITION.TOP]: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
  [POSITION.BOTTOM]: "top-full left-1/2 transform -translate-x-1/2 mt-2",
  [POSITION.LEFT]: "right-full top-1/2 transform -translate-y-1/2 mr-2",
  [POSITION.RIGHT]: "left-full top-1/2 transform -translate-y-1/2 ml-2",
};

export default function Tooltip({
  text,
  children,
  pointerClassName,
  position = "top"
}: TooltipProps) {
  const panelClassName = useMemo(() => {
    return classnames("absolute w-72 bg-gray-900 text-white text-sm rounded-lg shadow-lg px-3 py-2 z-[1502]", PANEL_CLASSNAME[position]);
  }, [position]);

  const triangleClassName = useMemo(() => {
    return classnames("absolute z-[1502]", TRIANGLE_CLASSNAME[position], pointerClassName);
  }, [position, pointerClassName]);

  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => setIsOpen(false), 150);
  };

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Popover className="relative inline-block" data-testid="tooltip-component">
      <PopoverButton
        className="focus:outline-none flex items-center"
        data-testid="tooltip-button"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </PopoverButton>

      <When condition={isOpen}>
        <PopoverPanel
          static
          data-testid="tooltip-text"
          className={panelClassName}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="relative z-[1502]">
            {text}

            {/* Triangle */}
            <div data-testid="tooltip-triangle" className={triangleClassName} />
          </div>
        </PopoverPanel>
      </When>
    </Popover>
  );
}
