import { useState, FC } from "react";

import { Button, CircularProgress, Stack, Typography } from "@mui/material";

import SingleCharacterInputGroup from "commons/Input/SingleCharacterInputGroup";
import Dialog from "commons/Modal/Dialog";

import { InputValidator } from "@utils/deprecated/validations";

import { styled } from "@mui/material";

const Title = styled(Typography)({
  fontWeight: "400",
  fontSize: "1.2rem",
  textAlign: "center",
});

const Text = styled(Typography)({
  textAlign: "center",
  marginTop: "1rem",
});

const Content = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  paddingInline: "2rem",
  [theme.breakpoints.down("md")]: {
    paddingInline: "1rem",
  },
}));

interface VerificationModalPropTypes {
  title: string;
  description: string;
  open: boolean;
  handleCloseModal: () => void;
  handleNext: (verificationCode: string) => void;
  retry: () => void;
}

const VerificationModal: FC<VerificationModalPropTypes> = ({
  title,
  description,
  open,
  handleCloseModal,
  handleNext,
  retry,
}) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<"none" | "resend" | "validate">("none");

  const handleVerifyCode = async () => {
    try {
      if (!isValidCode()) return;
      setError("");
      setLoading("validate");
      await handleNext(verificationCode);
    } catch (err: any) {
      if (!err.errors) setError("An error occurred, please try again or contact support");
      else if (err.errors[0].type === "MFA_CODE_EXCEPTION") setError("Invalid MFA code, try again");
      else setError("An error occurred, please try again or contact support");
      setLoading("none");
    }
  };

  const isValidCode = () => {
    const isValid = InputValidator.isValidCode(verificationCode);
    setError(!isValid ? "Please enter a valid code" : "");
    return isValid;
  };

  const handleValueChange = (e: any) => {
    const code = e.target.value;
    if (code.length > 6 || isNaN(code)) return;
    setVerificationCode(code);
  };

  const handleRetry = async () => {
    try {
      setVerificationCode("");
      setLoading("resend");
      await retry();
      setLoading("none");
    } catch (err: any) {
      setLoading("none");
      setError("An error occurred, please try again or contact support");
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <Stack spacing={2} alignItems="center">
        <Content spacing={3}>
          <Title data-cy="modal-title">{title}</Title>
          <Text data-cy="modal-description">{description}</Text>
          <SingleCharacterInputGroup
            length={6}
            error={!!error}
            onChange={handleValueChange}
            value={verificationCode}
          />

          <Button
            onClick={handleVerifyCode}
            variant="contained"
            disabled={!InputValidator.isValidCode(verificationCode) || loading !== "none"}
            data-cy="modal-submit"
          >
            {loading === "validate" ? (
              <CircularProgress size="1.7rem" sx={{ color: "white" }} />
            ) : (
              "Verify Code"
            )}
          </Button>
        </Content>
        <Button onClick={handleRetry} variant="text" data-cy="modal-retry">
          {loading === "resend" ? (
            <CircularProgress size="1.7rem" sx={{ color: "gray" }} />
          ) : (
            "Request another code"
          )}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default VerificationModal;
