import instance from "config/fetch.config";
import type { AccountOwner, BankAccount, BankAccountBalance } from "@lib/types/account";
import type { UUID } from "@lib/types/global";
import { errorMapper } from "@utils/deprecated/errors";
import { useAuthStore } from "@stores/authStore";

const { setToken } = useAuthStore.getState();

class AccountServices {
  static async getAccountOwnerBankAccounts(
    // @ts-ignore
    uuid: string = "f75cf3b2-3f06-44e4-80e6-03a7fcf44d56",
  ): Promise<BankAccount> {
    try {
      const {
        data: { data },
      } = await instance.get("/bank-accounts");

      return data.bank_accounts[0];
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async getAccountBalance(bank_account_id: UUID): Promise<BankAccountBalance> {
    try {
      const {
        data: { data },
      } = await instance.get(`/bank-accounts/${bank_account_id}/balance`);
      return data.balance;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async getAccountOwner(): Promise<AccountOwner> {
    try {
      const {
        data: { data },
      } = await instance.get("/account-owners");
      return data.owner;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async createOwnerAccount(): Promise<string> {
    try {
      const {
        data: { data },
      } = await instance.post("/account-owners", { key: "placeholder" });

      setToken(data.login_token);

      return data.owner;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}

export default AccountServices;
