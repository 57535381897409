import {
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQueries } from "@tanstack/react-query";
import { When } from "react-if";

import Button from "@ui/Button/Button";
import InputText from "@ui/Input/InputText";
import InputAutocomplete from "@ui/Input/InputAutocomplete";
import Spinner from "@ui/Spinner/Spinner";

import { ERROR_TYPE } from "@lib/constants/error";
import { ANALYTICS_EVENT_ACTION } from "@lib/constants/analytics";

import { useFormValidation } from "@hooks/useFormValidation";
import { useAnalytics } from "@hooks/useAnalytics";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { createBeneficiary } from "@lib/mutations/beneficiaryMutations";

import { FOREVER_STALE_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getInstitutions } from "@lib/queries/institutionQueries";
import { getState529Plans } from "@lib/queries/state529Queries";

import { getState529SupportDetails } from "@helpers/getState529PlanDetails";

import type { BeneficiaryResponse, CreateBeneficiaryPayload } from "@lib/types/beneficiary";
import type { FormProps } from "@lib/types/ui";
import type { Origin } from "@lib/types/global";
import { ORIGIN } from "@lib/constants/global";

type AddBeneficiaryFormProps = {
  origin?: Origin;
  onFormSuccess: (beneficiary: BeneficiaryResponse) => void;
} & FormProps;

const AddBeneficiaryForm = forwardRef<Partial<HTMLFormElement>, AddBeneficiaryFormProps>(({
  formId,
  hideSubmitButton = false,
  origin = ORIGIN.ONBOARDING,
  onIsValidForm,
  onPendingSubmit,
  onFormSuccess
}: AddBeneficiaryFormProps, ref) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current.requestSubmit();
      }
    }
  }));

  const { trackEvent } = useAnalytics();

  const [selectedInstitution, setSelectedInstitution] = useState<string>("");
  const [selectedState529Plan, setSelectedState529Plan] = useState<string>("");

  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const showOtherPlanNameInput = useMemo(() => {
    const planType = getState529SupportDetails(selectedState529Plan)?.verification_type;

    return planType === "other";
  }, [selectedState529Plan]);

  const queryResults = useQueries({
    queries: [
      {
        queryKey: [QUERY_KEY.INSTITUTIONS],
        queryFn: getInstitutions,
        throwOnError: true,
        ...FOREVER_STALE_QUERY_OPTIONS
      },
      {
        queryKey: [QUERY_KEY.STATE_529_PLANS],
        queryFn: getState529Plans,
        throwOnError: true,
        ...FOREVER_STALE_QUERY_OPTIONS
      }
    ]
  });

  const institutions = queryResults[0].data || [];
  const state_529_plans = queryResults[1].data || [];

  const {
    isValidForm,
    errors,
    setErrors,
    setFormErrors,
    validateFormField,
  } = useFormValidation(formId);

  const {
    mutate,
    error: createBeneficiaryError,
    isPending: isCreateBeneficiaryPending,
    isSuccess: isCreateBeneficiarySuccess,
  } = useMutation({
    mutationKey: [MUTATION_KEY.CREATE_BENEFICIARY],
    mutationFn: createBeneficiary,
    onError: (error) => {
      if (error.name === ERROR_TYPE.INVALID_FORMAT) {
        setIsPendingSubmit(false);
      } else {
        throw error;
      }
    },
    onSuccess: async (results) => {
      onFormSuccess(results);
    }
  });

  const handleFormSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();

    setIsPendingSubmit(true);

    const form = document.querySelector("form") as HTMLFormElement;
    const formData = new FormData(form);

    const isValid = (event.target as HTMLFormElement).checkValidity();
    const hasErrors = errors && Object.values(errors).some((error) => !!error.length);

    // if the form is invalid, show an error message
    // and make sure all invalid inputs are in an error state
    if (!isValid) {
      setFormErrors(formData);
      setIsPendingSubmit(false);
      return;
    }

    // if the form is valid but used to have errors
    // courtesey reset all errors
    if (isValid && hasErrors) setErrors(null);

     // since student_id is optional
    // we need to see if it has a value before adding it to the payload
    const student_id = (document.getElementById("student-id-number") as HTMLInputElement).value as string;

    const beneficiary: CreateBeneficiaryPayload = {
      first_name: formData.get("first_name") as string,
      last_name: formData.get("last_name") as string,
      state_529_plan_id: selectedState529Plan,
      enrollments: [
        {
          institution_id: selectedInstitution,
        },
      ],
    };

    if (student_id) {
      beneficiary.enrollments[0].student_id = student_id;
    }

    const other_529_plan_name = formData.get("other_529_plan_name");

    if (!!other_529_plan_name && (other_529_plan_name as string).length > 0) {
      trackEvent("OTHER_PLAN_NAME", {
        origin,
        action: ANALYTICS_EVENT_ACTION.FORM_SUBMIT,
        action_description: "Submitting beneficiary form. User has selected other as their 529 plan.",
        component: "AddBeneficiaryForm",
        other_529_plan_name,
      });
    }

    mutate(beneficiary);
  }, [selectedInstitution, selectedState529Plan]);

  const formErrorMessage = useMemo(() => {
    if (createBeneficiaryError && createBeneficiaryError.name === ERROR_TYPE.INVALID_FORMAT) {
      return "There was a problem submitting the form. Fix the issues below and try again.";
    }

    if (!!errors && Object.values(errors).some((error) => !!error.length)) {
      return "Please fill out the required fields.";
    }

    return "";
  }, [errors, createBeneficiaryError]);

  const disableVisually = useMemo(() => {
    return isCreateBeneficiaryPending || isCreateBeneficiarySuccess;
  }, [isCreateBeneficiaryPending, isCreateBeneficiarySuccess]);

  useEffect(() => {
    if (showOtherPlanNameInput) return;

    if (!!errors && Object.hasOwn(errors as object, "other_529_plan_name")) {
      const updatedErrors = { ...errors };
      delete updatedErrors["other_529_plan_name"];

      setErrors(updatedErrors);
    }
  }, [showOtherPlanNameInput]);

  useEffect(() => {
    onIsValidForm(isValidForm);
  }, [isValidForm]);

  useEffect(() => {
    onPendingSubmit && onPendingSubmit(isPendingSubmit);
  }, [isPendingSubmit]);

  return (
    <Suspense>
      <form
        ref={formRef}
        id={formId}
        noValidate
        className="mt-6 flex flex-col gap-4 w-full"
        data-testid="add-beneficiary-form"
        onSubmit={handleFormSubmit}
      >
        <When condition={formErrorMessage}>
          <span className="text-error font-semibold">{formErrorMessage}</span>
        </When>

        <div className="flex flex-col md:flex-row justify-between gap-8">
          <InputText
            required
            id="student-first-name"
            data-testid="student-first-name"
            name="first_name"
            label="Student's First Name"
            placeholder="Enter first name"
            readOnly={disableVisually}
            error={errors?.first_name}
            onBlur={(event) => validateFormField({
              inputName: "first_name",
              isValid: !!event.currentTarget.value.trim()
            })}
          />

          <InputText
            required
            id="student-last-name"
            data-testid="student-last-name"
            name="last_name"
            label="Student's Last Name"
            placeholder="Enter last name"
            readOnly={disableVisually}
            error={errors?.last_name}
            onBlur={(event) => validateFormField({
              inputName: "last_name",
              isValid: !!event.currentTarget.value.trim()
            })}
          />
        </div>

        <InputAutocomplete
          required
          form={formId}
          id="select-university"
          data-testid="university-input-autocomplete"
          name="institution"
          label="University"
          placeholder="Select University"
          disabled={disableVisually}
          options={institutions}
          error={errors?.institution}
          onSelected={(option) => {
            validateFormField({
              inputName: "institution",
              isValid: !!option?.id
            });

            setSelectedInstitution(option?.id);
          }}
        />

        <InputText
          id="student-id-number"
          data-testid="student-id-number"
          name="student_id"
          label="Student ID Number (if known)"
          placeholder="Enter student id number"
          error={createBeneficiaryError?.message}
          readOnly={disableVisually}
        />

        <InputAutocomplete
          required
          form={formId}
          id="select-529-plan-provider"
          data-testid="state-529-input-autocomplete"
          name="state_529_plan"
          label="529 Plan Provider"
          placeholder="Start typing to search"
          options={state_529_plans}
          disabled={disableVisually}
          error={errors?.state_529_plan}
          onSelected={(option) => {
            validateFormField({
              inputName: "state_529_plan",
              isValid: !!option?.id
            });

            setSelectedState529Plan(option?.id);
          }}
        />

        <When condition={showOtherPlanNameInput}>
          <div className="flex flex-col gap-2 p-5 bg-gray-100 rounded-md">
            <span className="font-semibold text-md">It looks like we don&apos;t have your 529 Plan listed. Enter your 529 plan name below:</span>

            <InputText
              required
              id="other-529-plan-name"
              data-testid="other-529-plan-name"
              name="other_529_plan_name"
              label="Other 529 Plan Name"
              hideLabel
              placeholder="529 Plan Name"
              error={errors?.other_529_plan_name}
              readOnly={disableVisually}
              onBlur={(event) => validateFormField({
                inputName: "other_529_plan_name",
                isValid: !!event.currentTarget.value.trim()
              })}
            />
          </div>
        </When>

        <When condition={!hideSubmitButton}>
          <div className="w-full shrink-0">
            <Button
              type="submit"
              form={formId}
              data-testid="add-beneficiary-form-submit-btn"
              data-gtm="add-beneficiary"
              disabled={!isValidForm || isPendingSubmit}
              rounded
              fullWidth
              onClick={handleFormSubmit}
            >
              {isPendingSubmit
                ? <Spinner color="white" />
                : "Continue"
              }
            </Button>
          </div>
        </When>
      </form>
    </Suspense>
  );
});

AddBeneficiaryForm.displayName = "AddBeneficiaryForm";

export default AddBeneficiaryForm;