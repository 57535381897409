import { useEffect, useState } from "react";
import { When } from "react-if";

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import CheckIcon from "@icons/CheckIcon";
import ChevronUpDownIcon from "@icons/ChevronUpDownIcon";

import InputDescription from "@ui/Input/InputDescription";

import { INPUT_TYPE, useInputAttributes } from "@hooks/useInputAttributes";

import type { InputProps } from "@lib/types/ui";

export type InputAutocompleteOption = {
  id: string;
  name: string;
};

type InputAutocompleteProps = {
  options: InputAutocompleteOption[];
  preselected?: InputAutocompleteOption["id"];
  onSelected: (selected: InputAutocompleteOption) => void;
} & Omit<InputProps, "onChange" | "defaultValue">;

const InputAutocomplete = ({
  id,
  error,
  helper,
  label,
  options,
  preselected,
  hideLabel = false,
  onSelected,
  ...inputAttrs
}: InputAutocompleteProps) => {
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState<InputAutocompleteOption | null>(null);

  const {
    labelClassName,
    inputClassName,
    ariaDescribedBy,
    description
  } = useInputAttributes({
    id,
    error,
    helper,
    hideLabel,
    type: INPUT_TYPE.AUTOCOMPLETE
  });

  const filteredOptions = query === ""
    ? options
    : options.filter((option) => {
      return option.name.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    if (preselected) {
      const selected = options.find((option) => option.id === preselected) || null;

      setSelectedOption(selected);
    }
  }, [preselected, options]);

  const handleOnChange = (option: InputAutocompleteOption) => {
    setQuery("");
    setSelectedOption(option);
    onSelected(option);
  };

  return (
    <div>
      <label
        className={labelClassName}
        data-testid="input-autocomplete-component-label"
        htmlFor={id}
      >
        {label}
      </label>

      <Combobox
        as="div"
        data-testid="input-autocomplete-component-combobox"
        value={selectedOption}
        onChange={(option: InputAutocompleteOption) => handleOnChange(option)}
      >
        <div className="relative">
          <ComboboxInput
            data-testid="input-autocomplete-component-combobox-input"
            className={inputClassName}
            id={id}
            displayValue={(option: InputAutocompleteOption | null) => option?.name || ""}
            {...inputAttrs}
            onChange={(event) => setQuery(event.target.value)}
            onBlur={() => setQuery("")}
          />

          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>

          {filteredOptions.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredOptions.map((option) => (
                <ComboboxOption
                  key={`input-autocorrect-${option.id}`}
                  value={option}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                >
                  <span className="block truncate group-data-[selected]:font-semibold">{option.name}</span>

                  <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                    <CheckIcon className="size-5 text-white" aria-hidden="true" />
                  </span>
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>

      <When condition={!!helper || !!error}>
        <InputDescription
          id={ariaDescribedBy || ""}
          helper={!!helper}
          error={!!error}
        >
          {description}
        </InputDescription>
      </When>
    </div>
  );
};

export default InputAutocomplete;