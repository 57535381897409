import {
  Dialog,
  DialogBackdrop,
  DialogPanel
} from "@headlessui/react";

import XMarkIcon from "@icons/XMarkIcon";
import { classnames } from "@utils/classnames";

type ModalDialogProps = {
  open: boolean;
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  onClose: () => void;
}

/**
 * The ModalDialog component is a small dialog that can be used on it's own or on top of an existing modal
 */
const ModalDialog = ({
  open,
  children,
  className,
  containerClassName,
  onClose
}: ModalDialogProps) => {
  return (
    <Dialog
      className={classnames("dialog z-[1501]", className)}
      data-testid="modal-dialog-component"
      open={open}
      onClose={onClose}
    >
      <DialogBackdrop
        transition
        className="backdrop transitions-backdrop"
        data-testid="modal-dialog-backdrop"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 sm:p-0 text-center">
          <DialogPanel
            transition
            className="relative overflow-hidden bg-white rounded-xl shadow-xl sm:max-w-sm sm:my-8 sm:w-full transition-panel"
            data-testid="modal-dialog-panel"
          >
            <div className="absolute right-1 top-1">
              <button
                type="button"
                className="relative icon-button rounded-full p-2"
                data-testid="modal-dialog-close-btn"
                onClick={onClose}
              >
                <XMarkIcon />
              </button>
            </div>

            <div className={classnames("w-full p-9 pb-7", containerClassName)}>
              {children}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalDialog;