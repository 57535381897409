import { BANK_ACCOUNT_NUMBER_VALIDATOR } from "@lib/constants/banks";
import type { BankName } from "@lib/types/banks";

/**
 * Validates whether a given value contains only numeric digits.
 *
 * @param {string} value - The value to validate.
 * @returns {boolean} `true` if the value contains only digits, otherwise `false`.
 */
export const isValidNumber = (value: string) => {
  return /^\d+$/.test(value);
};

/**
 * Validates whether a given input value is a valid routing number.
 *
 * Routing numbers must have exactly 9 digits.
 *
 * @param {string} inputValue - The input value to validate as a routing number.
 * @returns {boolean} `true` if the input value is a valid routing number, otherwise `false`.
 */
export const isValidRoutingNumber = (inputValue: string) => {
  const inputLength = inputValue.toString().length;

  return inputLength === 9;
};

/**
 * Validates whether a given input value is a valid bank account number based on the bank type.
 *
 * The account number must match the required length specified by the `BANK_ACCOUNT_NUMBER_VALIDATOR` for the given bank type.
 *
 * Currently only in use with Fidelity.
 *
 * @param {BankName} type - The bank name
 * @param {string} inputValue - The input value to validate as an account number.
 * @returns {boolean} `true` if the input value is a valid account number for the specified bank type, otherwise `false`.
 */
export const isValidAccountNumber = (
  type: BankName,
  inputValue: string
) => {
  const inputLength = inputValue.toString().length;
  const requiredLength = BANK_ACCOUNT_NUMBER_VALIDATOR[type].length;

  return inputLength === requiredLength;
};

export const isValidInputValue = (input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
  if (input instanceof HTMLInputElement && (input.type === "checkbox" || input.type === "radio")) {
    return input.validity.valid && input.checked;
  }

  return input.validity.valid && input.value.trim().toString().length > 0;
};