/**
 * Deprecated
 */
export const errorMapper = (error: any, defaultError: string = "UNEXPECTED_ERROR") => {
  const errorKey = error.errors?.length ? error.errors[0].type : defaultError;
  switch (errorKey) {
    case "DUPLICATE_ROW_EXCEPTION":
      return "An application already exists using this number. Please contact support.";

    case "MFA_CODE_EXCEPTION":
    case "VERIFY_MFA_ERROR":
      return "Please enter a valid code.";

    case "UNEXPECTED_ERROR_LOGIN":
      return "An error occurred with your login attempt. Please contact support.";

    case "RECORD_NOT_FOUND":
      return "User not found, please try again.";

    case "DUPLICATE_APPLICATION":
      return "Application was already completed.";

    case "INVALID_FORMAT":
      return error.errors[0];

    case "ACCESS_DENIED_EXCEPTION":
      return new Error("ACCESS_DENIED_EXCEPTION");

    case "SESSION_EXPIRED":
      return new Error("SESSION_EXPIRED");

    case "UNEXPECTED_ERROR":
    default:
      return "There was an error submitting your request. Please contact support.";
  }
};