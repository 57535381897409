import instance from "config/fetch.config";
import { MutationError } from "@utils/errors";
import type {
  Add529CounterpartyLinkPayload,
  State529ConnectResponse,
  State529DebitWithdrawalRequest,
  State529WithdrawalRequestResponse,
} from "@lib/types/state529";

type UpdateManual529LinkingPayload = {
  state_529_plan_id: string;
  beneficiary_id: string;
}

export const updateManual529Linking = async (payload: UpdateManual529LinkingPayload): Promise<State529ConnectResponse> => {
  try {
    const { data: { data } } = await instance.post("/state-529-connect/manual-link", payload);

    return data.beneficiary;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};

export const add529CounterpartyLink = async ({ state_529_plan_id, payload }: Add529CounterpartyLinkPayload): Promise<State529ConnectResponse> => {
  try {
    const { data: { data } } = await instance.post(`/state-529-connect/${state_529_plan_id}/counterparty`, payload);

    return data;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};

export const submit529DebitWithdrawalRequest = async ({ state_529_plan_id, payload }: State529DebitWithdrawalRequest): Promise<State529WithdrawalRequestResponse> => {
  try {
    const { data: { data } } = await instance.post(`/state-529-connections/${state_529_plan_id}/withdrawal-requests/direct-debit`, payload);

    return data.state_529_withdrawal_request;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};