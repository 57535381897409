import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { TransactionResponse } from "@lib/types/transaction";

export const getTransactions = async (): Promise<TransactionResponse[]> => {
  try {
    const { data: { data } } = await instance.get("/transactions");

    return data.transactions;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};