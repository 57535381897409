import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import {
  useQueries,
  useQuery,
  type UseQueryResult
} from "@tanstack/react-query";

import { checkProfileStatus } from "@helpers/checkProfileStatus";
import { buildActiveNoticesProfile, buildOnboardingProfile } from "@helpers/buildUserProfiles";

import {
  DEFAULT_QUERY_OPTIONS,
  FOREVER_STALE_QUERY_OPTIONS,
  QUERY_KEY,
  STALE_TIME_5_MINS
} from "@lib/queries/constants";
import { getBeneficiaries } from "@lib/queries/beneficiaryQueries";
import { getInstitutionPayments } from "@lib/queries/institutionPaymentQueries";
import { getTransactions } from "@lib/queries/transactionQueries";
import { getState529Connection, getState529WithdrawalRequests } from "@lib/queries/state529Queries";

import { useUserProfileStore } from "@stores/userProfileStore";

import { ProfileType } from "@lib/enums/userProfile";
import { State529WithdrawalStatus } from "@lib/enums/state529";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { InstitutionPayment } from "@lib/types/institutionPayment";
import type { Transaction } from "@lib/types/transaction";
import type { State529WithdrawalRequest } from "@lib/types/state529";

export const useBuildUserProfiles = (enable: boolean) => {
  const userProfiles = useUserProfileStore(useShallow((state) => state.profiles));
  const userProfileStatus = useUserProfileStore(useShallow((state) => state.profilesCompleted));

  let updatedUserProfile = {
    profiles: { ...userProfiles },
    profilesCompleted: { ...userProfileStatus }
  };

  const combinedQueries = useQueries({
    queries: [{
      queryKey: [QUERY_KEY.BENEFICIARIES],
      queryFn: getBeneficiaries,
      enabled: enable,
      throwOnError: true,
      staleTime: STALE_TIME_5_MINS,
      ...DEFAULT_QUERY_OPTIONS,
    },
    {
      queryKey: [QUERY_KEY.INSTITUTION_PAYMENTS],
      queryFn: () => getInstitutionPayments(),
      enabled: enable,
      throwOnError: true,
      ...FOREVER_STALE_QUERY_OPTIONS,
    },
    {
      queryKey: [QUERY_KEY.TRANSACTIONS],
      queryFn: getTransactions,
      enabled: enable,
      throwOnError: true,
      ...FOREVER_STALE_QUERY_OPTIONS,
    },
    {
      queryKey: [QUERY_KEY.STATE_529_WITHDRAWALS, State529WithdrawalStatus.CREATED],
      queryFn:  ({ queryKey }) => getState529WithdrawalRequests((queryKey[1] as State529WithdrawalStatus)),
      enabled: enable,
      throwOnError: true,
      ...FOREVER_STALE_QUERY_OPTIONS,
    }],
    combine: useCallback((results: UseQueryResult[]) => {
      const beneficiaries = results[0].data as Beneficiary[];
      const institutionPayments = results[1].data as InstitutionPayment[];
      const transactions = results[2].data as Transaction[];
      const createdWithdrawalRequests = results[3].data as State529WithdrawalRequest[];

      const hasBeneficiary = !!beneficiaries && beneficiaries.length >= 1;

      const hasInstitutionPayments = !!institutionPayments && institutionPayments.length >= 1;
      const hasPendingInstitutionPayments = !!(!!institutionPayments && institutionPayments.find((payment) => payment.status === "PENDING"));

      const hasTransactions = !!transactions && transactions.length >= 1;

      const hasPendingWithdrawalRequests = !!createdWithdrawalRequests && createdWithdrawalRequests.length >= 1;

      const state_529_plan_id = hasBeneficiary ? beneficiaries[0].state_529_plan_id : "";

      return {
        data: {
          hasBeneficiary,
          hasInstitutionPayments,
          hasPendingInstitutionPayments,
          hasTransactions,
          hasPendingWithdrawalRequests,
          state_529_plan_id
        },
        isSuccess: results.every((result) => result.isSuccess),
      };
    }, []),
  });

  const {
    data,
    isSuccess
  } = combinedQueries;

  const state_529_plan_id = useMemo(() => data?.state_529_plan_id, [data?.state_529_plan_id]);

  const {
    data: state_529_connection,
    isFetching: isState529ConnectionFetching,
    isFetched: isState529ConnectionFetched,
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, state_529_plan_id],
    queryFn: () => getState529Connection(state_529_plan_id),
    enabled: enable && !!state_529_plan_id,
    throwOnError: true,
    staleTime: STALE_TIME_5_MINS,
    ...DEFAULT_QUERY_OPTIONS
  });

  const isAllDataFetched = useMemo(() => {
    if (!isSuccess
      || (!!state_529_plan_id.length &&!state_529_connection)
      || !enable
    ) {
      return false;
    }

    return true;
  }, [
    enable,
    isSuccess,
    state_529_plan_id,
    isState529ConnectionFetching,
    isState529ConnectionFetched,
    state_529_connection
  ]);

  if (!isAllDataFetched) return null;

  if (isAllDataFetched) {
    const onboardingProfile = buildOnboardingProfile(data, state_529_connection);
    const activeNoticesProfile = buildActiveNoticesProfile(data);

    updatedUserProfile = {
      profiles: {
        ...updatedUserProfile.profiles,
        onboarding: onboardingProfile,
        activeNotices: activeNoticesProfile,
      },
      profilesCompleted: {
        ...updatedUserProfile.profilesCompleted,
        onboarding: checkProfileStatus(
          ProfileType.ONBOARDING,
          onboardingProfile
        )
      }
    };
  }

  return updatedUserProfile;
};