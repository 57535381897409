import { Property } from "components/AccountDetailsCard";

import type { AccountOwner } from "@lib/types/account";
import type { BeneficiaryResponse } from "@lib/types/beneficiary";
import type { Institution } from "@lib/types/institution";
import type { State529Plan } from "@lib/types/state529";

import { formatPhoneNumber } from "@utils/format";

export const formatAccount = (account: AccountOwner): Property[] => {
  return [
    {
      label: "Account Name",
      value: `${account.first_name} ${account.last_name}`,
      displayValue: `${account.first_name} ${account.last_name}`,
    },
    {
      label: "Phone Number",
      value: account.phone_number,
      displayValue: `+1 ${formatPhoneNumber(account.phone_number.slice(2))}`,
    },
    {
      label: "Email",
      value: account.email,
      displayValue: account.email,
    },
  ];
};

export const formatBeneficiary = ({
  beneficiary,
  institutions,
  plans,
}: {
  beneficiary: BeneficiaryResponse;
  institutions: Institution[];
  plans: State529Plan[];
}): Property[] => {
  const institution = institutions.find(
    (institution) => institution.id === beneficiary.enrollments[0].institution_id,
  );
  const plan = plans.find((plan) => plan.id === beneficiary.state_529_plan_id);
  return [
    {
      label: "Beneficiary",
      value: `${beneficiary.first_name} ${beneficiary.last_name}`,
      displayValue: `${beneficiary.first_name} ${beneficiary.last_name}`,
      variant: "regular",
    },
    {
      label: "Education Institution",
      value: institution?.name || "N/A",
      displayValue: institution?.name || "N/A",
      variant: "regular",
    },
    {
      label: "529 Plan",
      value: plan?.name || "N/A",
      displayValue: plan?.name || "N/A",
      variant: "regular",
    },
  ];
};
