import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@ui/Button/Button";
import DescriptionList from "@ui/DescriptionList/DescriptionList";
import Spinner from "@ui/Spinner/Spinner";
import Stepper from "@ui/Stepper/Stepper";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { submitDebitWithdrawalRequest } from "@lib/mutations/bankAccountMutations";

import { useAddFundsStore, type PersonalDebitPayload } from "@stores/addFundsStore";

import { ADD_FUNDS_STEP_DETAILS } from "@lib/constants/addFunds";
import { State529ProviderType } from "@lib/enums/state529";
import { AddFundsSteps } from "@lib/enums/flows";

import { startCase } from "@utils/format";
import { formatAmountToCents } from "@utils/formatAmounts";


const AddPersonalFundsSubmit = () => {
  const step = useAddFundsStore(useShallow((state) => state.step));
  const payload = useAddFundsStore(useShallow((state) => state.payload)) as PersonalDebitPayload;
  const accountNumberLast4 = useAddFundsStore(useShallow((state) => state.accountNumberLast4));

  const setConfirmationDetails = useAddFundsStore((state) => state.setConfirmationDetails);
  const goToNextStep = useAddFundsStore((state) => state.goToNextStep);

  const details = useMemo(() => {
    if (!step.details) return ADD_FUNDS_STEP_DETAILS[State529ProviderType.DIRECT_DEBIT][AddFundsSteps.SUBMIT];

    return step.details[AddFundsSteps.SUBMIT];
  }, [step]);

  const withdrawalDetails = useMemo(() => {
    if (!payload && !accountNumberLast4) return [];

    const {
      amount,
      account_holder_name,
      account_type,
    } = payload as PersonalDebitPayload;

    return [{
      term: "Name",
      details: `${account_holder_name}`,
    },
    {
      term: "Account",
      details: `${startCase(account_type || "")} **${accountNumberLast4}`,
      privacy: true,
    },
    {
      term: "Amount",
      details: `$${amount}`
    }];
  }, [payload, accountNumberLast4]);

  const {
    mutate: submitDebitRequest,
    isPending: isSubmitDebitRequestPending,
  } = useMutation({
    mutationKey: [MUTATION_KEY.PERSONAL_DEBIT_WITHDRAWAL_REQUEST],
    mutationFn: submitDebitWithdrawalRequest,
    throwOnError: true,
    onSuccess: async (results) => {
      setConfirmationDetails(results);
      goToNextStep();
    }
  });

  const handleSubmit = () => {
    // [SM - 12/20/2024]: need to update how we handle this error
    if (!payload.counterparty_id) throw new Error("Unable to submit personal debit withdrawal request. Missing counterparty id");

    submitDebitRequest({
      counterparty_id: payload.counterparty_id,
      amount_in_cents: formatAmountToCents(payload.amount)
    });
  };

  return (
    <>
      <header className="modal-content-fixed flex-col px-12 items-center">
        <h1 className="text-blue text-lg font-montserrat font-bold text-center mb-5" data-testid="AddPersonalFundsSubmit-heading">Submit Withdrawal</h1>

        <When condition={step.list && !!step.list.length}>
          <Stepper
            steps={step.list}
            current={step.stepNumber}
            className="mb-7"
          />
        </When>

        <p className="text-base font-bold font-montserrat text-indigo text-center max-w-72" data-testid="AddPersonalFundsSubmit-description">
          <span className="block text-xl mb-4">{details.subHeading}</span>
          {details.description}
        </p>
      </header>

      <main className="modal-screen-footer-fixed px-12 modal-body-scroll">
        <DescriptionList
          data-testid="Add529FundsSubmit-details"
          list={withdrawalDetails}
          containerClassName="mt-14 w-full"
          listItemClassName="py-3.5 gap-x-14"
        />

        <div className="w-full shrink-0">
          <span className="block text-xxxs text-gray-400 mb-4">By clicking “submit”, I authorize Backpack to initiate a withdrawal from my bank account</span>
          <Button
            rounded
            fullWidth
            data-testid="AddPersonalFundsSubmit-submit-btn"
            onClick={handleSubmit}
          >
            {isSubmitDebitRequestPending
              ? <Spinner color="white" />
              : "Submit"
            }
          </Button>
        </div>
      </main>
    </>
  );
};

export default AddPersonalFundsSubmit;