import { useEffect, useRef, useState } from "react";

import {
  FormControl,
  OutlinedInput,
  FormGroup,
  StandardTextFieldProps,
  outlinedInputClasses,
} from "@mui/material";

import { InputValidator } from "@utils/deprecated/validations";

import { styled } from "@mui/material/styles";

const SpacedFormControl = styled(FormControl)(({ theme }) => ({
  width: "2.5rem",
  height: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    width: "2.2rem",
  },
}));

const InputGroup = styled(FormGroup)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "1rem 0",
});

const SingleCharacterInput = styled(OutlinedInput)({
  [`& .${outlinedInputClasses.input}`]: {
    textAlign: "center",
    fontSize: "1.125rem",
    fontWeight: "bold",
    height: "2.6875rem",
    padding: "0",
  }
});

interface SingleCharacterInputGroupProps extends StandardTextFieldProps {
  length: number;
  error: boolean;
  value: string;
  reset?: boolean;
}

const SingleCharacterInputGroup = ({
  length,
  error,
  value,
  reset,
  onChange,
}: SingleCharacterInputGroupProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const inputRefs = useRef<Map<number, HTMLInputElement> | null>(null);
  const inputsIds = Array.from({ length }, (_, i) => i + 1);

  useEffect(() => {
    focusById(1);
  }, []);

  const getMap = () => {
    if (!inputRefs.current) {
      inputRefs.current = new Map();
    }
    return inputRefs.current;
  };

  const getNode = (itemId: number) => {
    const map = getMap();
    return map.get(itemId);
  };

  const focusById = (itemId: number) => {
    const target = itemId > length ? length : itemId;
    const node = getNode(target);
    if (node) node.focus();
  };

  const focusNext = (itemId: string, amount: number = 1) => focusById(parseInt(itemId) + amount);

  const focusPrev = (itemId: string) => focusById(parseInt(itemId) - 1);

  const setValue = (value: string) => {
    setInputValue(value);
    onChange && onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleGroupValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    // quit if reached limit value
    if (value.length >= length) return;

    // quit if not a number
    if (!InputValidator.isNumeric(e.target.value)) return;

    // regular user typing
    if (e.target.value.length === 1) {
      setValue(value + e.target.value);
      focusNext(e.target.id);
    }

    // pasted value
    if (e.target.value.length > 1) {
      const values = e.target.value;
      focusNext(e.target.id, values.length);
      // max {length} characters
      setValue((value + values).slice(0, length));
    }
  };

  const detectBackspace = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (inputValue.length < length) focusPrev(e.currentTarget.id);
      setValue(inputValue.slice(0, -1));
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const resetInputs = () => {
    setInputValue(""); // Reset input value
    onChange && onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>); // Notify parent component
    focusById(1); // Focus the first input after clearing
  };

  useEffect(() => {
    if (reset) {
      return resetInputs();
    }
  }, [reset]);

  return (
    <InputGroup data-testid="single-character-input-group">
      {inputsIds.map((id) => (
        <SpacedFormControl error={error} key={id}>
          <SingleCharacterInput
            data-cy={`mfa-input-${id.toString()}`}
            id={id.toString()}
            inputRef={(node: HTMLInputElement) => {
              const map = getMap();
              if (node) {
                map.set(id, node);
              } else {
                map.delete(id);
              }
            }}
            inputProps={{ inputMode: "numeric" }}
            value={value.slice(id - 1, id)}
            onChange={handleGroupValue}
            onKeyDown={detectBackspace}
            onFocus={handleOnFocus}
          />
        </SpacedFormControl>
      ))}
    </InputGroup>
  );
};

export default SingleCharacterInputGroup;
