import { useEffect, useState } from "react";

import ModalDialog from "@ui/Modal/ModalDialog";
import Button from "@ui/Button/Button";
import Spinner from "@ui/Spinner/Spinner";
import SingleCharacterInputGroup from "commons/Input/SingleCharacterInputGroup";

import { InputValidator } from "@utils/deprecated/validations";

type MFAModalProps = {
  title: string;
  description: string;
  open: boolean;
  error?: Error | null;
  handleMFACode: (verificationCode: string) => void;
  handleClose: () => void;
  onRetry?: () => void;
}

enum LoadingState {
  RESEND = "RESEND",
  VALIDATE = "VALIDATE"
}

const ERROR_TYPE = {
  DEFAULT: "DEFAULT",
  MFA_CODE_EXCEPTION: "MFA_CODE_EXCEPTION",
  INVALID: "INVALID",
  VALIDATION_EXCEPTION: "VALIDATION_EXCEPTION"
} as const;

const ERROR_MESSAGE = {
  [ERROR_TYPE.DEFAULT]: "An error occurred, please try again or contact support.",
  [ERROR_TYPE.MFA_CODE_EXCEPTION]: "Invalid MFA code, try again.",
  [ERROR_TYPE.VALIDATION_EXCEPTION]: "Invalid MFA code, try again.",
  [ERROR_TYPE.INVALID]: "Please enter a valid code."
};

const MFAModal = ({
  title,
  description,
  open,
  error,
  onRetry,
  handleMFACode,
  handleClose
}: MFAModalProps) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<LoadingState | null>(null);

  useEffect(() => {
    if (!error) return;

    setLoading(null);

    if (error.name === ERROR_TYPE.VALIDATION_EXCEPTION) {
      return setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.VALIDATION_EXCEPTION]);
    }

    if (error.name === ERROR_TYPE.MFA_CODE_EXCEPTION) {
      return setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.MFA_CODE_EXCEPTION]);
    }

    setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.DEFAULT]);
  }, [error]);

  useEffect(() => {
    if (!open) {
      setErrorMessage(null);
      setLoading(null);
    }
  }, [open]);

  // [SM - 10/15/2024]: when we refactor <SingleCharacterInputGroup />, come back and update this type to be explicit
  const handleOnChange = (event: any) => {
    const code = event.target.value;
    if (code.length > 6 || isNaN(code)) return;
    setVerificationCode(code);
  };

  const handleVerifyCode = async () => {
    try {
      const isValid = InputValidator.isValidCode(verificationCode);

      // if input is invalid,
      // set the error and exit
      if (!isValid) {
        setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.INVALID]);
        return;
      }

      setErrorMessage(null);
      setLoading(LoadingState.VALIDATE);

      await handleMFACode(verificationCode);
    } catch (error: any) {
      if (error.errors[0].type === ERROR_TYPE.MFA_CODE_EXCEPTION) {
        setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.MFA_CODE_EXCEPTION]);
      } else {
        setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.DEFAULT]);
      }

      setLoading(null);
    }
  };

  const handleRetry = async () => {
    try {
      setVerificationCode("");
      setLoading(LoadingState.RESEND);

      if (onRetry) await onRetry();

      setLoading(null);
    } catch (error: any) {
      setErrorMessage(ERROR_MESSAGE[ERROR_TYPE.DEFAULT]);
      setLoading(null);
    }
  };

  return (
    <ModalDialog open={open} onClose={handleClose}>
      <h3 className="text-xl">{title}</h3>

      <p className="mt-4 mb-1">{description}</p>

      {!!error && <p className="text-error font-bold">{errorMessage}</p>}

      <SingleCharacterInputGroup
        length={6}
        value={verificationCode}
        error={!!error}
        reset={!open}
        onChange={handleOnChange}
      />

      <div className="mt-2">
        <Button
          primary
          rounded
          fullWidth
          data-testid="mfa-modal-button"
          disabled={!InputValidator.isValidCode(verificationCode) || !!loading}
          onClick={handleVerifyCode}
        >
          {loading === LoadingState.VALIDATE
            ? <Spinner color="white" />
            : "Verify Code"
          }
        </Button>

        {!!onRetry &&
          <Button
            text
            rounded
            size="sm"
            className="mt-1.5 font-medium"
            data-testid="mfa-modal-retry-button"
            onClick={handleRetry}
          >
            {loading === LoadingState.RESEND
              ? <Spinner color="blue" />
              : "Request another code"
            }
          </Button>
        }
      </div>
    </ModalDialog>
  );
};

export default MFAModal;