import instance from "config/fetch.config";

import type { UUID } from "@lib/types/global";
import type { BeneficiaryResponse, Enrollment } from "@lib/types/beneficiary";

import { errorMapper } from "@utils/deprecated/errors";

// [SM 06/26/2024] - since we're only updating the student_id, student_id is required in the payload.
// if this gets extended in the future, we'll want to add
// either it's own type or wrap it in Partial.
type UpdateBeneficiaryEnrollmentProps = {
  beneficiaryId: UUID;
  enrollmentId: UUID;
  payload: Pick<Enrollment, "student_id">;
}

class BeneficiaryServices {
  static async getBeneficiaries(): Promise<BeneficiaryResponse[]> {
    try {
      const {
        data: { data },
      } = await instance.get("/beneficiaries");

      return data.beneficiaries;
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async updateBeneficiaryEnrollment({
    beneficiaryId,
    enrollmentId,
    payload
  }: UpdateBeneficiaryEnrollmentProps): Promise<any> {
    try {
      const {
        data: { data },
      } = await instance.patch(`/beneficiaries/${beneficiaryId}/enrollments/${enrollmentId}`, { ...payload });

      // @ts-ignore
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(data.student);
        }, 1000);
      });
    } catch (err: any) {
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}

export default BeneficiaryServices;
