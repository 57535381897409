import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import { InstitutionPaymentStatus, InstitutionPaymentType } from "@lib/enums/institutionPayment";
import type { InstitutionPayment, InstitutionPaymentResponse } from "@lib/types/institutionPayment";

import { formatCurrency, formatDate } from "@utils/deprecated/formats";
import { startCase } from "@utils/format";

export const getInstitutionPayments = async (status?: InstitutionPaymentStatus): Promise<InstitutionPayment[]> => {
  try {
    const url = status
      ? `/institution-payments?status=${status.toLowerCase()}`
      : "/institution-payments";

    const { data: { data } } = await instance.get(url);

    if (!data) throw new Error("There was an error fetching institution payments");

    if (!data.institution_payments.length) return data.institution_payments;

    return data.institution_payments.map((payment: InstitutionPaymentResponse) => {
      const date = formatDate(new Date(payment.updated_at));
      const description = `${startCase(InstitutionPaymentType[(payment.type as InstitutionPayment["type"])])} payment`;
      const formattedAmount = formatCurrency(payment.amount_in_cents);

      return {
        ...payment,
        date,
        description,
        amount: formattedAmount
      };
    });
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};