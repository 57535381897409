import { getState529PlanType } from "@helpers/getState529PlanDetails";

import { DEFAULT_529_PLAN_CONNECTION } from "@lib/constants/currentUser";
import { State529ConnectionStatus } from "@lib/enums/state529";

import type { UUID } from "@lib/types/global";
import type { State529Connection } from "@lib/types/state529";
import type { ActiveNoticesProfile, OnboardingProfile } from "@lib/types/userProfile";

type BuildUserProfileQueriesData = {
  hasBeneficiary: boolean;
  hasInstitutionPayments: boolean;
  hasPendingInstitutionPayments: boolean;
  hasTransactions: boolean;
  hasPendingWithdrawalRequests: boolean;
  state_529_plan_id: UUID;
}

/**
 * Builds an onboarding profile based on initial queries data and whether or not the state 529 connection is available
 *
 * @param {BuildUserProfileQueriesData} data - The initial query data for the user.
 * @param {State529Connection} [state_529_connection] - state 529 connection details if available in initial query
 * @returns {OnboardingProfile} The onboarding profile containing various states and flags.
 */
export const buildOnboardingProfile = (
  data: BuildUserProfileQueriesData,
  state_529_connection?: State529Connection
):OnboardingProfile => {
  const {
    hasBeneficiary,
    hasInstitutionPayments,
    hasTransactions,
    state_529_plan_id
  } = data;

  // set the state 529 connection if it exists
  const state529Connection = !!state_529_plan_id.length && state_529_connection
    ? state_529_connection
    : { ...DEFAULT_529_PLAN_CONNECTION };

  const {
    planProviderType,
    planType,
  } = getState529PlanType({
    state_529_plan_id,
    state_529_connection: state529Connection
  });

  return {
    hasBeneficiary,
    hasInstitutionPayments,
    hasTransactions,
    planProviderType,
    planType,
    hasSkippedTuitionPayment: null,
    hasSkippedManualLinking: null,
    hasSkippedDirectDebitLinking: null,
    hasLinked529: state529Connection.status !== State529ConnectionStatus.NOT_LINKED,
  };
};

/**
 * Builds a profile of active notices based on initial queries data.
 *
 * @param {BuildUserProfileQueriesData} data - The initial query data for the user.
 * @returns {ActiveNoticesProfile} The active notices profile with pending and in-progress statuses.
 */
export const buildActiveNoticesProfile = (data: BuildUserProfileQueriesData): ActiveNoticesProfile => {
  const {
    hasPendingInstitutionPayments,
    hasPendingWithdrawalRequests
  } = data;

  return {
    pendingTuitionPayment: hasPendingInstitutionPayments,
    withdrawalInProgress: hasPendingWithdrawalRequests,
  };
};