import { useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { If, Else, Then } from "react-if";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { Button, IconButton } from "@mui/material";
import Card from "@ui/Card/Card";
import CopyToClipboard from "@ui/CopyToClipboard/CopyToClipboard";
import EyeIcon from "@icons/EyeIcon";
import ChevronDownIcon from "@icons/ChevronDownIcon";

import { useCurrentUserStore } from "@stores/currentUserStore";
import useScreenSize, { SCREEN } from "@hooks/useScreenSize";

import { formatCurrency } from "@utils/deprecated/formats";

const AccountCard = () => {
  const routing_number = useCurrentUserStore(useShallow((state) => state.currentUser.bank_account.routing_number));
  const account_number = useCurrentUserStore(useShallow((state) => state.currentUser.bank_account.account_number));
  const balance = useCurrentUserStore((state) => state.currentUser.bank_account_balance.available_balance_in_cents);

  const [show, setShow] = useState(false);

  const hiddenAccountNumber = useMemo(() => {
    return account_number.slice(0, 4) + "*".repeat(account_number.length - 5) + account_number.slice(-1);
  }, [account_number]);

  const formattedBalance = useMemo(() => {
    return formatCurrency(balance);
  }, [balance]);

  const screenSize = useScreenSize();

  return (
    <Card
      data-cy="account"
      containerClassName="w-full"
      className="flex flex-col xs:flex-row flex-wrap justify-between gap-4"
    >
      <div className="flex flex-col gap-2 lg:gap-4 md:gap-6">
        <h3 className="text-3xl text-blue" data-testid="balance">{formattedBalance}</h3>

        <span className="text-sm md:text-md xl:text-base">
          Available Balance
        </span>
      </div>

      <If condition={[SCREEN.XL, SCREEN.XXL].includes(screenSize)}>
        <Then>
          <div className="flex flex-col justify-end gap-2.5">
            <div className="flex items-center justify-between gap-8 text-base font-bold">
              <span className="font-normal shrink-0">
                Routing Number
              </span>

              <div className="flex items-center justify-end gap-1.5 w-40 pr-8" data-testid="routing-number">
                <CopyToClipboard
                  value={routing_number}
                  tooltip={{
                    text: "Click to copy",
                    placement: "top"
                  }}
                  showIcon={false}
                  notificationMessage="Routing Number copied"
                >
                  {routing_number}
                </CopyToClipboard>
              </div>
            </div>

            <div className="flex items-center justify-between gap-8 text-base font-bold">
              <span className="font-normal shrink-0">
                Account Number
              </span>

              <div className="flex items-center justify-end gap-3.5 w-40" data-testid="account-number">
                <CopyToClipboard
                  copyTextClassnames="sentry-mask"
                  value={account_number}
                  tooltip={{
                    text: "Click to copy",
                    placement: "top"
                  }}
                  showIcon={false}
                  notificationMessage="Account Number copied"
                >
                  {show ? account_number : hiddenAccountNumber}
                </CopyToClipboard>

                <IconButton
                  type="button"
                  className="p-0 hover:bg-transparent text-primary hover:text-blue"
                  disableRipple
                  onClick={() => setShow(!show)}
                >
                  <span className="sr-only">Show Account Number</span>

                  <EyeIcon
                    className="size-5"
                    aria-hidden="true"
                    slash={!show}
                    strokeWidth={2}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </Then>

        {/* smaller screens & mobile */}
        <Else>
          <Disclosure>
            <DisclosureButton
              as={Button}
              disableRipple
              className="group flex items-center justify-start xs:justify-end xs:self-end gap-0.5 xs:w-1/2 xs:whitespace-pre mb-0.5 md:mb-0 font-normal text-primary text-sm md:text-md"
              data-testid="account-card-disclosure-btn"
            >
              Account & Routing #
              <ChevronDownIcon strokeWidth={2}  className="shrink-0 w-4 xl:w-5 group-data-[open]:rotate-180" />
            </DisclosureButton>

            <DisclosurePanel className="w-full flex flex-col gap-2 sm:gap-2.5" data-testid="account-card-disclosure-panel">
              <div className="flex max-[374px]:flex-col items-start xs:items-center justify-between gap-1 font-bold">
                <span className="font-normal shrink-0 text-sm sm:text-md">
                  Routing Number
                </span>

                <div className="flex max-[374px]:items-center max-[374px]:justify-start justify-end gap-1.5 max-[374px]:w-full w-40 text-sm sm:text-md text-primary hover:text-blue" data-testid="routing-number">
                  <CopyToClipboard
                    value={routing_number}
                    tooltip={{
                      text: "Click to copy",
                      placement: "top"
                    }}
                    notificationMessage="Routing Number copied"
                  >
                    {routing_number}
                  </CopyToClipboard>
                </div>
              </div>

              <div className="flex max-[374px]:flex-col items-start xs:items-center justify-between gap-1 font-bold">
                <div className="flex gap-2 items-center">
                  <span className="font-normal shrink-0 text-sm sm:text-md">
                    Account Number
                  </span>

                  <IconButton
                    type="button"
                    className="p-0 hover:bg-transparent text-primary hover:text-blue"
                    disableRipple
                    onClick={() => setShow(!show)}
                  >
                    <span className="sr-only">Show Account Number</span>

                    <EyeIcon
                      className="size-4 xs:size-5"
                      aria-hidden="true"
                      slash={!show}
                      strokeWidth={2}
                    />
                  </IconButton>
                </div>

                <div className="flex max-[374px]:items-center max-[374px]:justify-start justify-end gap-1.5 max-[374px]:w-full w-40 text-sm sm:text-md text-primary hover:text-blue" data-testid="account-number">
                  <CopyToClipboard
                    copyTextClassnames="sentry-mask"
                    value={account_number}
                    tooltip={{
                      text: "Click to copy",
                      placement: "top"
                    }}
                    notificationMessage="Account Number copied"
                  >
                    {show ? account_number : hiddenAccountNumber}
                  </CopyToClipboard>
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </Else>
      </If>
    </Card>
  );
};

export default AccountCard;