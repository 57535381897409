import { useCallback } from "react";
import { When } from "react-if";

import InputDescription from "@ui/Input/InputDescription";

import { INPUT_TYPE, useInputAttributes } from "@hooks/useInputAttributes";

import type { InputProps } from "@lib/types/ui";

import { classnames } from "@utils/classnames";
import {
  formatAmountToCents,
  formatCurrency,
  sanitizeAmount
} from "@utils/formatAmounts";

type InputCurrencyProps = {
  maxAmount?: number;
  minAmount?: number;
} & InputProps;

/**
 * A currency input component that formats the input value as currency.
 *
 * @param {string} id - The unique identifier for the input element.
 * @param {string} [error] - Error message to display when validation fails.
 * @param {string} [helper] - Helper text to provide additional context to the user.
 * @param {string} label - The label for the input element.
 * @param {string} [name] - The name of the input element.
 * @param {string} [className] - Additional classes for the input element.
 * @param {React.ReactNode} [tooltip] - Tooltip element to provide more information.
 * @param {boolean} [hideLabel=false] - Whether to hide the label.
 * @param {function} [onBlur] - Callback function to call when the input loses focus.
 * @param {Object} inputAttrs - Additional attributes to spread onto the input element.
 *
 * @returns {JSX.Element} The rendered component.
 */
const InputCurrency = ({
  id,
  error,
  helper,
  label,
  name,
  className,
  tooltip,
  maxAmount,
  minAmount,
  hideLabel = false,
  onBlur,
  ...inputAttrs
}: InputCurrencyProps) => {
  const {
    labelClassName,
    inputClassName,
    ariaDescribedBy,
    description
  } = useInputAttributes({
    id,
    error,
    helper,
    hideLabel,
    type: INPUT_TYPE.TEXT
  });

  const validateInput = useCallback((value: string) => {
    const input = document.getElementById(id) as HTMLInputElement;
    const amount = formatAmountToCents(value);

    if (!!maxAmount && amount > formatAmountToCents(maxAmount)) {
      input.setCustomValidity("The amount is too high. Please enter a valid amount.");
      return;
    }

    if ((!!minAmount && amount < formatAmountToCents(minAmount))
      || amount <= 0
    ) {
      input.setCustomValidity("The amount is too low. Please enter a valid amount.");
      return;
    }

    input.setCustomValidity("");
  }, [id, minAmount, maxAmount]);

  const handleAmount = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value;
    const isValidFormat = inputValue.trim() !== "" && !isNaN(parseInt(sanitizeAmount(inputValue)));

    const formatted = isValidFormat
      ? formatCurrency(inputValue, false)
      : "";

    validateInput(inputValue);

    if (isValidFormat) {
      event.currentTarget.value = formatted;
    } else {
      event.currentTarget.value = "";
    }

    onBlur && onBlur(event);
  }, [onBlur, validateInput]);

  return (
    <div>
      <label
        className={labelClassName}
        data-testid="input-text-component-label"
        htmlFor={id}
      >
        {label}{tooltip}
      </label>

      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 text-md">$</span>
        </div>

        <input
          type="text"
          className={classnames(inputClassName, "pl-6", className)}
          data-testid="input-currency-component"
          id={id}
          name={name || id}
          aria-invalid={!!error || undefined}
          aria-describedby={ariaDescribedBy}
          {...inputAttrs}
          onBlur={(event) => handleAmount(event)}
        />
      </div>

      <When condition={!!helper || !!error}>
        <InputDescription
          id={ariaDescribedBy || ""}
          helper={!!helper}
          error={!!error}
        >
          {description}
        </InputDescription>
      </When>
    </div>
  );
};

export default InputCurrency;