import { useEffect, useReducer } from "react";
import { Case, Switch, If, Else, Then } from "react-if";

import { ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { moduleTheme } from "@components/PayTuition/theme";

import { Stack } from "@mui/material";
import Card from "commons/Card/Vertical";
import DefaultErrorScreen from "@shared/Error/DefaultErrorScreen";

import AccountConnection from "@components/Link529/screens/AccountConnection";
import Credentials529 from "@components/Link529/screens/Credentials529";
import ReadyToAddAccount from "@components/Link529/screens/ReadyToAddAccount";
import SuccessConnection from "@components/Link529/screens/SuccessConnection";
import ConnectionError from "@components/Link529/screens/ConnectionError";
import AccountError from "@components/Link529/screens/AccountError";
import LinkMFAVerification from "@components/Link529/screens/LinkMFAVerification";
import Link529Header from "@components/Link529/Link529Header";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import Link529Services from "@lib/services/link529.services";

import { Steps, link529Reducer, Actions, createInitialLink529State } from "@lib/reducers/Link529Reducer";

import { LinkType } from "@lib/enums/link529";
import { State529ConnectionStatus } from "@lib/enums/state529";

import type { State529Plan } from "@lib/types/state529";
import type { BankAccount } from "@lib/types/account";
import type { Beneficiary } from "@lib/types/beneficiary";
import type { Link529ModalPayload, ReLink529Payload } from "@lib/types/modal";

type Link529Props = {
  bankAccount: BankAccount;
  beneficiary: Beneficiary;
  state529Plan: State529Plan;
  reLink?: ReLink529Payload;
  handleClose: (payload?: Link529ModalPayload) => void;
}

const CardContent = styled(Stack)(({ theme }) => ({
  padding: "2rem",
  paddingBottom: "0",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    height: "90vh",
    paddingBottom: "0",
  },
}));

const Link529 = ({
  bankAccount,
  beneficiary,
  reLink,
  state529Plan,
  handleClose
}: Link529Props) => {
  const [state, dispatch] = useReducer(link529Reducer, createInitialLink529State({ state529Plan, linkType: reLink?.linkType, ...reLink?.payload }));

  const { step, stepCount, error, beneficiaryPlan, policy, type } = state;

  const { trackEvent } = useAnalytics();

  const handleCloseModal = (nextAction?: "DASHBOARD" | "WITHDRAWAL") => {
    if (step !== Steps.SUCCESS) {
      handleClose();
      return;
    }

    if (nextAction === "DASHBOARD" || !nextAction?.length ) {
      handleClose({ status: State529ConnectionStatus.LINKED });
      return;
    }

    if (nextAction === "WITHDRAWAL") {
      handleClose({ amount: state.amount, linkType: type });
      return;
    }
  };

  const handleNext = () => {
    dispatch({ type: Actions.CONTINUE });
  };

  const accountInfo = {
    accountName: `${beneficiary.first_name} ${beneficiary.last_name}`,
    accountNumber: bankAccount.account_number,
    routingNumber: bankAccount.routing_number,
  };

  const connect529 = async () => {
    try {
      await handleAcceptPolicy();
      handleNext();
    } catch (err) {
      dispatch({ type: Actions.SET_ERROR, payload: { type: "connection" } });
    }
  };

  const sendCredentials = async (username: string, password: string) => {
    try {
      await Link529Services.authenticate(username, password);
      handleNext();
    } catch (err) {
      dispatch({ type: Actions.SET_ERROR, payload: { type: "connection" } });
    }
  };

  const handleMFA = (hasBackpackAccountAdded: boolean) => {
    if (hasBackpackAccountAdded) {
      trackEvent("VERIFICATION_HOLDING_AUTOMATED", {
        origin: ANALYTICS_EVENT_ORIGIN.DASHBOARD,
        action: ANALYTICS_EVENT_ACTION.ON_SUCCESS,
        action_description: "Successfully completed MFA and linked 529. Backpack account already exists.",
        component: "Link529",
        variation: type
      });
    }

    dispatch({
      type: Actions.VERIFY_BACKPACK_ACCOUNT,
      payload: { hasBackpackAccountAdded },
    });
  };

  const addWithdrawalAccount = async () => {
    try {
      await Link529Services.link529();

      trackEvent("VERIFICATION_HOLDING_AUTOMATED", {
        origin: ANALYTICS_EVENT_ORIGIN.DASHBOARD,
        action: ANALYTICS_EVENT_ACTION.ON_SUCCESS,
        action_description: "Successfully added Backpack account and linked 529.",
        component: "Link529",
        variation: type
      });

      dispatch({ type: Actions.CONTINUE });
    } catch (err) {
      dispatch({ type: Actions.SET_ERROR, payload: { type: "account" } });
    }
  };

  const handleAcceptPolicy = async () => {
    try {
      await Link529Services.acceptPolicy(policy.id);
    } catch (err) {
      dispatch({ type: Actions.SET_ERROR, payload: { type: "connection" } });
    }
  };

  const restart = () => {
    dispatch({ type: Actions.RESTART });
  };

  useEffect(() => {
    Link529Services.getPolicies()
      .then((responsePolicy) =>
        dispatch({ type: Actions.SET_POLICY, payload: { policy: responsePolicy } }),
      )
      .catch(() => dispatch({ type: Actions.SET_ERROR, payload: { type: "unexpected" } }));
  }, []);

  const reAddAccount = (type !== LinkType.LINK) && (step === Steps.ADD_BACKPACK_ACCOUNT);

  const hideStepper = (type === LinkType.RELINK_WITHDRAWAL) && (step === Steps.TERMS_OF_SERVICE);

  return (
    <ThemeProvider theme={moduleTheme}>
      <Card onClose={handleCloseModal} goBack={null}>
        <CardContent className="gap-y-5">
          <If condition={!error}>
            <Then>
              <Link529Header
                reAddBackpackAccount={reAddAccount}
                reLinkWithdrawal={type === LinkType.RELINK_WITHDRAWAL && step === Steps.TERMS_OF_SERVICE}
                showStepper={!hideStepper}
                stepper={{ steps: Object.values(Steps), current: stepCount.current }}
              >
                {step}
              </Link529Header>

              <Switch>
                <Case condition={step === Steps.TERMS_OF_SERVICE}>
                  <AccountConnection
                    connect529={connect529}
                    policy={policy}
                  />
                </Case>

                <Case condition={step === Steps.LINK_529_CREDENTIALS}>
                  <Credentials529
                    sendCredentials={sendCredentials}
                    beneficiaryPlan={beneficiaryPlan}
                  />
                </Case>

                <Case condition={step === Steps.LINK_529_MFA}>
                  <LinkMFAVerification handleNext={handleMFA} />
                </Case>

                <Case condition={step === Steps.ADD_BACKPACK_ACCOUNT}>
                  <ReadyToAddAccount
                    handleAddWithdrawalAccount={addWithdrawalAccount}
                    displayInfo={accountInfo}
                    reAddAccount={reAddAccount}
                  />
                </Case>


                <Case condition={step === Steps.SUCCESS}>
                  <SuccessConnection
                    linkType={type}
                    goToDashboard={() => handleCloseModal("DASHBOARD")}
                    goToWithdrawal={() => handleCloseModal("WITHDRAWAL")}
                  />
                </Case>
              </Switch>
            </Then>

            <Else>
              <Switch>
                <Case condition={error === "unexpected"}>
                  <DefaultErrorScreen />
                </Case>

                <Case condition={error === "connection"}>
                  <ConnectionError restart={restart} />
                </Case>

                <Case condition={error === "account"}>
                  <AccountError restart={restart} />
                </Case>
              </Switch>
            </Else>
          </If>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default Link529;
