import * as Sentry from "@sentry/react";

import { ERROR_REPORTING, ERROR_TYPE } from "@lib/constants/error";
import { useAuthStore } from "@stores/authStore";
import type { ErrorInfo } from "react";
import type { AppError } from "@utils/errors";

const { removeToken } = useAuthStore.getState();

export const handleApiError = (error: any) => {
  const reportDebug = ERROR_REPORTING.debug.includes(error.name);
  const reportLog = ERROR_REPORTING.log.includes(error.name);

  console.error(error);

  Sentry.withScope(function (scope) {
    if (reportLog) {
      scope.setLevel("log");
    } else if (reportDebug) {
      scope.setLevel("debug");
    } else {
      scope.setLevel("error");
    }

    if (error.request_id) {
      scope.setExtra("request_id", error.request_id);
    }

    Sentry.captureException(error);
  });

  if (error.name === ERROR_TYPE.SESSION_EXPIRED || error.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION) {
    removeToken();
  }
};

export const logError = (error: AppError, info?: ErrorInfo) => {
  const reportDebug = ERROR_REPORTING.debug.includes(error.name);
  const reportLog = ERROR_REPORTING.log.includes(error.name);

  if (info) {
    console.error(error, info.componentStack);
  } else {
    console.error(error);
  }

  Sentry.withScope(function (scope) {
    if (reportLog) {
      scope.setLevel("log");
    } else if (reportDebug) {
      scope.setLevel("debug");
    }

    if ("request_id" in error) {
      scope.setExtra("request_id", error.request_id);
    }

    Sentry.captureException(error);
  });
};