import React from "react";

import Icon, { type OutlineIconProps } from "@icons/Icon";

type SpeakerphoneIconProps = {
  innerFillClassName?: string;
  strokeClassName?: string;
} & OutlineIconProps;

const SpeakerphoneIcon = React.memo(function SpeakerphoneIcon({
  outline,
  innerFillClassName = "fill-sky-400",
  strokeClassName = "stroke-sky-800",
  ...iconProps
}: SpeakerphoneIconProps) {
  return (
    <Icon outline {...iconProps}>
      <path d="M4.99999 4H6.83208C10.9327 4 14.4569 2.7659 16 1L16 15C14.4569 13.2341 10.9327 12 6.83208 12L4.99998 12C4.44518 12 3.91677 11.887 3.43647 11.6829C2.0043 11.0741 1 9.65433 1 8C1 5.79086 2.79086 4 4.99999 4Z" fill={innerFillClassName} />
      <path d="M9 3.88218V17.2402C9 18.2121 8.2121 19 7.24018 19C6.49646 19 5.83302 18.5325 5.58288 17.8321L3.43647 11.6829M16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5M3.43647 11.6829C2.0043 11.0741 1 9.65433 1 8C1 5.79086 2.79086 4 4.99999 4H6.83208C10.9327 4 14.4569 2.7659 16 1L16 15C14.4569 13.2341 10.9327 12 6.83208 12L4.99998 12C4.44518 12 3.91677 11.887 3.43647 11.6829Z" fill="none" stroke={strokeClassName} strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
  );
});

export default SpeakerphoneIcon;
