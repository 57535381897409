import { FC } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import Error from "@assets/images/errorWarning.svg";
import { styled } from "@mui/material/styles";

const Block = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "1rem auto 0.5rem auto",
});

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  margin: "2.75rem 1.75rem 1.75rem 1.75rem",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
  },
}));

const Title = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 600,
  textAlign: "center",
});

const Text = styled(Typography)({
  textAlign: "left",
  lineHeight: "1.0625rem",
  marginTop: "0",
  width: "80%",
});

const ConnectionErrorButton = styled(Button)({
  width: "85%",
  margin: "1rem",
  padding: ".625rem 0",
});

const InfoContainer = styled(Stack)({
  alignItems: "center",
});

const ContactSupportContainer = styled(Stack)({
  width: "100%",
  alignItems: "center",
});

interface ConnectionErrorProps {
  restart: () => void;
}

const AccountError: FC<ConnectionErrorProps> = ({ restart }) => {
  return (
    <Content>
      <Title
        color="error"
        variant="h1"
        data-cy="link-account-error-title"
      >
        Unable to add Backpack Account
      </Title>
      <Block>
        <img height={100} src={Error} alt={"Connection error"} data-cy="link-account-error-logo" />
      </Block>
      <InfoContainer spacing={3}>
        <Text>We were able to make a successful connection to your 529 Account.</Text>

        <Text data-cy="link-account-error-text">
          However, an error occurred when we attempted to add your Backpack account as a withdrawal
          method.
        </Text>
        <Text fontWeight={"bold"}>
          Please login to your 529 Account and add your Backpack account as a withdrawal method
          manually.
        </Text>
      </InfoContainer>

      <ContactSupportContainer>
        <ConnectionErrorButton
          variant="outlined"
          id="intercom_support"
          data-cy="link-account-error-support-button"
        >
          Contact Support{" "}
        </ConnectionErrorButton>

        <ConnectionErrorButton
          variant="contained"
          onClick={restart}
          data-cy="link-account-error-retry-button"
        >
          Go to 529 Plan
        </ConnectionErrorButton>
      </ContactSupportContainer>
    </Content>
  );
};

export default AccountError;
