import { useMemo } from "react";
import { Else, If, Then } from "react-if";
import { Button } from "@mui/material";
import WarningIcon from "@icons/WarningIcon";

import type { State529Connection, State529Plan } from "@lib/types/state529";
import { State529Provider } from "@lib/enums/state529";

interface PaymentPendingProp {
  state529Connection?: State529Connection
  state529Plan?: State529Plan
  goToWithdrawal: () => void;
  closeModal: () => void;
}

const PaymentPending = ({
  state529Connection,
  state529Plan,
  goToWithdrawal,
  closeModal,
}: PaymentPendingProp) => {
  const showWithdrawalButton = useMemo(() => {
    if (!!state529Connection && !state529Connection.provider_name) return false;

    if (state529Connection?.provider_name === State529Provider.FIDELITY) return true;

    return state529Connection?.is_automated_withdrawal_eligible && state529Connection?.is_withdrawal_eligible;
  }, [state529Connection]);

  if (!state529Plan && !state529Connection) return null;

  const goTo529 = () => {
    window.open(state529Plan?.website_url, "_blank");

    closeModal();
  };

  return (
    <div className="flex flex-col grow items-center justify-between w-8/10 my-11 mx-0 md:mx-7 md:mb-7">
      <h1 className="text-blue text-lg">Payment Pending!</h1>

      <div className="flex items-center justify-center mt-4 mb-2">
        <WarningIcon className="size-25 text-warning-icon" aria-hidden="true" />
      </div>

      <div className="flex flex-col items-center gap-6 font-normal text-sm text-center">
        <p>
          Your Backpack <b data-cy="payment-balance-low">balance is currently too low</b> to make
          this payment.
        </p>

        <p>
          As soon as you fund your account from your 529 we will process this payment automatically
          (and let you know!).
        </p>

        <If condition={state529Connection?.is_automated_withdrawal_eligible && state529Connection?.is_withdrawal_eligible}>
          <Then>
            <p>Click below to make a withdrawal from your 529 Plan.</p>
          </Then>

          <Else>
            <p className="w-9/10">
              Click below to go to your 529 and make a withdrawal.
            </p>

            <p>
              For help in withdrawing funds from your 529, visit our <a href="https://intercom.help/backpack-529/en" target="_blank" rel="noreferrer" className="no-underline">Help Center</a> or <a href="https://www.backpack529.com/contact" id="intercom_support" target="_blank" rel="noreferrer" className="no-underline">Contact Support</a>.
            </p>
          </Else>
        </If>
      </div>

      <div className="flex flex-col items-center w-full">
        <Button
          variant="contained"
          className="w-[85%] pt-2.5 mt-7"
          onClick={closeModal}
        >
          Done
        </Button>

        <If condition={showWithdrawalButton}>
          <Then>
            <Button
              variant="text"
              className="text-blue text-sm hover:bg-transparent hover:underline mt-3"
              onClick={goToWithdrawal}
            >
              Make a withdrawal
            </Button>
          </Then>

          <Else>
            <Button
              variant="text"
              className="text-blue text-sm hover:bg-transparent hover:underline mt-3"
              onClick={goTo529}
            >
              Go to my 529
            </Button>
          </Else>
        </If>
      </div>
    </div>
  );
};

export default PaymentPending;
