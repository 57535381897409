import { ERROR_TYPE } from "@lib/constants/error";

type ApiErrorResponse = {
  errors: {
    fields?: string[];
    type?: string;
    error_message?: string;
  }[];
  request_id: string;
}

export type AppError = Error | ApiError | QueryError;

export const getErrorMessage = (error: ApiErrorResponse) => {
  const DEFAULT_ERROR_TYPE = "UNEXPECTED_ERROR";
  const type = error.errors[0].type || DEFAULT_ERROR_TYPE;

  switch (type) {
    case ERROR_TYPE.DUPLICATE_ROW_EXCEPTION:
      return "An application already exists using this number. Please contact support.";

    case ERROR_TYPE.DUPLICATE_APPLICATION:
      return "Application was already completed.";

    case ERROR_TYPE.MFA_CODE_EXCEPTION:
    case ERROR_TYPE.VERIFY_MFA_ERROR:
      return "Please enter a valid code.";

    case ERROR_TYPE.UNEXPECTED_ERROR_LOGIN:
      return "An error occurred with your login attempt. Please contact support.";

    case ERROR_TYPE.RECORD_NOT_FOUND:
      return "User not found, please try again.";

    case ERROR_TYPE.ACCESS_DENIED_EXCEPTION:
    case ERROR_TYPE.SESSION_EXPIRED:
      return "Session timed out. Please log back in";

    case ERROR_TYPE.UNEXPECTED_ERROR:
      return "There was an unexpected error. Please contact support.";

    case ERROR_TYPE.UNKNOWN_EXCEPTION:
      return "There was an error submitting your request. Please contact support.";

    case ERROR_TYPE.INVALID_FORMAT:
    default:
      return error.errors[0].error_message;
  }
};

// [SM - 12/17/2025]: tbd these will get refactored during the next CARE month
export class ApiError extends Error {
  request_id?: string;

  constructor(data: ApiErrorResponse) {
    const { errors, request_id } = data;
    const { type, error_message } = errors[0] || {};
    const message = error_message;

    super(message);

    this.name = type || "ApiError";
    this.request_id = request_id;
  }
}

export class QueryError extends Error {
  request_id?: string;

  constructor(response: ApiErrorResponse) {
    const { errors, request_id } = response;
    const { type } = errors[0] || {};
    const formattedErrorMessage = getErrorMessage(response);

    const message = formattedErrorMessage || type;

    super(message);

    this.name = type || "QueryError";
    this.request_id = request_id;
  }
}

export class MutationError extends Error {
  request_id?: string;

  constructor(response: ApiErrorResponse) {
    const { errors, request_id } = response;
    const { type } = errors[0] || {};
    const formattedErrorMessage = getErrorMessage(response);

    const message = formattedErrorMessage || type;

    super(message);

    this.name = type || "MutationError";
    this.request_id = request_id;
  }
}