export const parseRow = (value: string) =>
  value.slice(0, 4) + "*".repeat(value.length - 5) + value.slice(-1);

/**
 * Deprecated
 * Use formatCurrency in @lib/utils/formatAmounts
 * */
export const formatCurrency = (value: number): string => {
  const dollars = value / 100;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(dollars);
};

/**
 * Deprecated
 * Use sanitizeAmount in @lib/utils/formatAmounts
 */
export const sanitizeAmount = (amount: string) => Number(amount.replace(/[^0-9]/g, ""));

// format date to YYYY-MM-DD
export const formatDate = (date: Date) => {
  return date.toISOString().split("T")[0];
};
