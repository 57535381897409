import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { Case, Switch } from "react-if";
import { useNavigate, useParams } from "react-router-dom";

import AddPersonalFundsAccountDetails from "@components/AddFunds/AddPersonalFunds/AddPersonalFundsAccountDetails";
import AddPersonalFundsAmount from "@components/AddFunds/AddPersonalFunds/AddPersonalFundsAmount";
import AddPersonalFundsSubmit from "@components/AddFunds/AddPersonalFunds/AddPersonalFundsSubmit";
import AddPersonalFundsSuccess from "@components/AddFunds/AddPersonalFunds/AddPersonalFundsSuccess";

import { BASE_ROUTE, ROUTE_TYPE } from "@lib/constants/global";
import { AddFundsSteps, Flow } from "@lib/enums/flows";

import { useAddFundsStore } from "@stores/addFundsStore";

const baseRoute = BASE_ROUTE[ROUTE_TYPE.PERSONAL_DEBIT];

type AddPersonalFundsProps = {
  onFinishCloseModal?: () => void;
}

const AddPersonalFunds = ({ onFinishCloseModal }: AddPersonalFundsProps) => {
  const navigate = useNavigate();
  const { step } = useParams();

  const currentStep = useAddFundsStore(useShallow((state) => state.step.current));

  const updateRoute = () => {
    if (!currentStep) return;

    navigate(`${baseRoute}/${currentStep}`, { replace: true });
  };

  const handleOnFinish = () => {
    if (!onFinishCloseModal) {
      // set state to refetch 529 connection on dashboard
      navigate(BASE_ROUTE.dashboard, {
        replace: true,
        state: {
          addFundsCompleted: true,
          flowType: Flow.PERSONAL_DEBIT
        }
      });

      return;
    }

    onFinishCloseModal();
  };

  useEffect(() => {
    if (step !== currentStep) {
      updateRoute();
      return;
    }
  }, [currentStep]);

  return (
    <div className="flex flex-col items-center overflow-y-auto sm:h-full">
      <div className="modal-body-scroll flex-col w-[430px]">
        <Switch>
          <Case condition={currentStep === AddFundsSteps.ACCOUNT_DETAILS}>
            <AddPersonalFundsAccountDetails />
          </Case>

          <Case condition={currentStep === AddFundsSteps.AMOUNT}>
            <AddPersonalFundsAmount />
          </Case>

          <Case condition={currentStep === AddFundsSteps.SUBMIT}>
            <AddPersonalFundsSubmit />
          </Case>

          <Case condition={currentStep === AddFundsSteps.SUCCESS}>
            <AddPersonalFundsSuccess onFinish={handleOnFinish}/>
          </Case>
        </Switch>
      </div>

      <footer className="my-8 modal-content-fixed justify-center items-center w-full">
        <img
          data-testid="backpack-logo"
          alt="Backpack Logo"
          src="/backpack.svg"
          height={20}
        />
      </footer>
    </div>
  );
};

export default AddPersonalFunds;