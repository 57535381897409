import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { Beneficiary } from "@lib/types/beneficiary";
import type {
  State529Connection,
  State529CounterpartyResponse,
  State529Plan,
  State529WithdrawalRequest,
} from "@lib/types/state529";
import type { State529WithdrawalStatus } from "@lib/enums/state529";
import { formatAmountInCentsToCurrency } from "@utils/formatAmounts";
import { formatDateStringToShortMonth } from "@utils/formatDates";

export const getState529Plans = async (): Promise<State529Plan[]> => {
  try {
    const {
      data: { data },
    } = await instance.get("/state-529-plans");

    return data.plans as State529Plan[];
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const getState529Connection = async (
  state529PlanId: State529Plan["id"],
): Promise<State529Connection> => {
  try {
    const {
      data: { data },
    } = await instance.get(`/state-529-connect/${state529PlanId}/status`);

    return data;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const getState529Counterparty = async (
  state529PlanId: State529Plan["id"],
  beneficiaryId: Beneficiary["id"]
): Promise<State529CounterpartyResponse> => {
  try {
    const { data: { data } } = await instance.get(`/state-529-connect/${state529PlanId}/counterparty?beneficiary_id=${beneficiaryId}`);

    return data;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const getState529WithdrawalRequests = async (status?: State529WithdrawalStatus): Promise<State529WithdrawalRequest[] > => {
  try {
    const url = status
      ? `/state-529-withdrawals?status=${status}`
      : "state-529-withdrawals";

    const { data: { data } } = await instance.get(url);

    if (!data.state_529_plan_withdrawal_requests.length) return data.state_529_plan_withdrawal_requests;

    return data.state_529_plan_withdrawal_requests.map((withdrawalRequest: State529WithdrawalRequest) => {
      const date = formatDateStringToShortMonth(new Date(withdrawalRequest.created_at).toISOString());

      const formattedAmount = formatAmountInCentsToCurrency(withdrawalRequest.amount_in_cents, true);

      return {
        ...withdrawalRequest,
        created_at_date: date,
        date: new Date (withdrawalRequest.created_at).toISOString().split("T")[0],
        amount: formattedAmount
      };
    });
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};