import { FC } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import WarningIcon from "@icons/WarningIcon";

const Block = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "1rem auto 0.5rem auto",
});

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  margin: "2.75rem 1.75rem 1.75rem 1.75rem",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
  },
}));

const Title = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 600,
  textAlign: "center",
});

const Text = styled(Typography)({
  textAlign: "left",
  lineHeight: "1.0625rem",
  marginTop: "0",
  width: "85%",
});

const ConnectionErrorButton = styled(Button)({
  width: "85%",
  margin: "1rem",
  padding: ".625rem 0",
});

const InfoContainer = styled(Stack)({
  alignItems: "center",
});

const ContactSupportContainer = styled(Stack)({
  width: "100%",
  alignItems: "center",
});

interface ConnectionErrorProps {
  restart: () => void;
}

const ConnectionError: FC<ConnectionErrorProps> = ({ restart }) => {
  return (
    <Content>
      <Title color="error" variant="h1" data-cy="link-connection-error-title">
        An error ocurred
      </Title>

      <Block>
        <WarningIcon className="size-25 text-error-icon" aria-hidden="true" data-cy="link-connection-error-icon" />
      </Block>

      <InfoContainer spacing={3}>
        <Text data-cy="link-connection-error-text">
          We were unable to make a successful connection with your 529 Account.
        </Text>

        <Text>
          If you think you might have entered in the wrong details, please double check and try
          again.
        </Text>
        <Text>If you have additional questions please contact support.</Text>
      </InfoContainer>

      <ContactSupportContainer>
        <ConnectionErrorButton
          variant="outlined"
          id="intercom_support"
          data-cy="link-connection-error-support-button">
          Contact Support{" "}
        </ConnectionErrorButton>

        <ConnectionErrorButton
          variant="contained"
          onClick={restart}
          data-cy="link-connection-error-retry-button">
          Try again
        </ConnectionErrorButton>
      </ContactSupportContainer>
    </Content>
  );
};

export default ConnectionError;
