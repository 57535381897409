import { useEffect } from "react";

import studentsChatting from "@assets/images/studentsChatting.svg";

import Button from "@ui/Button/Button";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useOnboardingStore } from "@stores/onboardingStore";

const WelcomeScreen = () => {
  const first_name = useCurrentUserStore((state) => state.currentUser.first_name);
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);

  const { screenEvent } = useAnalytics();

  useEffect(() => {
    screenEvent("ACCOUNT_OWNER", {
      origin: ANALYTICS_EVENT_ORIGIN.REGISTRATION,
      action: ANALYTICS_EVENT_ACTION.ON_LOAD,
      action_description: "Welcome screen shown in onboarding",
      component: "WelcomeScreen",
    });
  }, []);

  return (
    <div className="modal-screen-footer-fixed xl:pt-12">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title" data-testid="onboarding-screen-header">Welcome {first_name}!</h2>

        <p className="modal-header-description" data-testid="onboarding-screen-description">Congrats, your little ones have grown up and so too have their 529 funds. Now that it&#39;s time for you to start spending those hard earned 529 dollars, we&#39;re here to help.</p>

        <img
          src={studentsChatting}
          alt="students chatting infront of university"
          loading="lazy"
          height={300}
          width={300}
          className="h-auto w-full max-w-60 sm:max-w-[300px] mt-5 sm:mt-10"
        />
      </div>

      <div className="w-full shrink-0">
        <Button
          rounded
          fullWidth
          data-testid="onboarding-nextstep-button"
          onClick={goToNextStep}
        >
          Let&#39;s get started
        </Button>
      </div>
    </div>
  );
};

export default WelcomeScreen;