import { Suspense } from "react";
import { useShallow } from "zustand/react/shallow";
import { Else, If, Then, When } from "react-if";

import BuildingLibraryIcon from "@icons/BuildingLibraryIcon";
import EnvelopeIcon from "@icons/EnvelopeIcon";
import SpeakerphoneIcon from "@icons/SpeakerphoneIcon";

import Spinner from "@ui/Spinner/Spinner";
import Button from "@ui/Button/Button";
import Well from "@ui/Well/Well";

import { VERIFICATION_TYPE } from "@components/Link529/Link529Manual/constants";

import { useLink529Store } from "@stores/link529Store";
import { useOnboardingStore } from "@stores/onboardingStore";

const InitiateWithdrawalScreen = () => {
  const planType = useLink529Store(useShallow((state) => state.planType));
  const withdrawal_guide = useLink529Store(useShallow((state) => state.guides.withdrawal));

  const goToPrevStep = useLink529Store((state) => state.goToPrevStep);
  const goToFinalScreen = useOnboardingStore((state) => state.goToFinalScreen);

  return (
  <Suspense fallback={<Spinner size="lg"/>}>
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between">
        <h2 className="modal-header-title">Initiate a withdrawal</h2>

        <p className="modal-header-description">
          <If condition={planType === VERIFICATION_TYPE.DIRECT_PAY}>
            <Then>Follow the steps below to initiate a withdrawal from your 529.</Then>

            <Else>Once verification is complete, follow the below steps to initiate a withdrawal to your Backpack account.</Else>
          </If>
        </p>

        <div className="pb-11 text-sm">
          <ol className="list-inside list-decimal pl-1 space-y-2 py-5">
            <If condition={planType === VERIFICATION_TYPE.DIRECT_PAY}>
              <Then>
                <li>Login to your LA START 529 Portal</li>

                <li>Click “Withdrawal Request”</li>

                <li>Make your withdrawal Payable to [Insert University Name]</li>
              </Then>

              <Else>
                <li>Login to your 529 Portal</li>


                <li>Navigate to the “Withdrawal” page</li>

                <li>Withdraw your payment amount to your linked (and verified!) Backpack account</li>
              </Else>
            </If>
          </ol>

          <p>
            <If condition={planType === VERIFICATION_TYPE.DIRECT_PAY}>
              <Then>
                See our guide for detailed steps on <a href={withdrawal_guide} target="_blank" rel="noreferrer" className="link">how to withdraw from your 529</a>.
              </Then>

              <Else>
                See our guide for detailed steps on <a href={withdrawal_guide} target="_blank" rel="noreferrer" className="link">how to withdraw from your 529 to Backpack</a>.
              </Else>
            </If>
          </p>
        </div>

        <When condition={planType !== VERIFICATION_TYPE.DIRECT_PAY}>
          <Well color="sky" className="flex items-start gap-2">
            <SpeakerphoneIcon strokeWidth={2} className="stroke-sky-800 fill-sky-400 shrink-0 mt-1" aria-hidden="true" />

            <div className="text-sm">
              <h4>Need 529 funds immediately?</h4>
              <p>Consider these non-Backpack withdrawal options while your account is being verified.</p>

              <ul className="flex flex-col gap-4 pt-4 pb-2">
                <li className="flex gap-3">
                  <BuildingLibraryIcon
                    outline
                    aria-hidden="true"
                    className="size-5 text-gray-500"
                    strokeWidth={2}
                  />
                  <span>Withdraw 529 to your bank. <a href="https://intercom.help/backpack-529/en/articles/9985397-payment-options#h_9849329530" className="link" target="_blank" rel="noreferrer">Learn more</a></span>
                </li>

                <li className="flex gap-3">
                  <EnvelopeIcon
                    outline
                    aria-hidden="true"
                    className="size-5 text-gray-500"
                    strokeWidth={2}
                  />
                  <span>Expedited check from 529. <a href="https://intercom.help/backpack-529/en/articles/9985397-payment-options#h_f8720d7a70" className="link" target="_blank" rel="noreferrer">Learn more</a></span>
                </li>

                <li className="flex gap-3">
                  <a href="https://intercom.help/backpack-529/en/articles/9985397-payment-options" className="link" target="_blank" rel="noreferrer">See all withdrawal options</a>
                </li>
              </ul>
            </div>
          </Well>
        </When>
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          onClick={goToPrevStep}
        >
          Back
        </Button>

        <Button
          rounded
          fullWidth
          onClick={goToFinalScreen}
        >
          Continue
        </Button>
      </div>
      </div>
    </Suspense>
  );
};

export default InitiateWithdrawalScreen;