/**
 * Deprecated
 * Use @ui/Modal
 */
import React, { useRef } from "react";
import clsx from "clsx";

import { IconButton, Modal as MuiModal } from "@mui/material";

import XMarkIcon from "@icons/XMarkIcon";

type ModalMaxWidth = "xs" | "sm" | "md" | "lg" | "xl" | false;

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  maxWidth?: ModalMaxWidth;
  handleClose: () => void;
};

const Modal = ({
  children,
  open,
  maxWidth = "md",
  handleClose
}: ModalProps) => {

  const modalMaxWidth = {
    xs: "sm:max-w-[444px]",
    sm: "sm:max-w-[600px]",
    md: "sm:max-w-[768px]",
    lg: "sm:max-w-[768px]",
    xl: "sm:max-w-[1024px]"
  };

  const backdropClick = useRef(false);

  if (open) {
    document.documentElement.classList.add("modal-open");
  } else {
    document.documentElement.classList.remove("modal-open");
  }

  const handleOnClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    backdropClick.current = event.target === event.currentTarget;

    if (event.currentTarget.id === "close-modal" || backdropClick.current) {
      handleClose();
    }
  };

  return (
    <MuiModal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={handleClose}
    >
      <div
        id="modal-backdrop"
        className="flex h-full justify-center items-center outline-0 -z-10"
        onClick={handleOnClose}
      >
        <div className={clsx("relative overflow-y-auto bg-white paper-24 rounded-none sm:rounded-xl flex flex-col sm:max-h-[calc(100%-20px)] max-sm:size-full max-w-full", `${!!maxWidth && modalMaxWidth[maxWidth]}`)}>

          <div className="flex justify-between p-2">
            <IconButton
              id="close-modal"
              aria-label="close"
              className="self-end ml-auto text-primary"
              onClick={handleOnClose}
            >
              <XMarkIcon />
            </IconButton>
          </div>

          {children}
        </div>
      </div>
    </MuiModal>
  );
};

export default Modal;