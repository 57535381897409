import studentsAtUni from "@assets/images/studentsAtUni.svg";

import Button from "@ui/Button/Button";

import { useAnalytics } from "@hooks/useAnalytics";
import { ANALYTICS_EVENT_ACTION, ANALYTICS_EVENT_ORIGIN } from "@lib/constants/analytics";

import { useOnboardingStore } from "@stores/onboardingStore";

const ConfirmAttendanceScreen = () => {
  const { trackEvent } = useAnalytics();

  const goToPayTuition = useOnboardingStore((state) => state.goToPayTuition);
  const skipTuitionPayment = useOnboardingStore((state) => state.skipTuitionPayment);

  const handleNextStep = () => {
    trackEvent("STARTED_TUITION_PAYMENT", {
      origin: ANALYTICS_EVENT_ORIGIN.ONBOARDING,
      action: ANALYTICS_EVENT_ACTION.BUTTON_CLICK,
      action_description: "Clicked the Yes button",
      component: "ConfirmAttendanceScreen",
    });

    goToPayTuition();
  };

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title w-8/10 sm:self-start" data-testid="onboarding-screen-header">Do you have a Tuition bill to pay?</h2>

        <img
          src={studentsAtUni}
          alt="students chatting infront of university"
          loading="lazy"
          height={262}
          width={272}
          className="h-auto w-full max-w-60 sm:max-w-[300] mt-10 sm:mt-16"
        />
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          data-testid="onboarding-skiptuition-button"
          onClick={skipTuitionPayment}
        >
          No, not yet
        </Button>

        <Button
          rounded
          fullWidth
          data-testid="onboarding-nextstep-button"
          onClick={handleNextStep}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default ConfirmAttendanceScreen;