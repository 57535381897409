import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import InputText from "@ui/Input/InputText";
import Select from "@ui/Select/Select";

import { AGREEMENT_TYPE } from "@lib/constants/global";
import { ERROR_TYPE, FORM_ERROR, INPUT_ERROR_MESSAGE } from "@lib/constants/error";
import { ACCOUNT_TYPE_OPTIONS } from "@lib/constants/addFunds";

import { useFormValidation } from "@hooks/useFormValidation";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { add529CounterpartyLink } from "@lib/mutations/state529Mutations";
import { addPersonalDebitCounterparty } from "@lib/mutations/bankAccountMutations";

import { useCurrentUserStore } from "@stores/currentUserStore";

import {
  isValidAccountNumber,
  isValidNumber,
  isValidRoutingNumber
} from "@utils/validators";
import { classnames } from "@utils/classnames";
import { getLastFourDigits } from "@utils/format";

import type { FormProps } from "@lib/types/ui";
import type { Add529CounterpartyLinkPayload } from "@lib/types/state529";
import type { PersonalDebitCounterpartyPayload } from "@lib/types/directDebit";
import type { AccountDetails } from "@lib/types/addFunds";

type DirectDebitAccountFormProps = {
  type?: "fidelity" | "personal" | null;
  onFormSuccess: (accountDetails: AccountDetails) => void;
} & FormProps;

const AGREEMENT = {
  [AGREEMENT_TYPE.ACH]: {
    id: "ach-authorization",
    name: "ach_authorization",
    label: "I authorize Backpack to initiate debit entries for the specified amount and any future entries under this authorization. This authorization remains in effect until revoked in writing by emailing support@backpack529.com, and I agree to the terms provided, including how to cancel this authorization.",
  },
  [AGREEMENT_TYPE.TERMS_OF_SERVICE]: {
    id: "terms-of-service-agreement",
    name: "terms_of_service_agreement",
    label: <>I confirm that I have read, understood, and agree to the <a href="https://www.backpack529.com/legal/terms-of-service" target="_blank" rel="noreferrer" className="link">Terms of Service</a> and <a href="https://www.backpack529.com/legal/e-sign-consent-agreement" target="_blank" rel="noreferrer" className="link">E-Sign Consent Agreement</a>.</>,
    text: "I confirm that I have read, understood, and agree to the Terms of Service and E-Sign Consent Agreement."
  }
};

const AGREEMENTS = [AGREEMENT_TYPE.ACH, AGREEMENT_TYPE.TERMS_OF_SERVICE];

const isInputValid = (value: string) => {
  if (!value || !isValidNumber(value)) return false;

  return Number(value) > 0;
};

const DirectDebitAccountForm = forwardRef<Partial<HTMLFormElement> | null, DirectDebitAccountFormProps>(({
  formId,
  type = null,
  onPendingSubmit,
  onIsValidForm,
  onFormSuccess
}: DirectDebitAccountFormProps, ref) => {
  const isPersonalDebit = useMemo(() => {
    return !type || type === "personal";
  }, [type]);

  const formRef = useRef<HTMLFormElement | null>(null);

  useImperativeHandle(ref, () => ({
    ...formRef.current,
    submitForm: () => {
      if (formRef.current) {
        formRef.current.requestSubmit();
      }
    },
  }), []);

  const {
    isValidForm,
    errors,
    hasErrors,
    setErrors,
    setFormErrors,
    validateFormField,
  } = useFormValidation(formId);

  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const currentUser = useCurrentUserStore(useShallow((state) => state.currentUser));
  const beneficiaries = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries));

  const defaultAccountHolderName = useMemo(() => {
    return `${currentUser.first_name} ${currentUser.last_name}`;
  }, [currentUser]);

  const accountNumberLabel = `${type === "fidelity" ? "Fidelity " : ""}Account Number`;
  const routingNumberLabel = `${type === "fidelity" ? "Fidelity " : ""}Routing Number`;

  const handleValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    const inputName = input.name;

    // set the default error message depending on the input
    let errorMessage = inputName === "routing_number"
      ? INPUT_ERROR_MESSAGE.routing_number
      : INPUT_ERROR_MESSAGE.account_number.default;

    const isValid = isInputValid(input.value);
    let hasError = false;

    // if input is invalid or value is less than minLength
    if (!isValid || (input.value.toString().length < input.minLength)) hasError = true;

    // if input is routing number and is invalid
    if (inputName === "routing_number" && !isValidRoutingNumber(input.value)) hasError = true;

    // if input is account number and form type is fidelity
    if (inputName === "account_number"
      && type === "fidelity"
      && !isValidAccountNumber("fidelity", input.value)
    ) {
      hasError = true;
      errorMessage = INPUT_ERROR_MESSAGE.account_number.fidelity;
    }

    if (hasError) {
      input.setCustomValidity(errorMessage);
    } else {
      input.setCustomValidity("");
    }

    validateFormField({ inputName, isValid });
  };

  const {
    mutate: add529CounterpartyLinkMutation,
    error: addCounterpartyLinkError,
    isPending: isAdd529CounterpartyLinkPending,
    isSuccess: isAdd529CounterpartyLinkSuccess,
  } = useMutation({
    mutationKey: [MUTATION_KEY.ADD_529_COUNTERPARTY_LINK],
    mutationFn: add529CounterpartyLink,
    onError: (error) => {
      if (error.name === ERROR_TYPE.INVALID_FORMAT) {
        setIsPendingSubmit(false);
      } else {
        throw error;
      }
    },
    // @ts-expect-error
    onSuccess: (results, variables) => {
      onFormSuccess({
        account_number: getLastFourDigits(variables.payload.account_number),
        routing_number: getLastFourDigits(variables.payload.routing_number),
      });
    },
  });

  const {
    mutate: addPersonalDebitCounterpartyMutation,
    isPending: isAddPersonalDebitCounterpartyPending,
    isSuccess: isAddPersonalDebitCounterpartySuccess
  } = useMutation({
    mutationKey: [MUTATION_KEY.ADD_PERSONAL_DEBIT_COUNTERPARTY],
    mutationFn: addPersonalDebitCounterparty,
    throwOnError: true,
    onSuccess: (results, variables) => {
      onFormSuccess({
        counterparty_id: results.id,
        account_holder_name: variables.account_holder_name,
        account_type: variables.account_type,
        account_number: getLastFourDigits(variables.account_number),
        routing_number: getLastFourDigits(variables.routing_number),
      });
    },
  });

  const handleFormSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();

    setIsPendingSubmit(true);

    const form = document.querySelector(`#${formId}`) as HTMLFormElement;
    const formData = new FormData(form);

    const isValid = (event.target as HTMLFormElement).checkValidity();

    // if the form is invalid, show an error message
    // and make sure all invalid inputs are in an error state
    if (!isValid) {
      setFormErrors(formData);
      setIsPendingSubmit(false);
      return;
    }

    // if the form is valid but used to have errors
    // courtesey reset all errors
    if (isValid && hasErrors) setErrors(null);

    let payload = {
      account_number: formData.get("account_number") as string,
      routing_number: formData.get("routing_number") as string,
      ach_authorizations: [
        AGREEMENT[AGREEMENT_TYPE.ACH].label,
        AGREEMENT[AGREEMENT_TYPE.TERMS_OF_SERVICE].text
      ],
      timestamp: new Date().toISOString()
    } as Add529CounterpartyLinkPayload["payload"] | PersonalDebitCounterpartyPayload;

    // if personal debit
    if (isPersonalDebit) {
      payload = {
        ...payload,
        account_holder_name: formData.get("account_holder_name") as string,
        account_type: formData.get("account_type") as string,
      } as PersonalDebitCounterpartyPayload;

      addPersonalDebitCounterpartyMutation(payload);
      return;
    }

    // if fidelity
    payload = {
      ...payload,
      account_holder_name: defaultAccountHolderName,
      beneficiary_id: beneficiaries[0].id,
    } as Add529CounterpartyLinkPayload["payload"];

    add529CounterpartyLinkMutation({
      state_529_plan_id: beneficiaries[0].state_529_plan_id,
      payload
    });
  }, [formId]);

  const formErrorMessage = useMemo(() => {
    if (addCounterpartyLinkError && addCounterpartyLinkError.name === ERROR_TYPE.INVALID_FORMAT) {
      return FORM_ERROR.INVALID_FORMAT;
    }

    if (hasErrors) {
      return FORM_ERROR.INVALID_INPUT;
    }

    return "";
  }, [errors, addCounterpartyLinkError]);

  const disableVisually = useMemo(() => {
    return isAdd529CounterpartyLinkPending
      || isAdd529CounterpartyLinkSuccess
      || isAddPersonalDebitCounterpartyPending
      || isAddPersonalDebitCounterpartySuccess;
  }, [
    isAdd529CounterpartyLinkPending,
    isAdd529CounterpartyLinkSuccess,
    isAddPersonalDebitCounterpartyPending,
    isAddPersonalDebitCounterpartySuccess
  ]);

  useEffect(() => {
    onIsValidForm(isValidForm);
  }, [isValidForm]);

  useEffect(() => {
    onPendingSubmit && onPendingSubmit(isPendingSubmit);
  }, [isPendingSubmit]);

  return (
    <form
      noValidate
      ref={formRef}
      id={formId}
      className="mt-6 flex flex-col gap-4 w-full"
      data-testid="direct-debit-account-form"
      onSubmit={handleFormSubmit}
    >
      <When condition={formErrorMessage}>
        <span className="text-error font-semibold" data-testid="form-error-message">
          {formErrorMessage}
        </span>
      </When>

      <When condition={isPersonalDebit}>
        <InputText
          required
          id="account-holder-full-name"
          data-testid="account-holder-full-name"
          name="account_holder_name"
          label="Account Holder's Full Name"
          placeholder="Enter Name"
          readOnly={disableVisually}
          error={errors?.account_holder_name}
          onBlur={(event) => validateFormField({
            inputName: "account_holder_name",
            isValid: !!event.currentTarget.value
          })}
        />

        <Select
          required
          id="account-type"
          data-testid="account-type"
          name="account_type"
          label="Account Type"
          placeholder="Select Checking or Savings"
          options={ACCOUNT_TYPE_OPTIONS}
          className="py-2.5"
          onChange={(event) => validateFormField({
            inputName: "account_type",
            type: "select",
            isValid: !!event.currentTarget.value
          })}
        />
      </When>

      <InputText
        required
        privacy
        id="account-number"
        data-testid="account-number"
        name="account_number"
        label={accountNumberLabel}
        minLength={4}
        inputMode="numeric"
        placeholder="Enter Account Number"
        readOnly={disableVisually}
        error={errors?.account_number}
        onBlur={(event) => handleValidation(event)}
      />

      <InputText
        required
        privacy
        id="routing-number"
        data-testid="routing-number"
        name="routing_number"
        label={routingNumberLabel}
        minLength={9}
        inputMode="numeric"
        placeholder="Enter Routing Number"
        readOnly={disableVisually}
        error={errors?.routing_number}
        onBlur={(event) => handleValidation(event)}
      />

      <When condition={!isPersonalDebit}>
        <p className="font-medium text-sm" data-testid="fidelity-help-text">
          Don&apos;t know where to find your Fidelity account and routing numbers? <a href="https://www.fidelity.com/customer-service/529-direct-debit" target="_blank" rel="noreferrer" className="link">Find out here</a>
        </p>
      </When>

      <fieldset>
        <legend className="sr-only">Ach authorization and terms of service agreements</legend>

        <div className="space-y-2">
          {AGREEMENTS.map((item) => (
            <div className="relative flex items-start" key={item}>
              <div className="flex h-6 items-center">
                <input
                  required
                  type="checkbox"
                  className={classnames("input-checkbox", errors?.[AGREEMENT[item].name] && "error")}
                  data-testid={`${AGREEMENT[item].id}-checkbox`}
                  id={AGREEMENT[item].id}
                  name={AGREEMENT[item].name}
                  readOnly={disableVisually}
                  onChange={(event) => validateFormField({
                    inputName: event.currentTarget.name,
                    isValid: event.currentTarget.checked
                  })}
                />
              </div>

              <div className="ml-3 text-sm">
                <label
                  data-testid={`${AGREEMENT[item].id}-label`}
                  htmlFor={AGREEMENT[item].id}
                  className="font-normal text-primary"
                >
                  {AGREEMENT[item].label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    </form>
  );
});

DirectDebitAccountForm.displayName = "DirectDebitAccountForm";

export default DirectDebitAccountForm;