/**
 * Returns the last 4 digits of a given string.
 * If the string has fewer than 4 digits, it returns the entire string.
 *
 * @param input - The input string to process.
 * @returns The last 4 digits of the string.
 */
export function getLastFourDigits(input: string | null | undefined): string {
  if (!input) return input || "";

  // Remove non-digit characters and take the last 4 digits
  const digits = input.replace(/\D/g, "");
  return digits.slice(-4);
}

/**
 * Converts a sentence into start case (capitalizing the first letter of each word
 * and converting the rest of the word to lowercase).
 *
 * If the input is not a string, it is returned as is.
 *
 * @param {string} sentence - The input string to transform into start case.
 * @returns {string | any} The transformed string with each word capitalized, or the original input if it's not a string.
 *
 * @example
 * startCase("hello world"); // Returns "Hello World"
 * startCase("JAVASCRIPT is fun"); // Returns "Javascript Is Fun"
 * startCase(123); // Returns 123
 * startCase(null); // Returns null
 */
export const startCase = (sentence: string) => {
  if (typeof sentence !== "string") {
    return sentence;
  }

  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const snakeCase = (string: string) => {
  return string
    .replace(/\d+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, "");
  let formattedNumber;

  if (cleaned.length >= 1 && cleaned.length <= 3) {
    formattedNumber = "(" + cleaned;
  } else if (cleaned.length > 3 && cleaned.length <= 6) {
    formattedNumber = "(" + cleaned.substring(0, 3) + ") " + cleaned.substring(3);
  } else if (cleaned.length > 6) {
    formattedNumber =
      "(" + cleaned.substring(0, 3) + ") " + cleaned.substring(3, 6) + "-" + cleaned.substring(6);
  }

  return formattedNumber || phoneNumber;
};

export const sanitizeNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, "");